<template>
<div class="trading">
    <vue-trading-view :key="(state.theme , selectedCoin)" style="height: 100%" :options="{
              symbol: tradeFrom !== 'TETHER' ? 'BINANCE:' + $coinUnit[tradeFrom] + 'USDT' : 'BINANCEUS:USDTUSD',
              height:'710',
              autosize:true,  
             theme:`${state.theme}`,  
                      timezone: 'Asia/Tehran',
                      style: '1',
                      hide_top_toolbar:true,
                      hide_legend:true,
                      hide_bottom_toolbar:true,
                      hide_legend:false,
                      locale: 'fa_IR',
                      enable_publishing: true,
                       enable_publishing: false,
                      withdateranges: true,
                      range: 'ytd',
                      hide_side_toolbar: true,
                      allow_symbol_change: true,
                      enabled_features: [
                          'header_fullscreen_button'
                          ],
                      disabled_features: [
                          'border_around_the_chart',
                          'remove_library_container_border',
                          ],
                      loading_screen:{
                          backgroundColor:'#FFFFFF',
                          foregroundColor: '#FFFFFF',
                          },
                      overrides: {
                          background: '#FFFFFF'
                      }
          }" />
</div>
</template>

<script>
import {
    tradeFrom,
    tradeTo
} from "@/library/reuseableFunction";
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'
export default {
    name: 'TradingView',
    components: {
        VueTradingView
    },
    props: ['selectedCoin'],
    data() {
        return {

        }
    },

    computed: {
        tradeTo,
        tradeFrom
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.trading {
    flex-grow: 100;
    min-height: 520px;
    max-height: 720px;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid var(--border-color);
    //   min-width: 710px;
}
</style>
