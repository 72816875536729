<template>
<div class="trade-container" :key="state.theme">
    <div class="trade">
        <TradeTitle :key="$route.params.tradeTo + $route.params.tradeFrom + 'title'" class=""/>
        <div class="charts">
           
            <SellBuyLine :key="$route.params.tradeTo + $route.params.tradeFrom + 'market'+state.orderChange" />
            <Tradingview :selectedCoin="$coinUnit[$route.params.tradeFrom]" />
            <CurrencyList />
        </div>
        <div class="trade-container">
            <WalletBalance :key="$route.params.tradeTo + $route.params.tradeFrom + 'balance'+state.orderChange"/>
             <MarketLimit :key="$route.params.tradeTo + $route.params.tradeFrom + 'shop'+state.orderChange"/>
            <TheLastDeal :key="$route.params.tradeTo + $route.params.tradeFrom + 'deals'+state.orderChange"/>
        </div>
        <Orders :key="$route.params.tradeTo + $route.params.tradeFrom + 'orders'+state.orderChange" class="orders-components"/>
    </div>
</div>
</template>

<script>
import TheLastDeal from './TheLastDeal.vue'
import TradeTitle from './TradeTitle.vue'
import Tradingview from './Tradingview.vue'
import WalletBalance from './WalletBalance.vue'
import MarketLimit from './MarketLimit.vue'
import SellBuyLine from './SellBuyLine.vue'
import CurrencyList from './CurrencyList.vue'
import Orders from './Orders/Orders.vue'

export default {
    name: 'Trade',
    components: {
        TradeTitle,
        Tradingview,
        WalletBalance,
        TheLastDeal,
        MarketLimit,
        SellBuyLine,
        CurrencyList,
        Orders,

    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.trade {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin-top: 8px;
    width: 100%;
    background-color: var(--background-parent);
}

.trade-container {
    display: flex;
    row-gap: 4px;
    column-gap: 4px;
    flex-wrap: wrap;
}

.charts {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4px;
    row-gap: 8px;
}

.trade-container {
    flex-grow: 1;
}

.side-res {
    position: absolute;

}
.orders-components {
    order: 4;
}
</style>
