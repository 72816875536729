<template>
<div class="table-contaner">
    <CancelOrder v-if='cancelModal==true' @close="cancelModal=false" @remove="removeorder" />
    <table class="multi_color">
        <tr class="tr-heade">
            <td v-for="(head , index) in head" :key="index" :class=head.class>
                {{head.title}}
            </td>
        </tr>
        <tr class="tr-body" v-for="(tableContent , index) in tableContents" :key="index">
            <td class="number-font date">
                {{$G2J(tableContent.createdAtDateTime) }}
            </td>
            <td :class="{'sell' : tableContent.orderType.includes('SELL'), 'buy' : tableContent.orderType.includes('BUY')}">
                <span v-if="tableContent.orderType.includes('SELL')">
                    فروش
                </span>
                <span v-if="tableContent.orderType.includes('BUY')">
                    خرید
                </span>
            </td>
            <td class="amount-td number-font">
                {{$toLocal(tableContent.amount)}}
            </td>
            <td class="price-td number-font">
                {{$toLocal(tableContent.unitPrice,precision)}}
            </td>
            <td class="total-td number-font amount">
                {{$toLocal(tableContent.overallValue,precision)}}
            </td>
            <!-- <td class="fee-td number-font fee">
                        {{body.fee}} %
                    </td> -->
            <td class="number-font done">
                {{tableContent.executedPercent.toFixed(1)}} %
            </td>
            <td>
                <span class="opration_td" @click="showModal(tableContent)">
                    لغو سفارش
                </span>
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import {
    tradeFrom,
    tradeTo,
    precision,
    Loop
} from "@/library/reuseableFunction";
import CancelOrder from './CancelOrder.vue'
export default {
    name: 'OpenOrders',
    components: {
        CancelOrder
    },
    props: ['base'],
    data() {
        return {
            cancelModal: false,
            head: [{
                    title: "زمان",
                    class: "date"
                },
                {
                    title: "نوع سفارش"
                },
                {
                    title: "مقدار"
                },
                {
                    title: "قیمت",
                    class: "price"
                },
                {
                    title: "مجموع",
                    class: "amount"
                },
                //  {
                //    title:"کارمزد",
                //    class:"fee"
                //  },
                {
                    title: "انجام شده",
                    class: "done"
                },
                {
                    title: "عملیات",
                    class: "opration"
                }
            ],
            modalshow: false,
            removeid: 0,
            tableContents: [],
            loading: false,
            completedModal: false,
            loop: '',

        }
    },
    computed: {
        precision,
        tradeFrom,
        tradeTo
    },

    methods: {
        closemodal() {
            this.modalshow = false
        },
        showModal(e) {
            this.loop.stop()
            this.cancelModal = true
            this.removeid = e.id
        },
        async removeorder() {
            //   this.state.loading = true
            this.cancelModal = false
            const [res, ] = await this.$http.delete(`orders/${this.removeid}`)
            if (res) {
                ++this.state.orderChange
                this.modalshow = false
                this.completedModal = true
                this.$error('سفارش با موفقیت حذف شد', '', 'success')
                if (this.state.loop) {
                    this.loop.start()
                } else {
                    this.getActiveOrders()
                }
            }
        },
        async getActiveOrders() {
            // this.state.loading = true
            const res = await this.$axios.get('/orders', {
                params: {
                    orderStatus: 'IS_OPEN',
                    market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                }
            })
            this.tableContents = res.content;
            this.loading = false
        },
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getActiveOrders, 5000, 'activeOrder')
            this.loop.start()
        } else {
            this.getActiveOrders()
        }
    },
    beforeDestroy() {
        this.loop.stop()
    }

}
</script>

<style lang="scss" scoped>
.table-contaner {
    overflow: scroll;
    border: 2px solid var(--border-color);
    border-radius: 6px;
    border-top-right-radius: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100%;
    height: 300px;
}

.table-contaner::-webkit-scrollbar {
    display: none;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    tr td:first-child {
        text-align: right;
        padding-right: 16px;
    }

    tr td:last-child {
        text-align: left;
        padding-left: 6px;
    }

    tr:first-child td:first-child {
        border-top-right-radius: 6px;
    }

    tr:first-child td:last-child {
        border-top-left-radius: 6px;
    }

    tr:last-child td:first-child {
        border-bottom-right-radius: 6px;
    }

    tr:last-child td:last-child {
        border-bottom-left-radius: 6px;
    }

    .tr-heade {
        height: 44px;
        background: var(--background-child);
        font-weight: normal;
        font-size: 14px;
        color: var(--font-strong);
        position: sticky;
        top: 0;
    }

    .tr-body {
        height: 36px;
        font-weight: normal;
        font-size: 12px;
        color: var(--font-weake);

        .sell {
            color: #E43700;
        }

        // .buy {
        //     color: #B9FFA0;
        // }
    }
}

.opration_td {
    cursor: pointer;
    border-bottom: 1px solid white
}

@media only screen and (max-width:700px) {

    .amount-td,
    .price-td,
    .total-td,
    .fee-td,
    .price,
    .amount,
    .fee,
    .done,
    .opration {
        display: none;
    }
}
</style>
