<template>
<div class="form-container">
  <p class="title">
    آدرس ایمیلی که با آن در تیرکس ثبت نام کردید را وارد کنید
  </p>
    <form class="inputs-container" @submit.prevent="send">
        <label for="">
            <input type="email" placeholder="آدرس ایمیل" v-model="email">
            <p>{{emailError}}</p>
        </label>
        <button type="submit" class="login-btn" :disabled="email === '' || emailError !== ''">
            ارسال ایمیل بازیابی
        </button>
    </form>
</div>
</template>

<script>
const goto = {
    EMAIL: '/reset-pass-email',
    SMS: '/reset-pass-sms',
    GOOGLE_AUTHENTICATOR: '/reset-pass-google-auth',
}
export default {
    name: 'ForgetPassword',
    components: {

    },
    data() {
        return {
            email: '',
            emailError: '',
        }
    },
    methods: {
        checkEmail() {
            if (!this.email) {
                this.emailError = 'ایمیل اجباری';
            } else if (!this.validEmail(this.email)) {
                this.emailError = 'ایمیل صحیح وارد نمایید';
            } else {
                this.emailError = ''
            }
            if (!this.emailError.length) {
                return true;
            }
        },
        validEmail(email) {
            let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            return re.test(email);
        },
        receive(e) {
            this.email = e
            this.checkEmail(e)
        },
        async send() {
            if (this.checkEmail(this.email)) {
                await this.checkEmail()
                this.state.loading = true
                const res = await this.$axios.post('/users/forget-password?email=' + this.email)
                this.$cookies.set('username', this.email)
                this.state.userInfo.email = this.email
                if (res.baseDTO) {
                    this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                    this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
                } else {
                    this.state.userInfo.towStepAuthenticationType = 'EMAIL'
                }
                await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.inputs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;
    width: 100%;
}
.title {
  color: #FEFEFE;
  font-size: 12px;
  margin: 15px 0;
}
.login-btn {
    border: none;
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 44px;
    font-size: 14px;
    color: #FCFCFC;
    width: 100%;
}
label {
      width: 100%;
      position: relative;
  }

  input {
      background: rgba(105, 105, 105, 0.38);
      border: 1px solid rgba(199, 199, 199, 0.22);
      box-sizing: border-box;
      border-radius: 4px;
      height: 44px;
      width: 100%;
      color: #FEFEFE;
      box-sizing: border-box;
      padding: 0 10px;
  }

  input::placeholder {
      color: #FEFEFE;
  }
  .form-container {
    width: 100%;
  }
</style>
