<template>
<div class="trade-title">
    <div class="title-div">
        <div class="crypto-unit">
            <div class="crypto">
                <img class="coin-img" :src="'/coins/'+ tradeFrom + '.png'" :alt="tradeFrom">
                <span class="crypto-name">
                    {{$coinLabel[tradeFrom]}}

                </span>
            </div>
            <div class="unit" @click="upIndex">
                <div class="unit-title">
                    {{$coinLabel[tradeTo]}}
                </div>
                <div class="unit-icons">
                    <inline-svg :src="require('../../assets/Home/Vector.svg')" class="up-index"></inline-svg>
                    <inline-svg :src="require('../../assets/Home/Vector.svg')" class="down-index"></inline-svg>
                </div>
            </div>
        </div>
        <span class="change-number number-font">
        </span>
    </div>
    <div class="statistics-div">
        <div class="change">
            <span class="change-title positive-change">
                بیشترین 24 ساعت
            </span>
            <span class="change-number number-font positive-change">
                {{$toLocal(data.maxPrice,precision)}} <span>{{$coinLabel[tradeTo]}}</span>
            </span>
        </div>
        <div class="change">
            <span class="change-title negative-change">
                کمترین 24 ساعت
            </span>
            <span class="change-number number-font negative-change">
                {{$toLocal(data.minPrice,precision)}} <span> {{$coinLabel[tradeTo]}} </span>
            </span>
        </div>
        <div class="change">
            <span class="change-title">
                حجم 24 ساعت
            </span>
            <span class="change-number number-font">
                {{$toLocal(data.last24HourVolume,precision)}} <span>{{$coinLabel[tradeTo]}}</span>
            </span>
        </div>
    </div>
    <div class="change-div">
        <div class="change">
            <span class="change-title">
                آخرین
            </span>
            <span class="change-number number-font">
                {{$toLocal(data.lastPrice,precision)}} <span>{{$coinLabel[tradeTo]}}</span>
            </span>
        </div>
        <div class="change">
            <span class="change-title">
                درصد تغییرات
            </span>
            <span class="change-number number-font" :class="{'positive-change':data.last24HourChange > 0 , 'negative-change' : data.last24HourChange < 0}">
                %
                {{Math.abs(data.last24HourChange).toFixed(2)}}
                <span v-if=" data.last24HourChange < 0"> - </span>
                <span v-if="data.last24HourChange > 0"> + </span>
            </span>
        </div>
    </div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import {
    Loop,
    tradeTo,
    tradeFrom,
    precision
} from "@/library/reuseableFunction";
export default {
    name: 'TradeTitle',
    components: {
        InlineSvg
    },
    data() {
        return {
            loading: false,
            toman: true,
            loop: '',
            data: {
                maxPrice: 0,
                minPrice: 0,
                lastPrice: 0,
                last24HourVolume: 0,
                last24HourChange: 0,
            },
            unit: [{
                    title: "تومان",
                    price: 0
                },
                {
                    title: "تتر",
                    price: 0
                }
            ],
        }
    },
    computed: {
        tradeFrom,
        tradeTo,
        precision
    },
    methods: {
        upIndex() {
            this.toman = !this.toman
            this.$router.push('/profile/trade/' + (this.toman ? 'TOMAN' : 'TETHER') + '/' + this.tradeFrom)
            // const tradeTo = (this.toman ? 'TOMAN' : 'TETHER' )
            // this.$router.push({
            //     name: 'Trade',
            //     params: {
            //         tradeTo: (this.toman ? 'TOMAN' : 'TETHER' ),
            //         tradeFrom: this.tradeFrom
            //     }
            // })
        },
        downIndex() {
            if (this.unitIndex >= 1) {
                this.unitIndex--
            } else this.unitIndex = 0
        },
        async getTradeHeader() {
            const [res, ] = await this.$http.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                    market_type: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
                }
            })
            this.loading = false
            if (res) {
                this.data = res.content[0] || this.data
                this.state.shopInfo.lastPrice = this.data.lastPrice
                this.state.shopInfo.change24 = this.data.last24HourChange
                document.title = `بازار ${this.$coinLabel[this.tradeFrom]} - ${this.$coinLabel[this.tradeTo]}` + ' ｜ ' + this.$toLocal(this.data.lastPrice, this.precision)
            }
        },

    },
    mounted() {
        this.loading = true
        document.title = `بازار ${this.$coinLabel[this.tradeFrom]} - ${this.$coinLabel[this.tradeTo]}`
        if (this.state.loop) {
            this.loop = new Loop(this.getTradeHeader, this.state.time, 'tradeHeader:' + this.tradeFrom + '-' + this.tradeTo)
            this.loop.start()
        } else {
            this.getTradeHeader()
        }
    },
    beforeDestroy() {
        this.loop.stop()
    },

}
</script>

<style lang="scss" scoped>
.trade-title {
    width: 100%;
    background: var(--background-child);
    border-radius: 8px;
    display: flex;
    min-height: 64px;
    border-radius: 8px;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 50px;
    column-gap: 20px;

    div {
        flex-grow: 1;
        display: flex;
        align-items: center;
        // justify-content: center;
        flex-wrap: wrap;
        // height: 100%;
    }
}

.change {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .change-title {
        font-weight: normal;
        font-size: 12px;
        color: var(--font-weake);
    }

}

.unit {
    width: 40%;
    height: 100%;
    cursor: pointer;
}

.unit-icons {
    display: flex;
    flex-direction: column;
}

.crypto-unit {
    max-width: 230px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: hidden;

    .crypto {
        height: 40px;
        max-width: 60%;
        padding: 8px;
        column-gap: 12px;
        background: var(--background-crypto);
        height: 100%;
        justify-content: center;

        .crypto-name {
            font-weight: bold;
            font-size: 14px;
            color: var(--font-strong);
        }
    }

    .unit {
        height: 40px;
        max-width: 40%;
        background: var(--crypto-unit);

        .unit-icons {
            row-gap: 8px;
            justify-content: center;

            .up-index {
                transform: rotate(180deg);

                path {
                    fill: red;
                }
            }
        }

        .unit-title {
            font-weight: bold;
            font-size: 14px;
            color: var(--font-strong);
            justify-content: center;
        }
    }
}

.coin-img {
    width: 24px;
    height: 24px;
}

.title-div {
    justify-content: space-between;

    span {
        flex-grow: 1;
        text-align: center;
    }
}

.change-number {
    font-weight: bold;
    font-size: 14px;
    color: var(--font-weake);
}
.statistics-div {
    column-gap: 20px;
}
</style>
