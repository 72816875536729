<template>
<div class="table-contaner">
    <!-- <perfect-scrollbar @ps-y-reach-end="getLastOrders" ref="scroll"> -->
        <table class="multi_color">
            <tr class="tr-heade">
                <td v-for="(head , index) in head" :key="index" :class='head.class'>
                    {{head.title}}
                </td>
            </tr>
            <tr class="tr-body muliti_color" v-for="(tableContent , index) in tableContents" :key="index">
                <td class="number-font">
                    {{$G2J(tableContent.createdAtDateTime)}}
                </td>
                <td :class="{'sell' : tableContent.orderType.includes('SELL'), 'buy' : tableContent.orderType.includes('BUY') }">
                    <span v-if="tableContent.orderType.includes('BUY')">
                        خرید
                    </span>
                    <span v-else>
                        فروش
                    </span>
                </td>
                <td class="amount-td number-font">
                    {{$toLocal(tableContent.amount)}}
                </td>
                <td class="price-td number-font">
                    {{$toLocal(tableContent.unitPrice,precision)}}
                </td>
                <td class="total-td number-font">
                    {{$toLocal(tableContent.wholePrice,precision)}}
                </td>
                <td class="fee-td number-font">
                    {{statusLabel(tableContent.orderStatus)}}
                </td>
            </tr>
        </table>
    <!-- </perfect-scrollbar> -->
</div>
</template>

<script>
import {
    tradeTo,
    tradeFrom,
    precision,
    statusColor,
    statusLabel,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'PreviosOrders',
    components: {

    },
    data() {
        return {
            head: [{
                    title: "زمان",
                    class: "date-td"
                },
                {
                    title: "نوع سفارش"
                },
                {
                    title: "مقدار"
                },
                {
                    title: "قیمت",
                    class: "price-td"
                },
                {
                    title: "مجموع",
                    class: "amount-td"
                },
                {
                    title: "وضعیت",
                    class: "fee-td"
                },
            ],
            modalshow: false,
            removeid: 0,
            tableContents: [],
            settings: {
                suppressScrollX: false
            },
            loading: false,
            loop: '',
            page: 0,
            lastPage:1,

        }
    },
    computed: {
        tradeTo,
        tradeFrom,
        precision,
        statusColor,
        statusLabel
    },

    methods: {
        async getLastOrders() {
            if (this.page <= this.lastPage) {
               //for scroll loading
                this.page++ //for scroll loading
                let res = await this.$axios.get('/orders', {
                    params: {
                        market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                        page: this.page,
                        size: 20
                    }
                })
                if (res.content)
                    res.content = res.content.filter(i => i.orderStatus !== 'IS_OPEN');
                this.tableContents = res.content;
                this.loading = false
            }
        },
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getLastOrders, 5000, 'lastOrder')
            this.loop.start()
        } else {
            this.getLastOrders()
        }

    },
    beforeDestroy() {
        this.loop.stop()
    }

}
</script>

<style lang="scss" scoped>
.table-contaner {
    overflow: scroll;
    max-height: 311px;
    border: 2px solid var(--border-color);
    border-radius: 6px;
    border-top-right-radius: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100%;
    height: 300px;

}

.table-contaner::-webkit-scrollbar {
    display: none;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    tr td:first-child {
        text-align: right;
        padding-right: 16px;
    }

    tr td:last-child {
        text-align: left;
        padding-left: 6px;
    }

    tr:first-child td:first-child {
        border-top-right-radius: 6px;
    }

    tr:first-child td:last-child {
        border-top-left-radius: 6px;
    }

    tr:last-child td:first-child {
        border-bottom-right-radius: 6px;
    }

    tr:last-child td:last-child {
        border-bottom-left-radius: 6px;
    }

    .tr-heade {
        height: 44px;
        background: var(--background-child);
        font-weight: normal;
        font-size: 14px;
        color: var(--font-strong);
        position: sticky;
        top: 0;
    }

    .tr-body {
        height: 36px;
        font-weight: normal;
        font-size: 12px;
        color: var(--font-weake);

        .sell {
            color: #E43700;
        }

        // .buy {
        //     color: #B9FFA0;
        // }
    }
}

@media only screen and (max-width:700px) {

    .amount-td,
    .price-td,
    .total-td,
    .fee-td,
    .date-td {
        display: none;
    }
}
</style>
