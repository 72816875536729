<template>
<div class="dashboard-title">
    <div class="wellcome">
        <span class="wellcome-title">
            به تیرکس خوش آمدید
        </span>
        <a href="account" @click.prevent="$router.push({name:'Account'})">
            <span class="complete-link">
                تکمیل اطلاعات کاربری
            </span>
        </a>
    </div>
    <!-- <div class="warning" v-if="state.userInfo.authenticationType ==('CONFIRMED_IDENTITY' || 'UNAUTHORIZED' || 'PENDING' || 'NOT_COMPLETE')">
        <img src="@/assets/Profile/war.png" alt="">
        <span>
            {{warning}}
        </span>
        <img src="@/assets/Profile/close.svg" alt="" @click="warningFun">
    </div> -->
    <div class="level">
        <span>
            سطح کاربری : &nbsp;<span>{{$levels[state.userInfo.userAccountLevel]}}</span>
        </span>
        <img src="@/assets/Profile/level.png" alt="">
    </div>
</div>
</template>

<script>
export default {
    name: 'DashboardTitle',
    components: {

    },
    data() {
        return {
            showwarning : true,
            warning: "حساب کاربری شما احراز هویت نشده است. برای انجام معاملات احراز هویت کنید"
        }
    },
    methods: {
      warningFun() {
        document.getElementsByClassName('warning')[0].style.display = 'none'
      }
    },
}
</script>

<style lang="scss" scoped>
.dashboard-title {
    width: 100%;
    min-height: 141px;
    background: #001E3D;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    column-gap: 16px;
    row-gap: 30px;
    justify-content: space-between;
}

.wellcome {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .wellcome-title {
        font-weight: bold;
        font-size: 24px;
        color: #FFFFFF;
    }

    .complete-link {
        font-weight: normal;
        font-size: 16px;
        background: -webkit-linear-gradient(rgba(255, 185, 4, 1), rgba(232, 153, 0, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-bottom: 1px solid rgba(232, 153, 0, 0.5);
        width: fit-content;
    }
}

.warning {
    background: #405C6D;
    border-radius: 4px;
    min-height: 40px;
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    padding: 0 10px;
    column-gap: 8px;
    row-gap: 8px;
    font-weight: normal;
    font-size: 16px;
    color: #FFFFFF;
}

.level {
    font-weight: normal;
    font-size: 16px;
    color: #F2F2F2;
    display: flex;
    align-items: center;
    column-gap: 16px;
}
</style>
