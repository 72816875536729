<template>
<div class="currency-list">
    <div class="currency-title">
        <span class="other-text">
            سایر بازار ها
        </span>
        <div class="tableTitle" style="height:CustomHeight">
            <span class="deActive" :class="{'active' : toman}" @click="toman=true;beforeLoop()">
                <span>تومان</span>
            </span>
            <span class="deActive" :class="{'active' : !toman}" @click="toman=false;beforeLoop()">
                <span>تتر</span>
            </span>
        </div>
        <!-- <TheTableTitle :items="items" @passcurrency="getCurrency" /> -->
    </div>
    <div class="table-container">
        <perfect-scrollbar>
            <table>
                <tr class="tr-head">
                    <td v-for="(head , index) in head" :key="index">
                        {{head.title}}
                    </td>
                </tr>
                <tr class="tr-body" v-for="(coin , index) in tableContents" :key="index" @click="goto($coins[coin.destCoinSymbol].coin)">
                    <td>
                        <img :src="'/coins/'+ $coins[coin.destCoinSymbol].coin + '.png'" alt="">
                        <span>
                            {{$coins[coin.destCoinSymbol].persianName}}
                        </span>
                    </td>
                    <td :class="{'positive-change':coin.price24hrChangePercent > 0 , 'negative-change' : coin.price24hrChangePercent < 0}">
                        % <span class="number-font">{{parseFloat(Math.abs(coin.price24hrChangePercent)).toFixed(2)}}</span>
                    </td>
                    <td>
                        <span class="number-font">{{$toLocal(coin.price,2)}}</span>
                    </td>
                </tr>
            </table>
        </perfect-scrollbar>
    </div>

    <div>

    </div>
</div>
</template>

<script>
// import TheTableTitle from '../Public/TheTableTitle.vue'
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'CurrencyList',
    components: {
        // TheTableTitle

    },
    data() {
        return {
            items: [{
                    title: "تومان",
                    class: (this.$route.params.tradeTo == 'TOMAN' ? 'active' : 'deActive')
                },
                {
                    title: "تتر",
                    class: (this.$route.params.tradeTo == 'TETHER' ? 'active' : 'deActive')
                }
            ],
            head: [{
                    title: "رمز ارز"
                },
                {
                    title: "تغییرات"
                },
                {
                    title: "قیمت"
                }
            ],
            toman: false,
            tableContents: [],
            loading: false,
            loop: undefined,
            coins: [],
            TomanCoin: 'market_type=BITCOIN_TOMAN' +
                '&market_type=BITCOIN_CASH_TOMAN' +
                '&market_type=ETHEREUM_TOMAN' +
                '&market_type=LITE_COIN_TOMAN' +
                '&market_type=BINANCE_COIN_BSC_TOMAN' +
                '&market_type=STELLAR_TOMAN' +
                '&market_type=TRON_TOMAN' +
                '&market_type=CELO_TOMAN' +
                '&market_type=RIPPLE_TOMAN' +
                '&market_type=DOGE_COIN_TOMAN' +
                '&market_type=MATIC_TOMAN' +
                '&market_type=TETHER_TOMAN',
            TetherCoin: 'market_type=BITCOIN_TETHER' +
                '&market_type=BITCOIN_CASH_TETHER' +
                '&market_type=ETHEREUM_TETHER' +
                '&market_type=LITE_COIN_TETHER' +
                '&market_type=BINANCE_COIN_BSC_TETHER' +
                '&market_type=MATIC_TETHER' +

                '&market_type=STELLAR_TETHER' +
                '&market_type=TRON_TETHER' +
                '&market_type=RIPPLE_TETHER' +
                '&market_type=DOGE_COIN_TETHER' +
                '&market_type=CELO_TETHER',
        }
    },
    watch: {
        'toman'() {
            this.tab()
        }
    },

    computed: {
        tradeTo,
        tradeFrom
    },

    methods: {
        getCurrency(value) {
            this.items[0].class = 'deActive'
            this.items[1].class = 'deActive'
            this.items[value].class = 'active'
            // this.currency=value
            this.setData()
            this.getPrice()
        },
        async setData() {
            const res = await this.$axios.get('/trades/market-info?' + (this.toman ? this.TomanCoin : this.TetherCoin), {
                params: {
                    weeklyDataIncluded: true,
                    // marketType: this.active ? this.TomanCoin : this.TetherCoin
                }
            })
            this.coins = res.content
            // this.tableContents = res.content;
            // this.$emit('get-data', res.content)
        },
        async getPrice() {
            this.state.loading = false
            const link = this.toman ? '/coins/price/usd' : '/coins/price/usdt'
            let [res, ] = await this.$http.get(link, {
                params: {
                    include24hrChange: true,
                    includeWeeklyChange: false
                }
            })
            this.loading = false
            if (res) {
                this.tableContents = res
                this.sort()
            }
        },
        goto(e) {
            this.$router.push('/profile/trade/' + (this.toman ? 'TOMAN' : 'TETHER') + `/${e}`)
        },
        sort() {
            this.tableContents.sort((a, b) => this.$sortCoins.indexOf(a.sourceCoinSymbol) - this.$sortCoins.indexOf(b.sourceCoinSymbol))
        },
        tab() {
            // (this.loop ? this.loop.stop() : '')
            // this.loop.stop()
            this.tableContents = []
            if (this.state.loop) {
                this.beforeLoop()
            } else {
                this.getPrice()
            }
        },
        beforeLoop() {
            this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.getPrice, 5000, 'realPrice' + (this.toman ? 'Toman' : 'Tether'))
                this.loop.start()
            } else {
                this.getPrice()
            }
        }

    },
    mounted() {
        this.beforeLoop()
    },
    beforeDestroy() {
        this.loop.stop()
    }
}
</script>

<style lang="scss" scoped>
.currency-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 260px;
}

.currency-title {
    display: flex;
}

.other-text {
    font-weight: bold;
    font-size: 14px;
    color: var(--font-weake);
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-container {
    width: 100%;
    // height: 480px;
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    background: var(--background-child);
    border: 2px solid var(--border-color);
    border-radius: 5px;
    padding-right: 5px;
}

.table-container::-webkit-scrollbar {
    display: none;
}

table {
    width: 100%;
    // border: 2px solid var(--border-color);
    background: var(--background-child);
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    color: var(--font-strong);
}

.tr-body {
    height: 40px;
    cursor: pointer;

    td:first-child {
        text-align: right;
    }

    img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }
}
.tr-body:hover {
    background-color: rgba(253, 215, 91, 0.3) !important;
}

.tr-head {
    height: 40px;
    position: sticky;
    top: 0;
}

.tableTitle {
    background: var(--table-title);
    border-radius: 8px 8px 0px 0px;
    height: 42px;
    width: 100%;
    display: flex;
    margin-bottom: -2px;
}

.active,
.deActive {
    width: 50%;
    max-width: 137px;
    // min-width:119px;
    // margin: 2px 2px 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0px 0px;
    font-weight: bold;
    // font-size: 16px;
    font-size: clamp(14px, 1vw, 16px);
    cursor: pointer;
}

.active {
    background: var(--table-title-active);
    color: var(--font-strong);
    border: 2px solid var(--border-color);
    border-bottom: #001E3D;
    z-index: 1;
}

.deActive {
    color: #BDBDBD;
}
</style>
