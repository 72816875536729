<template>
    <div id="modal" class="modal-backdrop" @click.self="close">
        <div class="modal" role="dialog"
             aria-labelledby="WithdrawModal"
             aria-describedby="WithdrawModal">
            <img src="@/assets/Profile/closeIcon.svg"
                 class="btn-close"
                 @click="close"
                 aria-label="Close modal"
                 alt="">
            <header class="modal-header">
                شناسایی دو عاملی
            </header>
            <section class="modal-body" style="direction: rtl">

                <template v-if="twoStep === 'EMAIL'">
                    <p>کد ۶ رقمی که به ایمیل شما ارسال شده است را وارد نمایید.</p>
                </template>

                <template v-else-if="twoStep === 'SMS'">
                    <p>کد ۶ رقمی که به شماره
                        <span style="display:inline-block;direction:ltr">{{mobile}}</span>
                        ارسال شده است را وارد نمایید.
                    </p>
                </template>

                <template v-else-if="twoStep === 'GOOGLE_AUTHENTICATOR'">
                    <p>
                        لطفا بارکد زیر را توسط برنامه Google Authenticator گوشی خود اسکن نمایید.
                    </p>
                    <img
                            width="180px"
                            :src="'data:image/png;base64,'+qrCode"
                            alt=""
                    >
                    <p>پس از اسکن بارکد، مورد مربوط به  هاکارکس در برنامه شما ایجاد خواهد شد. برای تایید صحت فرآیند،
                        کدی که در اپ خود مشاهده می‌کنید را در کادر زیر وارد نمایید.</p>
                </template>

                <CodeInput :loading="false" class="input"
                           @change="onChange" @complete="submit" :fields="num" ref="code-input" :fieldWidth="45"
                           :fieldHeight="45"/>
                <p class="resend-code" v-if="twoStep !== 'GOOGLE_AUTHENTICATOR'">
                    <a
                            v-if="countDown===0"
                            @click.prevent="send"
                            style="cursor: pointer"
                            class="countdown">
                        ارسال مجدد
                    </a>
                    <a v-else style="opacity:0.5;cursor:default" class="countdown">ارسال مجدد</a>
                    <a style="text-decoration: none" class="countdown">{{countDown}} ثانیه </a>
                </p>
            </section>
            <footer class="modal-btn">
                <button
                        :disabled="(twoStep !== 'GOOGLE_AUTHENTICATOR'? countDown === 0 : false) || code.length < 6"
                        type="button"
                        class="normal-btn"
                        @click="submit">
                    تایید
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
    import CodeInput from "vue-verification-code-input";

    export default {
        name: "setTwoStepModal",
        components: {CodeInput},
        props: ['twoStep', 'qrCode', ''],
        data() {
            return {
                amount: '',
                twostepcode: '',
                walletaddress: '0x40beE7b4e50d756C57B4e59693905D36B9cCD1B6',
                smscode: [],
                error: '',
                num: 6,
                code: '',
                timeOut: '',
                countDown: 120,
            }
        },
        methods: {
            countDownTimer() {
                if (this.countDown > 0) {
                    this.timeOut = setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
            },
            async send() {
                this.code = ''
                this.state.loading = true
                const a = {
                    EMAIL: 'ایمیل',
                    SMS: 'شماره'
                }
                const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=EMAIL`)
                if (res.message === 'Verification Code Sent') {
                    this.$error('', 'کد به ' + a[this.twoStep] + ' شما ارسال شد', 'success')
                    if (this.countDown === 0)
                        this.countDown = 120
                    this.countDownTimer()
                }
            },
            close() {
                this.$emit('close');
            },
            setPlaceholder() {
                for (let i = 0; i < this.num; i++) {
                    this.$refs['code-input'].$refs['input_' + i][0].placeholder = '------'
                }
            },
            onChange(e) {
                this.code = e
            },
            async submit() {
                const a = {
                    'EMAIL': 'ایمیل',
                    'SMS': 'پیامک',
                    'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
                }
                if (this.code.toString().length === 6 && this.countDown !== 0) {
                    if (this.$route.name !== 'Wallet') {
                        this.state.loading = true
                        await this.$axios.post(`/users/change-otp?twoStepAuthorization=${this.twoStep}&code=${this.code}`)
                        this.$emit('close')
                        this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + a[this.twoStep] + ' تغییر یافت.')
                    } else {
                        this.$emit('submit', this.code)
                    }
                }
            }
        },
        mounted() {
            this.$refs['code-input'].$refs['input_0'][0].focus()
            this.setPlaceholder()
            if (this.twoStep !== 'GOOGLE_AUTHENTICATOR') this.countDownTimer()
            document.body.style.overflow = 'hidden'
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
            document.body.style.overflow = 'auto'
        },
    }
</script>


<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 5;
        color: var(--font-light);
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);
    }

    .modal {
        position: relative;
        background: var(--font-light);
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);
        border-radius: 5px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: calc(100% - 10px);
        max-width: 600px;
        padding: 10px 10px;
    }

    .modal-header {
        color: white;
        font-size: 20px;
        display: flex;
        justify-content: center;
    }

    .btn-close {
        position: absolute;
        left: 15px;
        top: 15px;
        height: 22px;
        width: 22px;
        transition: 0.5s ease-in;
        cursor: pointer;
        z-index: 5;
    }

    .btn-close:hover {
        transform: rotate(180deg);
    }

    .resend-code {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 300px;
        padding: 0 10px;
    }

    .modal-body {
        margin-top: 20px;
        position: relative;
        background-color: white;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 20px 5% 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-body div:last-of-type {
        margin: 10px 0 10px;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .25s ease;
    }


    .modal-btn {
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .normal-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: white;
        max-width: 200px;
        margin: 0;
        border-radius: 5px;
        height: 40px;
        padding: 0;
    }

    .normal-btn img {
        margin-left: 10px;
    }

    @media (max-width: 400px) {
        .address {
            font-size: 12px;
        }

    }

    .auth-row {
        display: flex;
        direction: ltr;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: 350px;
        margin-top: 10px;
    }

    .Auth-input {
        font-size: 20px;
        border-radius: 5px;
        border: solid 1px #00000010;
        width: 55px;
        height: 55px;
        text-align: center;
    }

    .Auth-input:focus {
        border-color: var(--main-orange);
    }
</style>

<style lang="scss">
    input[type="tel"]::placeholder {
        color: var(--main-color);
    }

    .input {
        width: 280px !important;
        display: flex;
        justify-content: center;

        .react-code-input {
            direction: ltr;
            display: flex;
            justify-content: space-evenly;
            border: none;
            width: 50%;

            input {
                border: none !important;
                color: var(--font-color3) !important;
                font-family: 'VistaDev', sans-serif !important;
                font-size: 25px;
                letter-spacing: -2px;
                text-align: center !important;
            }
        }
    }
</style>