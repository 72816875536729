<template>
<div class="table-container">
    <table class="multi_color">
        <tr class="tr-heade">
            <td v-for="(heade , index) in heade" :key="index" :class=heade.class>
                {{heade.title}} <span class="subTitle">{{heade.subTitle}}</span>
            </td>
        </tr>
        <tr class="tr-body" v-for="(coin , index) in prices" :key="index">
            <td class="name">
                <img class="crypto-img" :src="'/coins/'+ coin.destCoinSymbol + '.png'" alt="">
                <!-- {{coin}} -->
                <span>{{$coins[coin.destCoinSymbol].persianName}}</span>
            </td>
            <td class="number-font buy-td">
                {{$toLocal(coin.buyPrice,$decimal[toman ? 'TOMAN' : 'TETHER'])}}
            </td>
            <td class="number-font sell-td">
                {{$toLocal(coin.sellPrice,$decimal[toman ? 'TOMAN' : 'TETHER'])}}
            </td>
            <td class="number-font">
                {{$toLocal(coin.price,2)}}
            </td>
            <td class="number-font" :class="{'negative-change' : coin.price24hrChangePercent < 0 , 'positive-change' : coin.price24hrChangePercent > 0}">
                %
                {{Math.abs(+coin.price24hrChangePercent).toFixed(2)}}
                <span v-if=" coin.price24hrChangePercent < 0"> - </span>
                <span v-if="coin.price24hrChangePercent > 0"> + </span>
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import {
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'CryptoExchange',
    components: {

    },
    props: ['toman'],
    data() {
        return {
            heade: [{
                    title: "ارز"
                },
                {
                    title: "قیمت خرید",
                    subTitle: (this.toman ? 'تومان' : 'تتر'),
                    class: "buy-td"
                },
                {
                    title: "قیمت فروش",
                    subTitle: (this.toman ? 'تومان' : 'تتر'),
                    class: "sell-td"
                },
                {
                    title: "قیمت جهانی",
                    subTitle: 'دلار',
                },
                {
                    title: "تغییرات"
                }
            ],
            coins: this.$coins,
            prices: '',
            loop: undefined
        }
    },
    watch: {
        $route(e) {
            const from = e.query.from || 'BITCOIN'
            this.state.exchangeBuyPrice = this.prices.find(a => a.destCoinSymbol === from).buyPrice
            this.state.exchangeSellPrice = this.prices.find(a => a.destCoinSymbol === from).sellPrice
        }
    },
    methods: {
        async getPrice() {
            const res = await this.$axios.get('coins/exchange-price/' + (this.toman ? 'usd' : 'usdt') + '?includeWeeklyChange=false')
            this.prices = res

            const from = this.$route.query.from || 'BITCOIN'
            this.state.exchangeBuyPrice = this.prices.find(a => a.destCoinSymbol === from).buyPrice
            this.state.exchangeSellPrice = this.prices.find(a => a.destCoinSymbol === from).sellPrice
        },

        beforeLoop() {
            this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.getPrice, 5000, 'realPrice' + (this.toman ? 'Toman' : 'Tether'))
                this.loop.start()
            } else {
                this.getPrice()
            }
        }
    },
    beforeDestroy() {
        if (this.loop) {
            this.loop.stop()
        }

    },
    mounted() {
        //   this.getPrice()
        this.coins = this.$coins
        this.beforeLoop()

    }
}
</script>

<style lang="scss" scoped>
.cryptoes {
    max-width: 824px;
    min-height: 360px;
    background: #001E3D;
    border-radius: 8px;
    min-width: 300px;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 24px;
}

.table-container {
    background: #1F3946;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 8px;
    //   height: 312px;
    overflow: scroll;

    table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;

        tr td:first-child {
            text-align: right;
            padding-right: 24px;
        }

        tr td:last-child {
            text-align: left;
            padding-left: 24px;
        }

        tr:first-child td:first-child {
            border-top-right-radius: 6px;
        }

        tr:first-child td:last-child {
            border-top-left-radius: 6px;
        }

        tr:last-child td:first-child {
            border-bottom-right-radius: 6px;
        }

        tr:last-child td:lastu-child {
            border-bottom-left-radius: 6px;
        }

        .tr-heade {
            height: 52px;
            background: #0A293D;
            font-weight: bold;
            font-size: 14px;
            color: #FEFEFE;
            position: sticky;
            top: -2px;

            .subTitle {
                font-weight: normal;
                font-size: 12px;
                color: #BDBDBD;
            }
        }

        .tr-body {
            height: 56px;
            font-weight: normal;
            font-size: 14px;
            color: #FCFCFC;

            .crypto-img {
                width: 32px;
                height: 32px;
                vertical-align: inherit;
                margin-left: 14px;
            }
        }
    }
}

.table-container::-webkit-scrollbar {
    display: none;
}

.table-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media only screen and (max-width:600px) {

    .sell-td,
    .buy-td,
    .crypto-img {
        display: none;
    }
}
</style>
