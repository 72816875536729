<template>
<div class="sidebar-container">
    <!-- <p class="side-title">
          تیرکس
        </p> -->
    <div class="main-logo-container" @click="$router.push('/')">
        <!-- <img class="main-logo" src="@/assets/Home/logo1.png" alt=""> -->
        <p class="side-title">
            تیرکس
        </p>
    </div>
    <div class="side-content">
        <div>
            <div class="img-container">
                <img class="profile-container" src="@/assets/Profile/profileContainer.svg" alt="">
                <img class="profile-img" src="@/assets/Profile/profile.svg" alt="">
            </div>
            <p class="account-email" v-if="state.userInfo.lastName">
                {{state.userInfo.lastName}}
            </p>
            <p class="account-email" v-else>
                {{state.userInfo.email}}
            </p>
            <div class="li-container">
                <div class="li" v-for="(list , index) in list" :key="index" :class="{'active' : $route.name===list.link || $route.name===list.link2 , 'deActive' : $route.name!==list.link && $route.name!==list.link2, 'accounting' : list.link=='Wallet' }">
                    <a :href=list.link @click.prevent="$router.push({name:list.link})">
                        <span>{{list.title}}</span>
                        <div class="icon">
                            <inline-svg class="inline" :class="{'inline-active' : $route.name===list.link || $route.name===list.link2 , 'inline' : $route.name!==list.link}" :stroke="list.storke" fill='#001E3D' :src="require('../../assets/Profile/hexagon.svg')"></inline-svg>
                            <inline-svg class="main-icon" :class="{'active-main' : $route.name===list.link || $route.name===list.link2 }" :src=list.img></inline-svg>
                            <inline-svg class="shadow-icon" :src="require('../../assets/Profile/shadow.svg')"></inline-svg>
                        </div>

                    </a>
                    <template v-if="list.link=='Wallet' && ($route.name=='Transaction' || $route.name=='Wallet' || $route.name=='OtcWallets')">
                        <a :href=accounting.link @click.prevent="$router.push({name:accounting.link})" v-for="(accounting , index) in accounting" :key="index" :class="{'active-accounting' : $route.name==accounting.link}">
                            <div>
                                <div class="icon">
                                    <inline-svg class="accounting-back active-back-accounting" :src="require('../../assets/Profile/shadow.svg')"></inline-svg>
                                    <inline-svg class="accounting-icon " :class="{'active-accounting-icon' :  $route.name==accounting.link}" :src=accounting.img></inline-svg>
                                </div>
                                <span>
                                    {{accounting.title}}
                                </span>
                            </div>
                        </a>
                    </template>
                </div>

            </div>
        </div>
        <div class="exit" @click="exit">
            <img src="@/assets/Profile/exit.png" alt="">
            <span>
                خروج از حساب
            </span>
        </div>
    </div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'Sidebar',
    components: {
        InlineSvg,
    },
    data() {
        return {
            openexit: true,
            list: [{
                    title: "داشبورد",
                    class: "deActive",
                    storke: "#FFEAC8",
                    link: "Dashboard",
                    // link2: "Account",
                    img: require('../../assets/Profile/dashboard.svg'),
                },
                {
                    title: "معامله سریع",
                    class: "deActive",
                    storke: "#FFEAC8",
                    link: "OTC",
                    img: require('../../assets/Profile/exchangeEs.svg'),
                },
                {
                    title: "صرافی",
                    class: "deActive",
                    storke: "#FFEAC8",
                    link: "Exchange",
                    img: require('../../assets/Profile/exchange.svg'),
                },
                {
                    title: "بازار ها",
                    class: "deActive",
                    storke: "#FFEAC8",
                    link: "Trade",
                    img: require('../../assets/Profile/markets.svg'),
                },
                {
                    title: "حسابداری",
                    class: "deActive",
                    storke: "#FFEAC8",
                    link: "Wallet",
                    link2: "Transaction",
                    img: require('../../assets/Profile/accounting.svg'),
                },
                {
                    title: "تاریخچه",
                    class: "deActive",
                    storke: "#FFEAC8",
                    link: "History",
                    img: require('../../assets/Profile/history.svg'),
                },
                {
                    title: "دعوت از دوستان",
                    class: "deActive",
                    storke: "#FFEAC8",
                    link: "InviteFriend",
                    img: require('../../assets/Profile/invite.svg'),
                },
                {
                    title: "تنظیمات",
                    class: "deActive",
                    storke: "#FFEAC8",
                    link: 'Setting',
                    img: require('../../assets/Profile/setting.svg'),
                }
            ],
            accounting: [{
                    title: "کیف پول",
                    class: "active-account",
                    link: "Wallet",
                    img: require('../../assets/Profile/wallet.svg'),
                },
                {
                    title: "کیف پول صرافی",
                    class: "active-account",
                    link: "OtcWallets",
                    img: require('../../assets/Profile/wallet.svg'),
                },
                {
                    title: "تراکنش ها",
                    class: "active-account",
                    link: "Transaction",
                    img: require('../../assets/Profile/transaction.svg'),
                }
            ]
        }
    },
    methods: {
        // chooseItem(index) {
        //   for(var i=0 ; i <= this.list.length-1 ; i++) {
        //     this.list[i].storke="#FFEAC8"
        //     this.list[i].class="deActive"
        //   }
        //   this.list[index].storke="#FFCA72"
        //   this.list[index].class="active"
        // }
        exit() {
            this.$emit('ExitModal', this.openexit)
        }
    }
}
</script>

<style lang="scss" scoped>
.sidebar-container {
    position: sticky;
    right: 0;
    top: 0;
    background: var(--background-child);
    height: 100vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-sizing: border-box;
    padding: 8px;
    margin-top: -48px;
    width: 100%;
    max-width: 240px;
    z-index: 10;
}

.sidebar-container::-webkit-scrollbar {
    display: none;
}

.side-title {
    font-weight: normal;
    font-size: 24px;
    color: #E89900;
    margin: 5px;
    // border-bottom: 1px solid rgba(224, 224, 224, 0.33);
}

.side-content {
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
}

.side-content::-webkit-scrollbar {
    display: none;
}

.li-container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    align-items: self-end;
    margin-top: 24px;
    margin-bottom: 100px;

    .li {
        max-width: 204px;
        width: 100%;
        height: 40px;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        // padding-right: 35px;
        display: flex;
        align-items: center;

        .icon {
            position: absolute;
            right: -17px;
            top: 0;

            .main-icon {
                position: absolute;
                right: 10px;
                top: 10px;
                fill: #BDBDBD;
            }

            .active-main {
                fill: white;

                path {
                    fill: white !important;
                }

            }

            .shadow-icon {
                position: absolute;
                top: 12px;
                right: -1px;
                fill: #7D7D7D;
                z-index: -1;
            }
        }

        a {
            width: 100%;
            padding-right: 35px;
        }
    }

    .li:hover {
        background: linear-gradient(270deg, #2D5163 8.58%, #1E4D7E 52.94%, #17406A 100%, #32444E 100%);
    }

    .active {
        background-color: #374C56;
        color: #FCFCFC;
    }

    .deActive {
        background-color: var(--sidebar-deactive);
        color: #BDBDBD;
    }
}

.exit {
    display: flex;
    margin: 20px;
    column-gap: 20px;
    cursor: pointer;

    span {
        font-weight: normal;
        font-size: 14px;
        color: var(--font-strong);
    }
}

// .inline {
//   // path {
//   //   // stroke: red;
//   // }
// }
.inline {
    path {
        fill: red !important;
    }
}

.inline:hover {
    stroke: white;
}

.account-email {
    font-weight: bold;
    font-size: 14px;
    color: var(--font-weake);
    // margin-right: 25%;
    text-align: center;
}

.img-container {
    position: relative;
}

.profile-container {
    // position: absolute;
    margin-right: 33%;
}

.profile-img {
    position: absolute;
    left: 105px;
    top: 14px;
}

.inline-active {
    stroke: #FFDA00;
}

.accounting {
    display: flex !important;
    flex-direction: column;
    align-items: baseline !important;
    height: auto !important;
    row-gap: 4px;

    a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    a:nth-child(n+2) {
        position: relative;
    }

    .accounting-back {
        width: 40px;
        height: 40px;
        top: -2px;
        right: -3px;
        position: absolute;
    }

    .accounting-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        fill: #BDBDBD;
    }

    .active-accounting-icon {
        fill: white
    }
}

.active-back-accounting {
    fill: rgba(255, 192, 79, 1);

    svg path {
        fill: rgba(255, 192, 79, 1);
    }
}

.active-accounting {
    border: 2px solid #FFC04F;
}

.main-logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E89900;
    padding: 10px 0;
}

.main-logo {
    width: 60px;
    height: 60px;
}

// @media only screen and (max-width:990px) {
//   .sidebar-container {
//     display: none;
//   }
// }
</style>
