<template>
<div class="exchange">
    <div class="form-crypto">
        <SellBuy @changeUnit='setUnit' @reloadHistory="reload = !reload" :key="state.orderChange"/>
        <CryptoExchange class="crypto" :key="toman" :toman="toman" />
    </div>
    <div>
        <TheTableTitle :items="items" />
        <div class="table-container">
            <!-- <OpenOrders v-if="items[0].class==='active'" :key="reload"/> -->
            <LastOrders :key="reload+state.orderChange+'ukjuy'" />
        </div>
    </div>
</div>
</template>

<script>
// import Allcryptoes from '../Dashboard/Allcryptoes.vue'
import TheTableTitle from '../../Public/TheTableTitle.vue'
import SellBuy from './SellBuy.vue'
// import OpenOrders from '../Dashboard/OpenOrders.vue'
import CryptoExchange from './CryptoExchange.vue'
// import OpenOrders from './OpenOrders.vue'
import LastOrders from './LastOrders.vue'
export default {
    name: 'Exchange',
    components: {
        // Allcryptoes,
        TheTableTitle,
        SellBuy,
        CryptoExchange,
        // OpenOrders,
        LastOrders,
        
        // OpenOrders
    },
    data() {
        return {
            toman: true,
            reload:false,
            items: [
                // {
                //     title: "سفارشات باز",
                //     class: "active"
                // },
                {
                    title: "سفارشات قبلی",
                    class: "active"
                }
            ],
            heade: [{
                    title: "زمان",
                    class: "date"
                },
                {
                    title: "نوع سفارش"
                },
                {
                    title: "مقدار"
                },
                {
                    title: "قیمت"
                },
                {
                    title: "مجموع",
                    class: "total"
                },
                {
                    title: "کارمزد",
                    class: "fee"
                }
            ]
        }
    },
    methods: {
        setUnit(value) {
            this.toman = value
        }
    },
    mounted() {
        this.toman = this.$route.query.to !== 'TETHER'
    }
}
</script>

<style lang="scss" scoped>
.exchange {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 26px;
    margin-top: 8px;
    margin-bottom: 20px;

    .form-crypto {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        column-gap: 26px;
        row-gap: 26px;

        .crypto {
            flex-grow: 1;
            // max-width: 749px;
            min-width: 300px;
            max-height: 625px;
        }
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 2px solid #4F4F4F;

    .tr-heade {
        height: 43px;
        background: #001E3D;
        color: #FEFEFE;
        font-weight: normal;
        font-size: 14px;
    }

    .tr-body {
        height: 36px;
        color: #FEFEFE;
        font-weight: normal;
        font-size: 12px;

        .sell {
            color: #E43700;
        }

        .buy {
            color: #B9FFA0;
        }
    }
}

.table-container {
    //   padding: 0 2px 2px 2px;
    //  background: #4F4F4F;
}

@media only screen and (max-width:700px) {

    .date,
    .total,
    .fee,
    .done,
    .total-td,
    .fee-td {
        display: none;
    }
}
</style>
