<template>
<div class="access">
    <div class="access-describe-container">
        <div class="titles-container">
            <p class="title1">
                دسترسی همیشگی به تیرکس
            </p>
            <p class="title2">
                با نصب اپلیکیشن تیرکس روی تلفن خود همواره به روز باشید
            </p>
        </div>
        <div class="access-ways-container">
            <a class="access-ways" :href="info.appAndroid">
                <img class="ways-img" src='../../assets/Home/googleplay.png' alt="">
                <span class="way-span">
                    دانلود از
                </span>
                <span class="way-title">
                    گوگل پلی
                </span>
            </a>
            <a class="access-ways" :href="info.appIOS">
                <img class="ways-img" src='../../assets/Home/apple.png' alt="">
                <span class="way-span">
                    دانلود از
                </span>
                <span class="way-title">
                    سیب اپ
                </span>
            </a>
            <a class="access-ways" :href="info.appAndroid">
                <img class="ways-img" src='../../assets/Home/bazar.png' alt="">
                <span class="way-span">
                    دانلود از
                </span>
                <span class="way-title">
                   بازار
                </span>
            </a>
            <a class="access-ways" :href="info.appAndroid">
                <img class="ways-img" src='../../assets/Home/android.png' alt="">
                <span class="way-span">
                    دانلود از
                </span>
                <span class="way-title">
                    اندروید
                </span>
            </a>
        </div>
    </div>
    <div class="img-container">
        <img src="@/assets/Home/Mockups.png" alt="">
    </div>
</div>
</template>

<script>
export default {
    name: 'AccessBeebitex',
    components: {

    },
    data() {
        return {
            ways: [{
                    img: require('../../assets/Home/googleplay.png'),
                    span: "دانلود از",
                    title: "گوگل پلی"
                },
                {
                    img: require('../../assets/Home/apple.png'),
                    span: "دانلود از",
                    title: "سیب اپ"
                },
                {
                    img: require('../../assets/Home/bazar.png'),
                    span: "دانلود از",
                    title: "بازار"
                },
                {
                    img: require('../../assets/Home/android.png'),
                    span: "دریافت برای",
                    title: "اندروید"
                }
            ],
            info:{},
        }
    },
    methods: {
        async getInfo() {
            const res = await this.$axios('trades/market-info-home')
            this.userscount = res.baseDTO.userCount
            this.tradprice = res.baseDTO.tradesValue
            this.totaltrad = res.baseDTO.tradesAmount
            const res1 = await this.$axios.get('/exchange-info')
            this.info = res1.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
        },
    },
    mounted() {
        this.getInfo()
        /*window.Raychat.open()
        window.addEventListener('raychat_ready', function () {
            window.Raychat.open()
        });
        window.Raychat.on('close', function () {
            window.Raychat.open()
        });*/
    }

}
</script>

<style lang="scss" scoped>
.access {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 30px 0;
    width: 100%;

    // row-gap: 20px;
    @supports (row-gap: 20px) {
        row-gap: 20px;
    }

    @supports not (row-gap: 20px) {
        margin: -10px 0 -10px 0;

        &>* {
            margin: 10px 0;
        }
    }

    .access-describe-container {
        width: 50%;
        min-width: 300px;
    }
}

.access-ways {
    border: 2px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 40px;
    width: 179px;

    // column-gap: 5px;
    @supports (column-gap: 5px) {
        column-gap: 5px;
    }

    @supports not (column-gap: 5px) {
        margin: 0 -2.5px 0 -2.5px;

        &>* {
            margin: 0 2.5px;
        }
    }
}

.ways-img {
    margin: 0 15px;
}

.way-span {
    font-weight: 100;
    font-size: 14px;
    color: #c5c5c5;
}

.way-title {
    font-weight: bold;
    font-size: 14px;
    color: #F2F2F2;
}

.access-ways-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 24px;
}

.img-container {
    width: 50%;
    min-width: 300px;
    display: flex;
    justify-content: flex-end;

    img {
        max-width: 100%;
    }
}

.titles-container {
    display: flex;
    flex-direction: column;
    // row-gap: 16px;
    margin-bottom: 64px;

    @supports (row-gap: 16px) {
        row-gap: 16px;
    }

    @supports not (row-gap: 16px) {
        margin: -8px 0 -8px 0;

        &>* {
            margin: 8px 0;
        }
    }
}

.title1 {
    font-weight: bold;
    font-size: 24px;
    color: #F2F2F2;
    margin: 0;
}

.title2 {
    font-weight: normal;
    font-size: 18px;
    color: #F2F2F2;
    margin: 0;
}
</style>
