<template>
<div class="dropdown">
    <div class="items-container">
        <div class="item" v-show="coin.coin !=='TOMAN'" v-for="(coin,index) in prices" :key="index" @click="$emit('selectCoin' , prices[index])">
            <img :src="'/coins/'+ $coins[coin.destCoinSymbol].coin + '.png'" :alt="coin.destCoinSymbol">
            <span>
                {{$coins[coin.destCoinSymbol].persianName}}
            </span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'CryptoDropDown',
    components: {

    },
    data() {
        return {
            coins: this.$coins,
            prices : ''
        }
    },
    methods: {
        async getPrice() {
            const res = await this.$axios.get('coins/exchange-price/usd?includeWeeklyChange=false')
            this.prices = res
        }
    },
    mounted() {
        this.getPrice()
        this.coins = this.$coins

    }

}
</script>

<style lang="scss" scoped>
.dropdown {
    position: absolute;
    width: 100%;
    overflow: scroll;
    height: 137px;
    background: #001E3D;
    border: 2px solid #4F4F4F;
    border-radius: 0px 0px 4px 4px;
    padding: 10px 12px;
    top: 35px;
    left: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    z-index: 2;
}

.dropdown::-webkit-scrollbar {
    display: none;
}

.items-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 13px;
    // height:100%
}

.item {
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-weight: normal;
    font-size: 12px;
    color: #FCFCFC;

    img {
        width: 20px;
        height: 20px;
    }
}
</style>
