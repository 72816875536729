<template>
<transition>
    <div class="modal-backdrop" @click.self="$emit('closeModaleEmit')">
        <div class="modal">
            <img @click="$emit('closeModaleEmit')" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt="">
            <div class="modal-container">
                <span class="modal-title">
                    برداشت {{$coins[relatedCoin].persianName}}
                </span>
                <span class="describe">
                    لطفا جزییات در خواست خود را بررسی کنید، در صورت اطمینان از صحت درخواست خود،کد تایید دومرحله ای خود را وارد کرده و روی گزینه ارسال کلیک کنید.
                </span>
            </div>
            <div class="modal-body">
                <label for="" v-if="selected_net">
                    <div class="top-input">
                        <inline-svg :src='require("@/assets/Profile/wallet.svg")' fill="white"/>
                       
                        <div>
                            <span class="number-font">
                                {{$toLocal($S2N(cash),3)}}
                            </span>
                        </div>
                    </div>
                    <input type="text" name="" id="" placeholder='میزان برداشت ' @input="amount = $toLocal(amount)" v-model="amount">
                    <div class="input-footer">
                        <span>
                            حداقل میزان برداشت
                        </span>
                        <div class="amounts-container">
                            <span class="number-fonts">
                                {{minWithdrawAmount}}
                            </span>
                            <span>
                                
                            </span>
                        </div>
                    </div>
                    <div class="input-footer">
                        <span>
                            حداکثر میزان برداشت
                        </span>
                        <div class="amounts-container">
                            <span class="number-fonts">
                                {{$toLocal(maxWithdrawAmount)}}
                            </span>
                            <span>
                                
                            </span>
                        </div>
                    </div>
                    <div class="input-footer">
                        <span>
                            کارمزد
                        </span>
                        <div class="amounts-container">
                            <span class="number-fonts">
                                {{wage}}
                            </span>
                            <span>

                            </span>
                        </div>
                    </div>
                </label>
                <label for="" v-if="selected_net">
                    <!-- <input type="text" name="" id="" placeholder="شماره شبا"> -->
                    <input type="text" v-model="walletAddress" placeholder="آدرس کیف پول">
                </label>
                <template >
                    <label for="" class="selectd-net-lable">
                        <div class="selectd-net" @click="drop = !drop">
                            <span v-if="selected_net">{{selected_net}}</span>
                            <span v-else>انتخاب شبکه</span>
                        </div>
                        <div class="dropdown" v-if="drop">
                            <div v-for="(net , index) in nets" :key="index" @click="selectFun(index)">
                                {{net.tokenType}}
                            </div>
                        </div>
                    </label>
                </template>
                <label for="" v-if="otpInput">
                    <input type="text" name="" id="" placeholder="کد تایید دو عاملی" v-model="code">
                    <p v-if="counter" class="counter-text">{{countDown}} ثانیه </p>
                </label>
            </div>
            <p class="error-p" v-if="!enable && !!selected_net">
                 در حال حاضر برداشت از شبکه مورد نظر مقدور نیست
            </p>
            <button v-if="otpInput && countDown!==0" class="confirm" @click="submitWithdrawRequest" :disabled="disabled ">
                <!-- v-if="otpInput && countDown!==0" -->
                برداشت
            </button>
            <button v-if="otpInput===false" class="confirm" @click="withdraw" :disabled="disabled">
                <!-- v-if="otpInput===false && countDown==0" -->
                ارسال کد تایید
            </button>
            <button v-if="otpInput===true && countDown===0" class="confirm" @click.prevent="send">
                ارسال مجدد
            </button>
        </div>
    </div>
</transition>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
export default {
    name: 'WithdrawalToman',
    components: {
        InlineSvg
    },
    props: {
        relatedCoin: {
            default: ''
        },
        cash: {
            default: 0
        },
        wage: {
            default: 'نامشخص'
        },
        role: {
            default: {}
        },
        exchange: {
            default: false
        },
        nets: {
            default: []
        }
    },
    data() {
        return {
            amount: '',
            walletAddress: '',
            local: false,
            token: false,
            transaction: {
                amount: "",
                destinationWalletAddress: "",
                relatedCoin: "",
                tokenType: "",
                code: "",
                walletType :(this.exchange ? 'EXCHANGE' : 'P2P')
            },
            minWithdrawAmount:0,
            maxWithdrawAmount:0,
            towStepAuthenticationType: "",
            otpInput: false,
            counter: false,
            timeOut: '',
            countDown: 120,
            code: '',
            selected_net: "",
            txIdIsNeeded: false,
            drop: false,
            enable : false
        }
    },
    computed: {
        disabled() {
            return !this.$S2N(this.amount) ||
                !this.walletAddress ||
                (this.$S2N(this.amount) > this.$S2N(this.cash)) ||
                (this.$S2N(this.amount) > this.$S2N(this.max)) ||
                (this.$S2N(this.amount) < this.$S2N(this.min)) 
                // ( !this.enable )
                // (!this.selected_net)
        },
        min() {
            return this.role.minWithdrawAmount
        },
        max() {
            return this.role.maxWithdrawAmount
        },
        danger() {
            return 'حداقل ' + this.$toLocal(this.min) + ' ' + this.$coinLabel[this.relatedCoin] +
                '\n' + 'حداکثر ' + this.$toLocal(this.max) + ' ' + this.$coinLabel[this.relatedCoin]
        }
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        close() {
            this.$emit('closeModaleEmit');
        },
        async withdraw() {
            if (!this.disable && !this.state.loading) {
                this.state.loading = true
                this.transaction.amount = this.$S2N(this.amount),
                    this.transaction.destinationWalletAddress = this.walletAddress,
                    this.transaction.relatedCoin = this.relatedCoin
                if ('TETHER' === this.relatedCoin) {
                    this.transaction.tokenType = this.token ? 'ERC20' : 'TRC20'
                }
                const res = await this.$axios.post("/users/send-code")
                this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
                this.otpInput = true
                if (this.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR') {
                    this.counter = true
                    this.countDownTimer()
                }
            }
        },
        async send() {
            this.code = ''
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
            if (res.message === 'Verification Code Sent') {
                this.$error('', 'کد به ' + a[this.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },
        selectFun(index) {
            this.selected_net = this.nets[index].tokenType
            this.transaction.tokenType= this.selected_net
            this.txIdIsNeeded = this.nets[index].txIdIsNeeded
             this.enable = this.nets[index].withdrawEnable
             this.minWithdrawAmount = this.nets[index].minWithdraw
            this.maxWithdrawAmount = this.nets[index].maxWithdraw
            this.drop = false

        },
        async submitWithdrawRequest() {
            this.state.loading = true
            this.transaction.code = this.code
            const [res, ] = await this.$http.post('/wallets/withdrawal-request', this.transaction)
            if (res) {
                this.$emit('closeModaleEmit')
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged
                
            }
        },
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    min-height: 300px;
    background: var(--background-child);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 15px;
}

.modal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.close-icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
}

.modal-title {
    font-weight: bold;
    font-size: 16px;
    color: #F2F2F2;
    text-align: center;
    width: 100%;
}

.describe {
    font-weight: normal;
    font-size: 14px;
    color: var(--font-weake);
}

.confirm {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 40px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
}

.top-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px;

    div {
        display: flex;
        align-items: center;
        column-gap: 4px;
        color: #FCFCFC;
        font-weight: normal;
        font-size: 12px;
    }
}

select {
    width: 100%;
    height: 44px;
    background: var(--background-input);
    border: 1px solid rgba(199, 199, 199, 0.22);
    color: #FCFCFC;
}

.input-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    font-size: 12px;
    color: #E0E0E0;
    margin: 4px 0;
}

.amounts-container {
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: #BDBDBD;
}

.counter-text {
    color: white;
}

.switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;
    cursor: pointer;

    .selector {
        height: 25px;
        width: 50px;
        border-radius: 25px;
        border: 1px solid white;
        position: relative;
        transition: 0.3s;

        &:after {
            content: '';
            background-color: yellow;
            /*border: 1px solid var(--dark);*/
            /*width: 50%;*/
            height: 100%;
            border-radius: 25px;
            position: absolute;
            transition: background-color 0.3s, left 0.3s, right 0.3s 0.15s;
            left: 0;
            right: 50%;
        }
    }

    input:checked {
        +.selector {
            border-color: white;

            &:after {
                background-color: orange;
                transition: background-color 0.3s, left 0.3s 0.15s, right 0.3s;
                left: 50%;
                right: 0;
            }
        }
    }
}

.no-gutters {
    margin: 20px 0;
    color: white;
}

.selectd-net {
    cursor: pointer;
    background: var(--background-input);
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 4px;
    height: 44px;
    width: 100%;
    color: var(--font-strong);
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.dropdown {
    position: absolute;
    width: 100%;
    background: gray;
    padding: 10px;
    z-index: 2;

    div {
        cursor: pointer;
    }
}
</style>
