<template>
<div>
    <div id="corner">
  <div id="corner">
    <div id="mainbtn" @click="openIcones">
    <span></span>
    <span></span>
    <span></span>
    </div>
    <svg height="200" width="">
      <circle cx="0" cy="0" r="75" fill="black" />
    </svg>
    </div>
</div>

<div class='circle-container'>
    <!-- <a :href=icon.link @click.prevent="$router.push({name:icon.link})" v-for="(icon , index) in icon" :key="index" :class=icon.class> -->
	<a href='Notification' @click.prevent="$router.push('/profile/notification')" class='deg0' :class="{'open' : openCorner}" >
        <img src="@/assets/Profile/noti.svg" alt="">
    </a>
    <a href='Support' @click.prevent="$router.push('/profile/support')" class='deg30' :class="{'open' : openCorner}">
        <img src="@/assets/Profile/support.svg" alt="">
    </a>
    
    <a href='Dashboard' @click.prevent="$router.push('/profile/dashboard')" class='deg60' :class="{'open' : openCorner}">
        <inline-svg  :src="require('../../assets/Profile/dashboard.svg')" fill='white'/>
    </a>
    <a class='deg90'  :class="{'open' : openCorner}" @click="changeTheme" v-if="$route.name=='Trade'">
        <img v-if="state.theme == 'light'" src="@/assets/Profile/toDark.svg" alt="" >
        <img v-if="state.theme == 'dark'" src="@/assets/Profile/toLight.svg" alt="" >
    </a>
    <!-- <img alt="" src='@/assets/Profile/toLight.svg' v-if="$route.name=='Trade' && state.theme=='dark'" @click="state.theme='light'">
         <img alt="" src='@/assets/Profile/toDark.svg' v-if="$route.name=='Trade' && state.theme=='light'" @click="state.theme='dark'"> -->
	<!-- <a href='#' class='deg30' :class="{'open' : openCorner}"><img src="@/assets/Profile/support.svg" alt=""></a>
  <a href='#' class='deg60' :class="{'open' : openCorner}"><img src="@/assets/Profile/mobile.svg" alt=""></a>
	<a href='#' class='deg90' :class="{'open' : openCorner}"><img src="@/assets/Profile/dashboard.svg" alt=""></a> -->
</div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
  name: 'cornerNav',
  components :{
    InlineSvg
  },
  data() {
      return {
         openCorner:false,
         icon:[
           {
             img:require('../../assets/Profile/noti.svg'),
             class:"notification"
           },
           {
             img:require('../../assets/Profile/support.svg'),
             link:"Support"
           },
           {
             img:require('../../assets/Profile/toDark.svg'),
             img2:require('../../assets/Profile/toLight.svg')
           },
           {
             img:require('../../assets/Profile/dashboard.svg'),
             link:'Dashboard'
           }
         ],
      }
  },
  methods : {
      openIcones() {
          this.openCorner= !this.openCorner
      },
      changeTheme() {
        if(this.state.theme == 'light'){
          this.state.theme = 'dark'
        }
        else if(this.state.theme == 'dark') {
          this.state.theme = 'light'
        }
      }
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('#mainbtn')) {
              this.openCorner=false
            }
            // else if(event.target.closest('.modal , .add-btn')) {
            // }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
// .parnet{
//     &[size="mini"{
//     }
//     &[size="larg"]{
        
//     }
// }
    #mainbtn {
  position:absolute;
  top:15px;
  left:15px;
  z-index:2;
width: 40px;
height: 30px;
border-radius: 50%;
border: none;
background: transparent;
overflow: hidden;
  span {
    width: 100%;
    height: 5px;
    background: white;
  }
}

.circle-container {
	position: absolute;
 top:-50px;
 left:-12px;
	width: 100px;
	height: 100px;	
z-index:0;
}
.circle-container a ,  circle-containe img{
	display: block;
	overflow: hidden;
	position: absolute;
	width: 40px; height: 40px; 
  margin:50px;
  background:  #FFA000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.circle-container i { display: block; width: 100px; }
.deg0 { transform: rotate(-25deg) translate(5.75em) rotate(-0deg);   -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;}
.deg30 { transform: rotate(-25deg) translate(5.75em) rotate(-30deg);  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out; }
.deg60 { transform: rotate(-25deg) translate(5.75em) rotate(-60deg);  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out; }
.deg90 { transform: rotate(-25deg) translate(6em) rotate(-90deg);  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out; 
}

a {
  text-decoration:none;
  color:black;
}

.fa-home:hover {color:tan;}
.fa-cog:hover {color:gray;}
.fa-comments:hover {color:teal;}
.fa-heart:hover {color:red;}

.open.deg0 { transform: rotate(0deg) translate(5.75em) rotate(-0deg); }
.open.deg30 { transform: rotate(30deg) translate(5.75em) rotate(-30deg); }
.open.deg60 { transform: rotate(60deg) translate(5.75em) rotate(-60deg); }
.open.deg90 { transform: rotate(90deg) translate(6em) rotate(-90deg); 
}


#author {
  font-family:'Squada One';
  top: calc(50% + 50px);
  left: calc(50% - 225px);
  color:black;
  font-size:40px;
  text-decoration:none;
  display: block;
  position: absolute;
  text-align:center;
}
.btn-img {
  width: 5px;
  height: 5px;
}
#mainbtn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
    width: 100%;
    height: 6px;
    background: chocolate;
  }
}
</style>
