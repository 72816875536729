<template>
<div class="market-limit">
    <div class="tableTitle" style="height:CustomHeight">
        <span :class="{'active' : limit=='limit' , 'deActive' : limit !== 'limit'}" @click.prevent="limit='limit'">
            <span>حد</span>
        </span>
        <span :class="{'active' : limit == 'market' , 'deActive' : limit !== 'market'}" @click.prevent="limit='market'">
            <span>بازار</span>
        </span>
        <span :class="{'active' : limit == 'stopLimit' , 'deActive' : limit !== 'stopLimit'}" @click.prevent="limit='stopLimit'">
            <span> stop-limit</span>
        </span>
        <span :class="{'active' : limit == 'stopMarket' , 'deActive' : limit !== 'stopMarket'}" @click.prevent="limit='stopMarket'">
            <span>stop-market</span>
        </span>
         <span :class="{'active' : limit == 'OCO' , 'deActive' : limit !== 'OCO'}" @click.prevent="limit='OCO'">
            <span>OCO</span>
        </span>
    </div>

    <div class="form-container">
        <div class="title-input">
            <!-- <div class="form-title">
                <div v-for="(title , index) in titles" :key="index" class="deActive-title" @click="buy = title.value" :class="{'buy' : buy && title.class=='buy' , 'sell' : !buy && title.class=='sell'}">
                    {{title.title}}
                </div>
            </div> -->
            <BuyLimit :cash="buyCredit" :role="role" :wage="state.userInfo.fee" :decimal="precision" :id="1" :key="'buyLimit'+limit" :limit='limit' />
            <SellLimit :cash="sellCredit" :role="role" :wage="state.userInfo.fee" :decimal="precision" :limit="limit" :id="2" :key="'sellLimit'+limit"/>
            <!-- <BuyMarket :cash="$toLocal(buyCredit)" :role="role" :wage="state.userInfo.fee" :decimal="precision" :limit="limit" :id="2" :key="'sellLimit'+limit" v-if="!limit" />
            <SellMarket :cash="sellCredit" :role="role" :wage="state.userInfo.fee" :decimal="precision" :limit="limit" :id="2" :key="'sellMarket'+limit" v-if="!limit " /> -->
        </div>
    </div>

</div>
</template>

<script>
import BuyLimit from './LimitMarket/BuyLimit.vue'
import SellLimit from './LimitMarket/SellLimit.vue'
// import BuyMarket from './LimitMarket/BuyMarket.vue'
// import SellMarket from './LimitMarket/SellMarket.vue'
import {
    tradeTo,
    tradeFrom
} from "@/library/reuseableFunction";
export default {
    name: 'MarketLimit',
    components: {
        BuyLimit,
        SellLimit,
        // BuyMarket,
        // SellMarket
    },
    props: [
        'coin'
    ],
    data() {
        return {
            sell: false,
            buy: false,
            titles: [{
                    title: "خرید",
                    class: "buy",
                    value: true
                },
                {
                    title: "فروش",
                    class: "sell",
                    value: false
                },

            ],
            button: [{
                    title: "واریز",
                    class: "buy-btn",
                    value: true,

                },
                {
                    title: "برداشت",
                    class: "sell-btn",
                    value: false
                }
            ],
            sellCredit: 0,
            buyCredit: 0,
            limit: 'limit',
            role: {},
        }
    },
    computed: {
        tradeTo,
        tradeFrom,
        precision() {
            return this.$decimal[this.tradeFrom] || 8
        }
    },
    methods: {
        async getWalletCredit() {
            if (this.state.userInfo.authorized) {
                const res = await this.$axios.get('/wallets/customer-addresses?walletType=P2P', {
                    params: {
                        coins: `${this.tradeFrom},${this.tradeTo}`
                    }
                })
                this.sellCredit = res[this.tradeFrom].credit || 0
                this.buyCredit = res[this.tradeTo].credit || 0
            }
            const coins = await this.$axios.get('/coins')
            this.role = coins.content.find((a) => a.coin === this.tradeFrom)
        },
        titlesclick() {
            if (this.buyTtile) {
                return true
            } else {
                this.buyTtile = true
                this.sellTitle = false
            }
        },
        openModal() {
            this.sell = true
        },
        closeSellModal(value) {
            this.sell = value
        }
    },
    created() {
        this.getWalletCredit()
    }
}
</script>

<style lang="scss" scoped>
.market-limit {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 260px;
    // background: var(--table-title-active);
    // border: 2px solid var(--border-color);
    border-top: none;
}

.form-container {
    background: var(--background-child);
    border-radius: 8px;
    padding: 24px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid var(--border-color);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    row-gap: 25px;
}

.deActive-title {
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-deactive);
    color: #BDBDBD;
    cursor: pointer;
}

.buy-btn,
.sell-btn {
    width: 100%;
    height: 40px;
    border-radius: 6px;
}

.buy,
.buy-btn {
    background: linear-gradient(90deg, #9DE700 0%, #5AB400 51.76%, #91D600 101.82%);
    font-weight: bold;
    color: #FCFCFC;
    cursor: pointer;
}

.sell,
.sell-btn {
    background: linear-gradient(270deg, rgba(255, 47, 1, 0.97) 0%, rgba(255, 61, 0, 0.979333) 32.6%, rgba(255, 92, 0, 0.984313) 64.37%, #FF3D00 100%);
    font-weight: bold;
    color: #FCFCFC;
    cursor: pointer;
}

.form-title {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    height: 32px;
    margin-bottom: 24px;
}

.title-input {
    // display: flex;
    // flex-direction: column;
    // flex-grow: 1;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 25px;

}

.tableTitle {
    background: var(--table-title);
    border-radius: 8px 8px 0px 0px;
    height: 42px;
    width: 100%;
    display: flex;
    margin-bottom: -2px;
}

.active,
.deActive {
    width: 50%;
    max-width: 137px;
    // min-width:119px;
    // margin: 2px 2px 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0px 0px;
    font-weight: bold;
    // font-size: 16px;
    font-size: clamp(14px, 1vw, 16px);
    cursor: pointer;
}

.active {
    background: var(--table-title-active);
    color: var(--font-strong);
    border: 2px solid var(--border-color);
    border-bottom: #001E3D;
    z-index: 1;
}

.deActive {
    color: #BDBDBD;
}

@media only screen and (max-width: 600px) {
    .market-limit {
        width: 100%;
    }
}
</style>
