<template>
<div class="history">
    <span class="history-title">
        تاریخچه معاملات
    </span>
    <div class="table-container">
        <TheTableTitle :items="items" />
        <div class="table-container">
            <template v-if="items[0].class==='active'">
                <!-- <table class="multi_color">
                    <tr class="tr-heade" v-if="items[0].class==='active'">
                        <td v-for="(headeEasy , index) in headeEasy" :key="index" :class=headeEasy.class>
                            {{headeEasy.title}}
                            <span class="subTitle">
                                {{headeEasy.subTitle}}
                            </span>
                        </td>
                    </tr>
                    <tr class="tr-body" v-for="(bodyEasy , index) in bodyEasy" :key="index" :class="bodyEasy.class">
                        <td class="date number-font">
                            {{bodyEasy.date}}
                        </td>
                        <td :class="{'buy' : bodyEasy.type==='خرید' , 'sell' : bodyEasy.type==='فروش'}">
                            {{bodyEasy.type}}
                        </td>
                        <td>
                            {{bodyEasy.crypto}}
                        </td>
                        <td class="number-font">
                            {{bodyEasy.amount.toLocaleString()}}
                        </td>
                        <td class="price number-font">
                            {{bodyEasy.price.toLocaleString()}}
                        </td>
                        <td class="amount number-font">
                            {{bodyEasy.total.toLocaleString()}}
                        </td>
                        <td class="detail">
                            <span class="visit">مشاهده جزعیات</span>
                        </td>
                        <td :class="{'success' : bodyEasy.statuse==='موفق' , 'fail' : bodyEasy.statuse==='نا موفق'}" class="status">
                            {{bodyEasy.statuse}}
                        </td>
                    </tr>
                </table> -->
                <ExchangeHistory/>
            </template>
            <AdvancedHistory  v-if="items[1].class==='active'"/>

        </div>
    </div>
</div>
</template>

<script>
import TheTableTitle from '../../Public/TheTableTitle.vue'
import AdvancedHistory from './AdvancedHistory.vue'
import ExchangeHistory from './ExchangeHistory.vue'
export default {
    name: 'History',
    components: {
        TheTableTitle,
        AdvancedHistory,
        ExchangeHistory
    },
    data() {
        return {
            items: [{
                    title: "معاملات صرافی",
                    class: "active"
                },
                {
                    title: "معاملات پیشرفته",
                    class: "deActive"
                },
            ],
            headeEasy: [{
                    title: "زمان",
                    class: "date"
                },
                {
                    title: "نوع سفارش",
                },
                {
                    title: "رمز ارز",
                },
                {
                    title: "مقدار",
                },
                {
                    title: "قیمت",
                    subTitle: "",
                    class: "price"
                },
                {
                    title: "مجموع",
                    subTitle: "",
                    class: "amount"
                },
                {
                    title: "شناسه تراکنش",
                    class: "id"
                },
                {
                    title: "وضعیت",
                    class: "status"
                }
            ],
            headeadvance: [{
                    title: "زمان",
                    class: "date"
                },
                {
                    title: "نوع سفارش",
                },
                {
                    title: "رمز ارز",
                },
                {
                    title: "مقدار",
                },
                {
                    title: "قیمت",
                    subTitle: "",
                    class: "price"
                },
                {
                    title: "مجموع",
                    subTitle: "",
                    class: "amount"
                },
                {
                    title: "کارمزد",
                    class: "fee"
                },
                {
                    title: "وضعیت",
                    class: "status"
                }
            ],
            tableContents: [],
            lastPage: 1,
            page: 0,
            loading: false,
        }
    },
    methods: {
        async check() {
            if (this.page <= this.lastPage && !this.loading) { //for scroll loading
                this.loading = true
                this.page++ //for scroll loading
                const [res, ] = await this.$http.get('/trades', {
                    params: {
                        size: 10,
                        page: this.page,
                        type: 'ALL'
                    }
                })
                if (res) {
                    this.lastPage = res.totalPages
                    this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
                    // this.tableContents = res.content //for pagination
                }
            } //for scroll loading
        },

    },
    mounted() {
        this.check()
    }

}
</script>

<style lang="scss" scoped>
.history {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .history-title {
        font-weight: bold;
        font-size: 18px;
        margin: 16px 0;
        color: #FEFEFE;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 2px solid #4F4F4F;

    tr td:first-child {
        text-align: right;
        padding-right: 16px;
    }

    tr td:last-child {
        text-align: left;
        padding-left: 16px;
    }

    .tr-heade {
        height: 55px;
        background: #001E3D;
        font-weight: normal;
        font-size: 14px;
        color: #E0E0E0;

        td:last-child {
            border-top-left-radius: 6px;
        }

        .subTitle {
            font-size: 12px;
            color: #BDBDBD;
        }
    }

    .tr-body {
        font-weight: normal;
        font-size: 12px;
        color: #E0E0E0;
        height: 40px;

        .sell,
        .fail {
            color: #E43700;
        }

        .buy,
        .success {
            color: #B9FFA0;
        }

        .visit {
            border-bottom: 1px solid #E0E0E0;
            cursor: pointer;
        }
    }

    .tr-body:last-child {
        td:first-child {
            border-bottom-right-radius: 6px;
        }

        td:last-child {
            border-bottom-left-radius: 6px;
        }
    }
}

@media only screen and (max-width:700px) {

    .status,
    .fee,
    .date,
    .price,
    .amount,
    .id,
    .detail {
        display: none;
    }
}
</style>
