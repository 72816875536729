<template>
<transition name="modal-fade">
    <div class="modal-backdrop" @click.self="$emit('closeModal')">
        <form class="modal" @submit.prevent="submit">
            <img @click="$emit('closeModal')" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt="">
            <span class="message-title">
                ارسال پیام به پشتیبانی تیرکس
            </span>
            <div class="inputs-container">
                <label for="">
                    <input type="text" placeholder="عنوان" v-model="data.subject">
                </label>
                <label for="" class="drop-container" @click="drop = !drop">
                    <div class="data-type">
                        <span v-if="data.type">{{data.type}}</span>
                        <span v-else>بخش</span>
                    </div>
                    <div class="dropdown" v-if="drop">
                        <div v-for="(type , index) in types" :key="index" @click="setTitle(index)">
                            {{type.label}}
                        </div>
                    </div>
                </label>
                <textarea name="" v-model="data.text" id="" cols="30" rows="10" placeholder="افزودن پیام"></textarea>
            </div>

            <button class="send-btn" :disabled="!data.subject || !data.type || !data.text" type="submit">
                ارسال
            </button>
        </form>
    </div>
</transition>
</template>

<script>
export default {
    name: 'NewMessage',
    components: {

    },
    data() {
        return {
            amount: '',
            drop: false,
            data: {
                type: '',
                subject: '',
                text: '',
            },
            types: [{
                    label: 'فنی',
                    value: 'TECHNICAL'
                },
                {
                    label: 'عمومی',
                    value: 'GENERAL'
                }
            ]

        }
    },
    computed: {
        typeValue() {
            return this.types.find(e => e.label === this.data.type).value
        }
    },
    mounted() {
        document.body.style.overflow = 'hidden';
    },
    beforeDestroy() {
        document.body.style.overflow = 'auto';
    },
    methods: {
        close() {
            this.$emit('close');
        },
        setTitle(index) {
            this.data.type = this.types[index].label
        },
        async submit() {
            let formData = new FormData
            for (let a in this.data) {
                if (a === 'type') {
                    formData.append(a, this.typeValue)
                } else {
                    formData.append(a, this.data[a])
                }
            }
            if (this.data.subject && this.data.type && this.data.text) {
                this.state.loading = true
                await this.$axios.post('/tickets', formData)
                this.$emit('closeModal')
                this.$emit('sendMessage')
            }
        }
    },

}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-width: 696px;
    min-height: 348px;
    background: #001E3D;
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
}

.close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.message-title {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
}

.send-btn {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    width: 202px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.inputs-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;
    column-gap: 16px;
    margin-top: 35px;
}

textarea {
    width: 100%;
    height: 120px;
    background: rgba(105, 105, 105, 0.38);
    border: 1px solid rgba(199, 199, 199, 0.22);
    border-radius: 4px;
    padding: 10px;
    resize: none;
    color: #FEFEFE;
}

textarea::placeholder {
    color: #FEFEFE;

}

.data-type {}

.drop-container {
    position: relative;
    background: var(--background-input);
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 4px;
    height: 44px;
    // width: 100%;
    display: flex;
    align-items: center;
    color: var(--font-strong);
    box-sizing: border-box;
    padding: 0 10px;
     cursor: pointer;
    .dropdown {
        position: absolute;
        background: #001E3D;
        box-shadow: 0px 4px 9px rgb(94 94 94 / 25%);
        border-radius: 8px;
        right: 0;
        top: 45px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 15px;
        cursor: pointer;
    }
}
</style>
