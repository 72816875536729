<template>
<div class="baner-help">
   
    <div class="baner">
        <vueper-slides :rtl='true' >
            <!-- autoplay -->
            <vueper-slide v-for="(slide , index) in slide" :key="index" >
                
                <template #content>
                    <div class="slide" :class=slide.class>
                        <div class="title-container">
                            <div class="name-container">
                                <span class="text">
                                    {{slide.text}}
                                </span>
                                <span class="name">
                                    {{slide.title}}
                                </span>
                                <span class="subTitle">
                                    {{slide.subTitle}}
                                </span>
                                
                            </div>
                            <!-- <button class="slide-btn">
                                {{slide.btn}} 
                                شروع کنید
                            </button> -->
                        </div>
                        <div class="images-container">
                            <img class="slide-img slide1" :src=slide.img1 width="100%" alt="" v-if=slide.img1>
                            <img class="slide-img slide2" :src=slide.img1 width="100%" alt="" v-if=slide.img1>
                            <img class="slide-img slide3" :src=slide.img1 width="100%" alt="" v-if=slide.img1>
                            <img class="slide-img slide4" :src=slide.img1 width="100%" alt="" v-if=slide.img1>
                            <div class="side-images">
                                <!-- <div class="side-image" v-for="(sideImg , i) in sideImg" :key="i" :class="{'activeImg' : index==i}">
                                    <span>
                                        {{sideImg.title}}
                                    </span>
                                    <img :src=sideImg.img alt="">
                                </div> -->
                            </div>
                        </div>
                    </div>
                </template>
            </vueper-slide>
        </vueper-slides>
        <svg height="100" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points="0,0 100,0 100,100" />
    </svg> 
    </div>
    <!-- <svg height="100" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points="0,0 100,0 100,100" />
    </svg> -->
</div>
</template>

<script>
import {
    VueperSlides,
    VueperSlide
} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
    name: 'Baner',
    components: {
        VueperSlides,
        VueperSlide
    },
    data() {
        return {
            slide: [
                {
                    title: "صرافی تیرکس",
                    subTitle: "معامله آسان حرفه ای را با ما تجربه کنید",
                    text: "رمزارز های خود را با معامله آسان بسرعت تبدیل کنید",
                    btn: "معامله آسان",
                    //  img:require('../../assets/Home/easyEx.png'),
                     class:"markets-slide",
                    //   image:require('../../assets/Home/Img-Slider-3.png')
                    img1: require('../../assets/Home/easyEx1.png'),
                    img2: require('../../assets/Home/easyEx.png'),
                    img3: require('../../assets/Home/easyEx.png'),
                    img4: require('../../assets/Home/easyEx.png'),
                },
                {
                    title: " صرافی تیرکس ",
                    subTitle: "معامله آسان حرفه ای را با ما تجربه کنید",
                    text: "درآمد خود را با تیرکس چند برابر کنید",
                    btn: "مشاهده صرافی",
                    //  img:require('../../assets/Home/siteEx.png'),
                    class: "markets-slide1",
                    // image:require('../../assets/Home/Img-Slider-1.png')
                    img1: require('../../assets/Home/siteEx1.png'),
                    img2: require('../../assets/Home/siteEx2.png'),
                    img3: require('../../assets/Home/siteEx3.png'),
                    img4: require('../../assets/Home/siteEx4.png'),
                },
                {
                    title: "صرافی تیرکس",
                    subTitle: "معامله آسان حرفه ای را با ما تجربه کنید",
                    text: "امکان معامله مستقیم کاربران با یکدیگر",
                    btn: "پیوستن به بازار",
                    //  img:require('../../assets/Home/marketEx.png'),
                     class:"markets-slide2",
                    //   image:require('../../assets/Home/Img-Slider-2.png')
                    img1: require('../../assets/Home/marketEx1.png'),
                    img2: require('../../assets/Home/marketEx.png'),
                    img3: require('../../assets/Home/marketEx.png'),
                    img4: require('../../assets/Home/marketEx.png'),
                },
                
                // {
                //     title: "تیرکس",
                //     subTitle: "معامله با سایت",
                //     text: "رمزارز های خود را با سایت صراف معامله کنید",
                //     btn: "مشاهده صرافی",
                //     //  img:require('../../assets/Home/siteEx.png'),
                //     class: "markets-slide1",
                //     image:require('../../assets/Home/Img-Slider-4.png')
                // },
                // {
                //     title: "تیرکس",
                //     subTitle: "معامله با سایت",
                //     text: "رمزارز های خود را با سایت صراف معامله کنید",
                //     btn: "مشاهده صرافی",
                //     //  img:require('../../assets/Home/siteEx.png'),
                //     class: "markets-slide1",
                //     image:require('../../assets/Home/Img-Slider-5.png')
                // },
            ],
            sideImg: [{
                    img1: require('../../assets/Home/siteEx.png'),
                    img2: require('../../assets/Home/siteEx2.png'),
                    img3: require('../../assets/Home/siteEx3.png'),
                    img4: require('../../assets/Home/siteEx4.png'),
                    title: "معامله با سایت"
                },
                {
                    img1: require('../../assets/Home/marketEx.png'),
                    img2: require('../../assets/Home/marketEx.png'),
                    img3: require('../../assets/Home/marketEx.png'),
                    img4: require('../../assets/Home/marketEx.png'),
                    title: "معامله بازار"
                },
                {
                    img1: require('../../assets/Home/easyEx.png'),
                    img2: require('../../assets/Home/easyEx.png'),
                    img3: require('../../assets/Home/easyEx.png'),
                    img4: require('../../assets/Home/easyEx.png'),
                    title: "معامله آسان"
                }
            ]
        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.baner-help {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.baner {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 400px;
    align-items: center;
    margin-top: -27px;
}

svg {
    fill: red;
    background: transparent;
    z-index: 2;
    margin-top: 10px;
}

.slide {
    display: flex;
    align-items: center;
    height: 430px;
    justify-content: center;
}

.title-container {
    width: 40%;
}
.images-container {
    width: 100%;
    height: 100%;
}
.title-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 20px;
    // height: 100%;
    // justify-content: space-around;
}

.images-container {
    display: flex;
    align-items: flex-start;
    // height: 275px;
    column-gap: 64px;
    justify-content: flex-end;

    @supports (column-gap: 5px) {
        column-gap: 5px;
    }

    @supports not (column-gap: 5px) {
        margin: 0 -2.5px 0 -2.5px;

        &>* {
            margin: 0 2.5px;
        }
    }

    .slide-img {
        // display: none;
        height: 100%;
        
    }
}

.side-images {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;

}

.side-image {
    border: 2px solid #FFC04F;
    box-sizing: border-box;
    border-radius: 8px;
    width: 102px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px;
    column-gap: 10px;
    opacity: 0.5;
    margin: 5px 0;
    background: #001E3D;

    span {
        font-weight: normal;
        font-size: 10px;
        color: #FFFFFF;
    }

    img {
        width: 50%;
    }
}

.name {
    font-weight: bold;
    font-size: 48px;
    font-size: clamp(32px, 4vw, 48px);
    color: #FC8304;
    margin: 20px 0;
    width: 223px;
    text-shadow: 2px 2px 2px black;
    text-align: center;
        width: 100%;
}

.subTitle {
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    // text-shadow: 2px 2px 2px black;
    text-align: center;
        padding: 5px 10px;
    color: #011328;
    background: #FCC504;
border-radius: 8px;
}

.text {
    font-weight: normal;
    font-size: 20px;
    color: #FCDE30;
    margin: 8px 0;
    text-shadow: 2px 2px 2px black;
    text-align: center;
}

.slide-btn {
    margin-top: 80px;
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    width: 136px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
    // box-shadow: 1px 1px 5px 2px black;
}

.activeImg {
    opacity: 1;
}

.markets-slide {
    // background-image: url('../../assets/Home/marketSlid.jpeg');
    position: relative;
    overflow-x: clip;

}

.name-container {
    max-width: 346px;
    flex-grow: 1;
    height: 195px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background: rgba(60, 41, 69, 0.81);
    // filter: blur(15px);
    border-radius: 42px;
    margin-top: 50px;
    padding: 10px 30px;
    position: relative;

    span {
        z-index: 2;
    }
}

.name-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    top: 0;
    filter: blur(15px);
    -webkit-backdrop-filter: blur(10px);
    width: 100%;
    flex-grow: 1;
    // height: 195px;
    // background: rgba(60, 41, 69, 0.81);
}

.markets-slide::after {
    content: "";
    // background-image: url('../../assets/Home/Slider photo (1).png');
    background-repeat: no-repeat;
    background-position: center;
    // background-size: cover;
    position: absolute;
    top: -15px;
    right: -24px;
    bottom: 0px;
    left: -5px;
    // opacity: 0.4;
    z-index: -1;
    background-size: cover;
    
    // transform: rotate(5deg);
}

@media only screen and (max-width:600px) {
    // .title-container {
    //     width: 80%;
    // }
    // .slide {
    //     position: relative;
    // }
    // .slide:after {
    //      content: "";
    // position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // top: 0;
    // filter: blur(15px);
    // -webkit-backdrop-filter: blur(10px);
    // width: 100%;
    // flex-grow: 1;
    // height: 195px;
    // background: rgba(60, 41, 69, 0.81);
    // }
      .title-container {
        width: 100%;
        height: 100%;
    }
    .name-container {
        // padding: 0;
        width: 100%;
        height: 100%;
    }
    .name-container::after {
        background: rgba(35, 72, 123, 0.7);
    }
}
</style><style lang="scss">
.vueperslides {
    // max-width: 1440px;
    flex-grow: 1;
    width: 100%;
    z-index: 2;
    box-shadow: rgba(35, 72, 123, 0.4) 0 50px 50px 1px;
    // opacity: 0.9;
}

.vueperslides::after {
    content: "";
    // background-image: url('../../assets/Home/marketSlid.jpeg');
    // background-repeat: no-repeat;
    // background-position-x: right;
    // background-size: cover;
    position: absolute;
    top: -70px;
    right: -20px;
    bottom: 0px;
    left: 0;
    // opacity: 0.4;
    z-index: -2;
    background-size: cover;
    transform: rotate(5deg);
    display: none;
}

.vueperslides__parallax-wrapper {
    position: relative;
    overflow-x: hidden;
    height: 430px;
    padding: 0 !important;
}

.vueperslides__arrow svg {
    vertical-align: middle;
    stroke: currentColor;
    fill: none;
    width: 36px;
    stroke-width: 2px;
    box-sizing: border-box;
    height: 40px;
    padding: 0;
}
.slide2 , .slide3 , .slide4 {
            display: none;
        }
@media only screen and (max-width:900px) {
    // .images-container {
    //     display: none !important;
    // }

}
@media only screen and (max-width:700px) {
    // .images-container {
    //     display: none !important;
    // }
    // .title-container {
    //     width: 100%;
    //     height: 100%;
    // }
    // .name-container {
    //     width: 100%;
    //     height: 100%;
    // }
    // .name-container::after {
    //     background: rgba(60, 41, 69, 0.81);
    // }
}
@media only screen and (max-width:500px) {
    .markets-slide::after {
        width: 100%;
        height: 100%;
        right: 0 !important;
        left: 0 !important;
    }
    .title-container {
        left: 0 !important;
    }
    .name-container {
        max-width: unset;
    }

}
// @media only screen and (min-width:1043px) {
//      .slide2 , .slide3 , .slide4 {
//         display: none;
//     }
// }
// @media only screen and (min-width:768px) {
//     .slide1 , .slide3 , .slide4{
//         display: none;
//     }
// }
// @media only screen and (max-width:480px) {
//     .slide1 ,.slide2 , .slide3 {
//         display: none;
//     }
// }
// @media only screen and (max-width:320px) {
//     .slide1 {
//         display: none;
//     }
// }
</style>
