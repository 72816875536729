<template>
<div class="orders">
    <!-- <TheTableTitle :items="items"/> -->
    <div class="table-container">
        <div class="table-title">
            سفارشات باز
        </div>
        <table class="multi_color">
            <tr class="tr-heade">
                <td v-for="(heade , index) in heade" :key="index" :class=heade.class>
                    {{heade.title}}
                </td>
            </tr>
            <!-- <tr class="tr-body" v-if="tableContents.length==0">
                sdfgsdf
            </tr> -->
            <tr  class="tr-body" v-for="(tableContent , index) in tableContents" :key="index">
                <td class="number-font date">
                    {{tableContent.createdAtDateTime }}
                </td>
                <td :class="{'sell' : tableContent.orderType==('LIMITED_SELL' || 'MARKET_SELL'), 'buy' : tableContent.orderType==('LIMITED_BUY' || 'MARKET_BUY') }">
                    <span v-if="tableContent.orderType==('LIMITED_SELL' || 'MARKET_SELL')">
                        فروش
                    </span>
                    <span v-if="tableContent.orderType==('LIMITED_BUY' || 'MARKET_BUY')">
                        خرید
                    </span>
                </td>
                <td class="amount-td number-font">
                    {{$toLocal(tableContent.amount)}}
                </td>
                <td class="price-td number-font">
                    {{$toLocal(tableContent.unitPrice,precision)}}
                </td>
                <td class="total-td number-font amount">
                    {{$toLocal(tableContent.overallValue,precision)}}
                </td>
                <td class="number-font done">
                    {{tableContent.executedPercent.toFixed(1)}} %
                </td>

            </tr>
        </table>
    </div>
</div>
</template>

<script>
import {
    tradeFrom,
    tradeTo,
    precision,
} from "@/library/reuseableFunction";

export default {
    name: 'Account',
    components: {

    },
    data() {
        return {
            modalshow: false,
            removeid: 0,
            tableContents: [],
            loading: false,
            alive: false,
            loopIsAlive: false,
            completedModal: false,
            heade: [{
                    title: "زمان"
                },
                {
                    title: "نوع سفارش"
                },
                {
                    title: "مقدار",
                    class: "amount-td"
                },
                {
                    title: "قیمت",
                    class: "price-td"
                },
                {
                    title: "مجموع",
                    class: "total-td"
                },
                {
                    title: "انجام شده"
                }
            ],
        }
    },
    computed: {
        precision,
        tradeFrom,
        tradeTo
    },
    methods: {
        closemodal() {
            this.modalshow = false
        },
        showModal(e) {
            if (!e.executedAmount || e < 0) {
                this.alive = false
                this.modalshow = true
                this.removeid = e.id
            }
        },
        async cancleAllOrders() {
            for (let i = 0; i < this.tableContents.length; i++) {
                if (!this.tableContents[i].executedAmount) {
                    await this.removeorder(this.tableContents[i].id)
                }
            }
            this.completedModal = true
        },
        async removeorder(e) {
            if (e < 0) {
                this.cancleAllOrders()
            } else {
                const res = await this.$axios.delete(`orders/${e}`)
                if (res.message === "deleting of order was successful") {
                    this.modalshow = false
                    this.completedModal = true
                    this.alive = true

                    if (!this.loopIsAlive)
                        if (this.state.loop) {
                            this.loop()
                        } else {
                            this.getActiveOrders()
                        }

                }
            }
        },
        async getActiveOrders() {
            const res = await this.$axios.get('/orders', {
                params: {
                    orderStatus: 'IS_OPEN',
                }
            })
            this.tableContents = res.content || [];
            this.$emit('update:activeLength', this.tableContents.length)
        },
        async loop() {
            // this.state.loading = true
            this.loading = true

            while (this.alive) {
                this.loopIsAlive = true
                await this.getActiveOrders()
                this.loading = false
                await this.$sleep(5000)
            }
            this.loopIsAlive = false
        }
    },
    async mounted() {
        this.alive = true
        this.loading = true
        if (this.state.loop) {
            this.loop()
        } else {
            await this.getActiveOrders()
            this.loading = false
        }
    },
    beforeDestroy() {
        this.alive = false
    }
}
</script>

<style lang="scss" scoped>
.orders {
    background: #001E3D;
    border-radius: 8px;
    flex-grow: 1;
    // max-width: 826px;
    height: 360px;
    box-sizing: border-box;
    padding: 24px;
    max-width: 1200px;
}

.table-container {
    display: flex;
    background: #1F3946;
    border-radius: 8px 0px 0px 0px;
    box-sizing: border-box;
    padding: 2px;
    flex-direction: column;
    height: 100%;

    .table-title {
        width: 132px;
        height: 49px;
        background: #001E3D;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px 6px 0px 0px;
        font-weight: bold;
        font-size: 18px;
        color: #E2E2E2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        height: 100%;
        tr {
            width: 100%;
            // display: table;
            // table-layout: fixed;
        }

        tr td:first-child {
            text-align: right;
            padding-right: 6px;
        }

        tr td:last-child {
            text-align: left;
            padding-left: 6px;
        }

        tr:first-child td:first-child {
            border-top-right-radius: 6px;
        }

        tr:first-child td:last-child {
            border-top-left-radius: 6px;
        }

        tr:last-child td:first-child {
            border-bottom-right-radius: 6px;
        }

        tr:last-child td:last-child {
            border-bottom-left-radius: 6px;
        }

        tbody {
            height: 280px;
            overflow: auto;
            display: block;
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        tbody::-webkit-scrollbar {
            display: none;
        }

        .tr-heade {
            height: 44px;
            background: #001E3D;
            font-weight: normal;
            font-size: 14px;
            color: #FEFEFE;
            position: sticky;
            top: 0;
        }

        .tr-body {
            height: 36px;
            font-weight: normal;
            font-size: 12px;
            color: #E0E0E0;

            .sell {
                color: #E43700;
            }

            .buy {
                color: #B9FFA0;
            }
        }
    }
}

@media only screen and (max-width:700px) {

    .amount-td,
    .price-td,
    .total-td,
    .fee-td {
        display: none;
    }
}
</style>
