<template>
<div class="upload">
    <span class="upload-title">
        آپلود مدارک
    </span>
    <p class="describe">
        لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا خوانا و واضح ،دقیقا مانند تصویر نمونه ،ارسال نمایید. دقت شود متن دست نوشته نبایستی تایپ شود. هم چنین توجه داشته باشید هنگام تهیه تصویر ، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود.
    </p>
    <div class="text-input-container">
        <div class="upload-part">
            <span class="part-title">
                متن دست نوشته:
            </span>
            <p class="text">
                این جانب ........... با شماره ملی ....... در تاریخ ........... مدارک خود را جهت احراز هویت در سایت تیرکس ارسال و اعلام می کنم مدارک، حساب کاربری و اطلاعات کارت بانکی خود را در اختیار دیگری قرار نداده ام و متعهد می شوم از دریافت وجه و خرید ارز دیجیتال برای اشخاص دیگر تحت هر عنوانی )آگهی استخدام، کسب سود، کاریابی در منزل و ....( خودداری کنم.
            </p>
        </div>
        <div class="upload-part">
            <span class="part-title">
                نمونه سلفی با کارت ملی:
            </span>
            <div class="images">
                <img :src=guid.img alt="" v-for="(guid , index) in guid" :key="index">
            </div>
        </div>
        <div class="upload-part">
            <span class="upload-part-title">
                محل آپلود تصویر:
            </span>
            <!-- <div class="input-file-container">
              <div class="">
                <img src="@/assets/Profile/cloud.png" alt="">
                <span>تصویر سلفی با کارت ملی</span>
              </div>
              <button class="input-file-btn">
                آپلود
              </button>
            </div> -->
            <form class="input-file-container" @submit.prevent="upload" @dragover.prevent @dragenter="hovered=!hovered" @dragleave="hovered=!hovered" @drop.stop.prevent="hovered=false;drop($event);" :style="{borderColor : hovered? 'blue' : ''}">
                <img v-if="path" class="img" :src="path" alt="">
                <img v-else class="img" src="@/assets/Profile/cloud.png" alt="">
                <input type="file" name="picture" id="picture" style="display: none" @change="upload($event.target)" :disabled="readonly" v-if="!readonly" required>
                <label for="picture" class="input-file-btn"> بارگذاری تصویر</label>
            </form>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'DocumentsUpload',
    components: {

    },
    props: ['data'],
    data() {
        return {
            guid: [{
                    img: require('../../../assets/Profile/trueSelfie.png'),
                },
                {
                    img: require('../../../assets/Profile/falseSelfie.png'),
                }
            ],
            path: '',
            loadedpicture: '',
            hovered: '',
        }
    },
    watch: {
        'data.image'() {
            if (this.data.image)
                if (typeof this.data.image === 'string')
                    this.path = this.data.image
        }
    },
    computed: {
        readonly() {
            return ['AUTHORIZED', "INFORMATION_CHANGED"].includes(this.state.userInfo.authenticationType)
        }
    },
    methods: {
        drop(e) {
            let dt = e.dataTransfer
            this.upload(dt)
        },
        upload(evt) {
            let file = evt.files[0]
            this.data.image = file
            let fileReader = new FileReader();
            fileReader.addEventListener("loadstart", (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
            })
            fileReader.addEventListener("progress", (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
            });
            fileReader.addEventListener('load', (event) => {
                this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                this.path = event.target.result
            });
            fileReader.readAsDataURL(file)
        },
        hover() {
            this.hovered = true
        }
    }
}
</script>

<style lang="scss" scoped>
.upload {
    background: #001E3D;
    border-radius: 8px;
    flex-grow: 1;
    min-height: 456px;
    padding: 24px;
}

.upload-title {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
}

.describe {
    font-weight: normal;
    font-size: 16px;
    color: #F2F2F2;
}

.text-input-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 24px;
    width: 100%;
    margin-top: 40px;
    justify-content: space-between;
}

.upload-part {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    flex-grow: 1;
    max-width: 400px;
}

.part-title {
    font-weight: normal;
    font-size: 18px;
    color: #F2F2F2;
}

.upload-part-title {
    font-weight: bold;
    font-size: 18px;
    color: rgba(255, 185, 4, 1);
}

.text {
    font-weight: bold;
    font-size: 14px;
    color: #B9FFA0;
}

.images {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
    justify-content: center;
}

.input-file-container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    button,
    .input-file-btn {
        background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
        border-radius: 6px;
        width: 100%;
        height: 40px;
        font-weight: bold;
        font-size: 14px;
        color: #FCFCFC;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div,
    input,
    img {
        border: 2px dashed #BDBDBD;
        border-radius: 6px;
        background: #2E414E;
        width: 100%;
        height: 172px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        span {
            color: #BDBDBD;
            font-weight: bold;
            font-size: 14px;
        }
    }
}
</style>
