<template>
    <div class="tableTitle" style="height:CustomHeight">
        <span v-for="(items , index) in items" :key="index"
                :class=items.class @click="activeTitle(index)">
            <span>{{items.title}}</span>
        </span>
    </div>
</template>

<script>

export default {
  name: 'TheTableTitle',
  components :{
    
  },
  props : {
            items:Array,
            CustomHeight:String,
            
  },
  data() {
      return { 
        //  currentCurrency:"teter"
      }
  },
  methods : {
      activeTitle(index) {
          for(var i=0 ; i <= this.items.length-1 ; i++) {
              this.items[i].class="deActive"
          }
              this.items[index].class="active"
            // if(index==0) {
            //     this.currentCurrency='toman'
            // }
            // else {
            //     this.currentCurrency='teter'
            // }
            this.$emit('passcurrency' , index)
      }
  }
  }
</script>

<style lang="scss" scoped>
// .tableTitle {
//     size[m] {
//         height: 200px;
//     }
// }
    .tableTitle {
        background: var(--table-title);
        border-radius: 8px 8px 0px 0px;
        height: 42px;
        width: 100%;
        display: flex;
        margin-bottom:-2px;
    }
    .active , .deActive {
        width:50%;
        max-width:137px;
        // min-width:119px;
        // margin: 2px 2px 0 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px 6px 0px 0px;
        font-weight: bold;
        // font-size: 16px;
        font-size: clamp(14px , 1vw , 16px);
        cursor: pointer;
    }
    .active {
        background: var(--table-title-active);
        color: var(--font-strong);
        border: 2px solid var(--border-color);
        border-bottom: #001E3D;
        z-index: 1;
    }
    .deActive {
        color: #BDBDBD;
    }
</style>
