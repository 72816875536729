<template>
<div class="support">
    <NewMessage v-if="modal" @closeModal="modal=false" @sendMessage="getData"/>
    <div class="support-title">
        <span>
            پشتیبانی
        </span>
        <button class="add-btn" @click="modal=true">
            <img src="@/assets/Profile/pluse.svg" alt=""> افزودن گفتگو
        </button>
    </div>
    <div class="list-message-container">
        <div class="message" v-for="(message , index) in messages" :key="index">
            <div class="message-title" @click="showMessage(index)">
                <div>
                    <div class="dote" v-if="message.status=='OPENED'"></div>
                    <img src="@/assets/Profile/waite.svg" alt="" v-else-if="message.status=='PENDING'">
                    <img src="@/assets/Profile/closed.svg" alt="" v-else>
                    <span class="message-title">
                        {{message.subject}}
                    </span>
                </div>
                <div>
                    <span :class=message.status>
                        {{$G2J(message.createdAtDateTime)}}
                    </span>
                    <img src="@/assets/Home/Vector.svg" alt="">
                </div>
            </div>
            <div class="message-content-container" v-if="message.id==id" :key="reload">
                <div class="message-content" v-for="(detail , index) in details" :key="index">
                    <div v-if="detail.customer">
                        <img src="@/assets/Profile/send.svg" alt="">
                        <span class="customer-message">
                            {{detail.text}}
                        </span>
                        <span class="detail-date">
                            <!-- {{detail.date}} -->
                        </span>
                    </div>
                    <div v-if="detail.admin">
                        <img src="@/assets/Profile/adminMessage.svg" alt="">
                        <span class="admin-message">
                            {{detail.text}}
                        </span>
                        <span class="detail-date">
                            <!-- {{detail.date}} -->
                        </span>
                    </div>
                </div>
                <label for="" class="send-container">
                    <!-- <textarea v-model="data.text" class="resize-ta" type="text" @input="setHeight" @change="setMinHieght"> -->
                    <textarea class="input-message resize-ta" v-model="data.text" @input="setHeight" @change="setMinHieght"></textarea>
                    <div class="send-btns" @click="sendMessage">
                        <span class="send-p">
                            ارسال
                        </span>
                        <img src="@/assets/Profile/sendMessage.svg" alt="">
                    </div>
                </label>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import NewMessage from './SupportModals/NewMessage.vue'

export default {
    name: 'Support',
    components: {
        NewMessage

    },
    data() {
        return {
            modal: false,
            messages: [],
            content: [{}],
            details: [],
            id: '',
            reload: false,
            data: {
                type: '',
                subject: '',
                text: '',
            },
            types: [{
                    label: 'فنی',
                    value: 'TECHNICAL'
                },
                {
                    label: 'عمومی',
                    value: 'GENERAL'
                }
            ]
        }
    },
    computed: {
        statusLabel() {
            const a = {
                OPENED: 'باز',
                PENDING: 'در انتظار پاسخ',
                CUSTOMER_ANSWERED: 'پاسخ مشتری',
                ADMIN_ANSWERED: 'پاسخ پشتیبانی',
            }
            return e => a[e]
        },
        typeLabel() {
            const a = {
                TECHNICAL: 'فنی',
                GENERAL: 'عمومی',
            }
            return e => a[e]
        },
    },

    methods: {

        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/tickets')
            this.header = res.ticketDTO
            this.messages = res.content
        },
        async showMessage(index) {
            if (this.id == this.messages[index].id) {
                this.id = ''
            } else {
                this.state.loading = true
                this.id = this.messages[index].id
                const res = await this.$axios.get('/tickets/message/' + this.id)
                this.details = res.pageDTO.content.reverse()
                this.reload = !this.reload
                this.state.loading = false
            }

        },
        async showMessageAgain(index) {
            this.state.loading = true
            const res = await this.$axios.get('/tickets/message/' + index)
            this.details = res.pageDTO.content.reverse()
            this.reload = !this.reload
            this.state.loading = false
        },
        async sendMessage() {
            let formData = new FormData
            formData.append('text', this.data.text)
            const res = await this.$axios.post('/tickets/message/' + this.id, formData)
            this.details.push(res.baseDTO)
            this.data.text = ''
            this.calcHeight("");
            this.showMessageAgain(this.id)

        },
        setMinHieght() {
            let textarea = document.querySelector(".resize-ta");
            textarea.style.height = 40 + "px";
        },
        setHeight() {
            let textarea = document.querySelector(".resize-ta");
            if (textarea.value !== "") {
                textarea.style.height = this.calcHeight(textarea.value) + "px";
            } else {
                this.calcHeight(0);
            }
            // textarea.value.lenght=0
        },
        calcHeight(value) {
            let textarea = document.querySelector(".resize-ta");
            if (textarea.value !== "") {
                let numberOfLineBreaks = (value.match(/\n/g) || []).length;
                // min-height + lines x line-height + padding + border
                let newHeight = 20 + numberOfLineBreaks * 20 + 20;
                return newHeight;
            } else {
                let newHeight = 0;
                return newHeight;
            }
        },

    },
    mounted() {
        this.getData()
    }

}
</script>

<style lang="scss" scoped>
.support {
    display: flex;
    flex-grow: 1;
    max-width: 1118px;
    margin: 0 auto;
    margin-top: 38px;
    flex-direction: column;
    row-gap: 24px;
}

.support-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    width: 100%;

    button {
        width: 184px;
        height: 40px;
        background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
        border-radius: 6px;
        color: #FCFCFC;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        justify-content: center;
    }
}

.list-message-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.message {
    width: 100%;
    height: auto;
    background: #001E3D;
    border-radius: 8px;
    overflow: hidden;
    padding: 18px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.dote {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
}

.message-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    div {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }
}

.title-text {
    font-weight: normal;
    font-size: 16px;
}

.unRead,
.waite,
.OPENED,
.PENDING {
    font-weight: bold;
    font-size: 16px;
    color: #F2F2F2;
}

.closed {
    font-weight: normal;
    font-size: 16px;
    color: #828282;
}

.date {
    font-weight: bold;
    font-size: 16px;
    color: #BDBDBD;
}

.message-content-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    div {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
}

.customer-message {
    color: #FFFFFF;
    font-size: 14px;
}

.admin-message {
    color: #FFC04F;
    font-size: 14px;
}

.detail-date {
    font-size: 12px;
    color: #E0E0E0;
}

.send-container {
    position: relative;

    .send-btns {
        position: absolute;
        left: 10px;
        top: 0;
        height: 90%;
        align-items: flex-end;
        bottom: 5px;
        cursor: pointer;

        img {
            margin-bottom: 5px;
        }

        .send-p {
            color: #F2F2F2;
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
}

.resize-ta {
    background: var(--background-input);
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 4px;
    height: 44px;
    width: 100%;
    color: var(--font-strong);
    box-sizing: border-box;
    padding: 0 10px;
}

.input-message {
    display: flex;
    flex-grow: 1;
    border: none;
    box-sizing: border-box;
    border-radius: 2px;
    /* overflow: hidden; */
    height: 40px;
    overflow: hidden;
    resize: none;
    padding: 5px 15px;
    max-height: 150px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* background: transparent; */
    background: rgba(255, 255, 255, 0.05) !important;
}

.input-message::-webkit-scrollbar {
    display: none;
}

.input-message[contenteditable]:empty::before {
    content: "Placeholder still possible";
    color: gray;
}
.message-title {
    color: white;
}
</style>
