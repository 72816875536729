<template>
<div class="table-container">
    <perfect-scrollbar @ps-y-reach-end="check" ref="scroll">
        <table class="multi_color">
            <tr class="tr-heade" >
                <td v-for="(headeadvance , index) in headeadvance" :key="index" :class=headeadvance.class>
                    {{headeadvance.title}}
                    <span class="subTitle">
                        {{headeadvance.subTitle}}
                    </span>
                </td>
            </tr>
            <tr class="tr-body" v-for="(tableContent , index) in tableContents" :key="index">
                <td class="date number-font">
                    {{$G2J(tableContent.createdAtDateTime,'faDateTime')}}
                </td>
                <td :class="{'buy' : tableContent.orderType.includes('BUY') , 'sell' : tableContent.orderType.includes('SELL')}">
                    <span v-if="tableContent.orderType.includes('BUY')">
                        خرید
                    </span>
                    <span v-if="tableContent.orderType.includes('SELL')">
                        فروش
                    </span>
                </td>
                <td>
                    {{$coins[tableContent.destinationCoin].persianName}}
                </td>
                <td class="number-font">
                    
                    <!-- {{$coins[$marketType2relatedCoin(tableContent.marketType)].code}} -->
                    {{$toLocal(tableContent.amount)}}
                    &nbsp;<span v-if="tableContent.marketType.includes('TOMAN')"> تومان </span>&nbsp;
                     &nbsp;<span v-if="tableContent.marketType.includes('TETHER')"> تتر </span>&nbsp;
                </td>
                <td class="price number-font">
                    {{$toLocal(tableContent.unitPrice,$marketType2sourceCoin(tableContent.marketType)==='TOMAN'?0:2)}}
                     &nbsp;<span v-if="tableContent.marketType.includes('TOMAN')"> تومان </span>&nbsp;
                     &nbsp;<span v-if="tableContent.marketType.includes('TETHER')"> تتر </span>&nbsp;
                </td>
                <td class="amount number-font">
                    {{$toLocal(tableContent.amount *
                  tableContent.unitPrice,$marketType2sourceCoin(tableContent.marketType)==='TOMAN'?0:2)}}
                     &nbsp;<span v-if="tableContent.marketType.includes('TOMAN')"> تومان </span>&nbsp;
                     &nbsp;<span v-if="tableContent.marketType.includes('TETHER')"> تتر </span>&nbsp;
                </td>
                <td class="fee ">
                    <span class="number-font">{{tableContent.wagePercent}}%</span>
                </td>
                <!-- <td>

                </td> -->
            </tr>
        </table>
    </perfect-scrollbar>
</div>
</template>

<script>
export default {
    name: 'AdvancedHistory',
    components: {

    },
    data() {
        return {
            items: [{
                    title: "معاملات صرافی",
                    class: "active"
                },
                {
                    title: "معاملات پیشرفته",
                    class: "deActive"
                },
            ],
            headeEasy: [{
                    title: "زمان",
                    class: "date"
                },
                {
                    title: "نوع سفارش",
                },
                {
                    title: "رمز ارز",
                },
                {
                    title: "مقدار",
                },
                {
                    title: "قیمت",
                    subTitle: "",
                    class: "price"
                },
                {
                    title: "مجموع",
                    subTitle: "",
                    class: "amount"
                },
                {
                    title: "شناسه تراکنش",
                    class: "id"
                },
                {
                    title: "وضعیت",
                    class: "status"
                }
            ],
            headeadvance: [{
                    title: "زمان",
                    class: "date"
                },
                {
                    title: "نوع سفارش",
                },
                {
                    title: "رمز ارز",
                },
                {
                    title: "مقدار",
                },
                {
                    title: "قیمت",
                    subTitle: "",
                    class: "price"
                },
                {
                    title: "مجموع",
                    subTitle: "",
                    class: "amount"
                },
                {
                    title: "کارمزد",
                    class: "fee"
                },
                // {
                //     title: "وضعیت",
                //     class: "status"
                // }
            ],
            tableContents: [],
            lastPage: 1,
            page: 0,
            loading: false,
        }
    },
    methods: {
        async check() {
            this.state.loading = true
            if (this.page <= this.lastPage && !this.loading) { //for scroll loading
                this.loading = true
                this.page++ //for scroll loading
                const [res, ] = await this.$http.get('/trades', {
                    params: {
                        size: 10,
                        page: this.page,
                        type: 'P2P'
                    }
                })
                if (res) {
                    this.lastPage = res.totalPages
                    this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
                    // this.tableContents = res.content //for pagination
                }
                this.loading = false
            } //for scroll loading
            this.state.loading = false
        },

    },
    mounted() {
        this.check()
    }

}
</script>

<style lang="scss" scoped>
.table-container {
    height: 600px;
    overflow: scroll;
}
.table-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.history {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .history-title {
        font-weight: bold;
        font-size: 18px;
        margin: 16px 0;
        color: #FEFEFE;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 2px solid #4F4F4F;

    tr td:first-child {
        text-align: right;
        padding-right: 16px;
    }

    tr td:last-child {
        text-align: left;
        padding-left: 16px;
    }

    .tr-heade {
        height: 55px;
        background: #001E3D;
        font-weight: normal;
        font-size: 14px;
        color: #E0E0E0;

        td:last-child {
            border-top-left-radius: 6px;
        }

        .subTitle {
            font-size: 12px;
            color: #BDBDBD;
        }
    }

    .tr-body {
        font-weight: normal;
        font-size: 12px;
        color: #E0E0E0;
        height: 40px;

        .sell,
        .fail {
            color: #E43700;
        }

        .buy,
        .success {
            color: #B9FFA0;
        }

        .visit {
            border-bottom: 1px solid #E0E0E0;
            cursor: pointer;
        }
    }

    .tr-body:last-child {
        td:first-child {
            border-bottom-right-radius: 6px;
        }

        td:last-child {
            border-bottom-left-radius: 6px;
        }
    }
}
.tr-heade {
    position: sticky;
    top: 0;
}
@media only screen and (max-width:700px) {

    .status,
    .fee,
    .date,
    .price,
    .amount,
    .id,
    .detail {
        display: none;
    }
}
</style>
