<template>
<div class="transaction">
    <span class="transaction-title">
        تاریخچه تراکنش ها
    </span>
    <div>
        <TheTableTitle :items="items" />
        <DepositHistory v-if="items[0].class=='active'"/>
        <WithdrawHistory v-if="items[1].class=='active'"/>
    </div>
</div>
</template>

<script>
import TheTableTitle from '../../Public/TheTableTitle.vue'
import WithdrawHistory from './WithdrawHistory.vue'
import DepositHistory from './DepositHistory.vue'
export default {
    name: 'Transaction',
    components: {
        TheTableTitle,
        WithdrawHistory,
        DepositHistory
    },
    data() {
        return {
            withdraList: [],
            depositList: [],
            loading: false,
            setting: {
                suppressScrollX: false,
                swipeEasing: false,
                wheelPropagation: false,
            },
            label: {
                with: 'برداشت',
                dep: 'واریز'
            },
            lastPage: 1,
            page: 0,
            page2: 0,
            lastPage2: 1,
            modalshow: false,
            completedModal: false,
            removeid: '',

            items: [{
                    title: "واریز ",
                    class: "active"
                },
                {
                    title: "برداشت",
                    class: "deActive"
                }
            ],
            heade1: [{
                    title: "زمان",
                    class: "date-td"
                },
                {
                    title: "واریز"
                },
                {
                    title: "مقدار",
                    title2: "تومان"
                },
                {
                    title: "شماره شبا/کارت",
                    class: "shaba-td"
                },
                {
                    title: "وضعیت"
                },
                {
                    title: "شماره پیگیری",
                    class: "id-td"
                }
            ],
            heade2: [{
                    title: "زمان",
                    class: "date-td"
                },
                //  {
                //      title:"واریز"
                //  },
                {
                    title: "رمز ارز",
                    class: "crypto-td"
                },
                {
                    title: "مقدار"
                },
                {
                    title: "/شبکه",
                    class: "net-td"

                },
                {
                    title: "آدرس مقصد",
                    class: "adress-td"
                },
                {
                    title: "وضعیت",
                    class: "status-td"
                },
                {
                    title: "شناسه تراکنش"
                }
            ],
        }
    },

    methods: {
        
       

    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
.transaction {
    flex-grow: 1;
    background: #001E3D;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.transaction-title {
    font-weight: 500;
    font-size: 18px;
    color: #FEFEFE;
}

.table-container {
    display: flex;
    flex-direction: column;
    // padding: 0 2px 2px 2px;
    // background: #4F4F4F;
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 2px solid #4F4F4F;

    td:first-child {
        text-align: right;
        padding-right: 14px;
        // border-top-right-radius: 8px;
    }

    td:last-child {
        text-align: left;
        padding-left: 14px;
        // border-top-left-radius: 8px;
    }
}

.tr-heade {
    height: 56px;
    font-weight: normal;
    font-size: 14px;
    color: #FEFEFE;
    background: #001E3D;
}

.tr-body {
    height: 40px;
    font-weight: normal;
    font-size: 14px;
    color: #E0E0E0;
}

.success,
.deposit {
    color: #B9FFA0;
}

.fail,
.withdrawal {
    color: #E43700;
}

.title2 {
    font-weight: normal;
    font-size: 14px;
    color: #BDBDBD;
}

.detail {
    font-weight: normal;
    font-size: 14px;
    color: #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
}

@media only screen and (max-width:700px) {

    .date-td,
    .net-td,
    .shaba-td,
    .id-td,
    .adress-td,
    .crypto-td,
    .status-td {
        display: none;
    }
}
</style>
