<template>
<div class="twofa">
    <WithGoogle v-if="options[2].modal" @closeModal='closeG' @submit="submitted($event)" :qr-code="qrCode" :two-step="modal" />
    <WithPhone v-if="options[1].modal" @closeModal='closeP' @submit="submitted($event)" :mobile="mobile" :two-step="modal" />
    <WithEmail v-if="options[0].modal" @closeModal='closeE' @submit="submitted($event)" :qr-link="qrLink" :two-step="modal" />
    <span class="title">
        شناسایی دوعاملی
    </span>
    <span class="describe">
        تایید هویت دو مرحله ای جهت ورود به حساب کاربری و درخواست برداشت.
    </span>
    <form class="options-container" @change="showModal(options.findIndex(e => e.model === twoStep))">
        <div class="option" v-for="(options , index) in options" :key="index">
            <input type="radio" class="modal-btn" v-model="twoStep" :value="options.model" />
            <span>
                {{options.title}}
            </span>
        </div>
    </form>
</div>
</template>

<script>
import WithGoogle from './SettingModals/WithGoogle.vue'
import WithPhone from './SettingModals/WithPhone.vue'
import WithEmail from './SettingModals/WithEmail.vue'
export default {
    name: 'TwoFA',
    components: {
        WithGoogle,
        WithPhone,
        WithEmail
    },

    data() {
        return {
            twoStep: this.state.towStepAuthenticationType,
            qrCode: '',
            qrLink: '',
            mobile: '',
            completedModal: false,
            completedText: '',
            modal: '',
            options: [{
                    title: "ایمیل",
                    modal: false,
                    model: 'EMAIL'
                },
                {
                    title: "پیامک",
                    modal: false,
                    model: 'SMS'
                },
                {
                    title: "شناسایی دو عاملی گوگل",
                    modal: false,
                    model: 'GOOGLE_AUTHENTICATOR'
                }
            ]
        }
    },
    methods: {
        //   OpenModal(index) {
        //       this.options[index].modal=true
        //   },
        closeG(value) {
            this.options[2].modal = value
        },
        closeP(value) {
            this.options[1].modal = value
        },
        closeE(value) {
            this.options[0].modal = value
        },
        async getTwoStepAuth() {
            this.twoStep = this.state.userInfo.towStepAuthenticationType

        },
        async showModal(index) {
            this.state.loading = true
            const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twoStep}`).catch(() => {
                this.getTwoStepAuth()
            })
            if (res) {
                this.mobile = res.baseDTO.starTwoStepAuthentication || ''
                this.qrCode = res.baseDTO.file || ''
                this.qrLink = res.baseDTO.qrCodeLink || ''
                this.options[index].modal = true
            }
            this.getTwoStepAuth()
        },
        async submitted(e) {
            this.completedText = e;
            await this.$error('', e, 'success')
            await this.$getuserInfo()
            this.getTwoStepAuth()
        },
    },
    mounted() {
        this.getTwoStepAuth()
    }
}
</script>

<style lang="scss" scoped>
.twofa {
    flex-grow: 3;
    background: #001E3D;
    border-radius: 8px;
    max-width: 464px;
    // height: 100%;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .title {
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
    }

    .describe {
        font-weight: normal;
        font-size: 14px;
        color: #FFFFFF;
    }

    .options-container {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        margin-top: 24px;

        .option {
            display: flex;
            column-gap: 16px;
            align-items: center;
            font-weight: normal;
            font-size: 14px;
            color: #FFFFFF;

            button {
                background: #2E414E;
                border: 1px solid rgba(199, 199, 199, 0.22);
                box-sizing: border-box;
                border-radius: 2px;
                width: 16px;
                height: 16px;

            }
        }
    }
}
</style>
