<template>
<div class="cryptoes">
    <div class="table-container">
        <table class="multi_color">
            <tr class="tr-heade">
                <td v-for="(heade , index) in heade" :key="index" :class=heade.class>
                    {{heade.title}} <span class="subTitle">{{heade.subTitle}}</span>
                </td>
            </tr>
            <tr class="tr-body" v-for="(coin , index) in coins" :key="index" @click="goto($coin2Snake[coin.relatedCoin])">
                <td class="name">
                    <img class="crypto-img" :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" alt="">
                    <span>{{$coinLabel[coin.relatedCoin]}}</span>
                </td>
                <td class="number-font">
                    {{$toLocal(coin.lastPrice)}}
                </td>
                <td class="number-font" :class="{'negative-change' : coin.last24HourChange < 0 , 'positive-change' : coin.last24HourChange > 0}">
                    %
                     {{Math.abs(coin.last24HourChange).toFixed(2)}}
                    <span v-if=" coin.last24HourChange < 0"> - </span>
                    <span v-if="coin.last24HourChange > 0"> + </span>
                </td>
            </tr>
        </table>
    </div>
</div>
</template>

<script>
export default {
    name: 'Allcryptoes',
    components: {

    },
    data() {
        return {
            heade: [{
                    title: "ارز"
                },
                //  {
                //    title:"قیمت خرید",
                //    subTitle:"تومان",
                //    class:"buy-td"
                //  },
                //  {
                //    title:"قیمت فروش",
                //    subTitle:"تومان",
                //    class:"sell-td"
                //  },
                {
                    title: "قیمت جهانی",
                    subTitle: "تومان"
                },
                {
                    title: "تغییرات"
                }
            ],
            coins: [],
            currency: "toman",
            TomanCoin: 'market_type=BITCOIN_TOMAN' +
                '&market_type=BITCOIN_CASH_TOMAN' +
                '&market_type=ETHEREUM_TOMAN' +
                '&market_type=LITE_COIN_TOMAN' +
                '&market_type=BINANCE_COIN_BSC_TOMAN' +
                '&market_type=STELLAR_TOMAN' +
                '&market_type=TRON_TOMAN' +
                '&market_type=CELO_TOMAN' +
                '&market_type=RIPPLE_TOMAN' +
                '&market_type=DOGE_COIN_TOMAN' +
                '&market_type=MATIC_TOMAN' +
                '&market_type=TETHER_TOMAN',
        }
    },
    methods: {
        // getCurrency(value) {
        //   alert(value)
        //   // this.currency=value
        //   this.setData()
        // },
        async setData() {
            const res = await this.$axios.get('/trades/market-info?' + this.TomanCoin, {
                params: {
                    weeklyDataIncluded: true,
                    // marketType: this.active ? this.TomanCoin : this.TetherCoin
                }
            })
            this.coins = res.content
            // this.tableContents = res.content;
            // this.$emit('get-data', res.content)
        },
        goto(e) {
            this.$router.push('trade/' + 'TOMAN' + `/${e}`)
        },
    },
    mounted() {
        this.setData()
    }
}
</script>

<style lang="scss" scoped>
.cryptoes {
    // max-width: 824px;
    min-height: 360px;
    background: #001E3D;
    border-radius: 8px;
    min-width: 300px;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 24px;
}

.table-container {
    background: #1F3946;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 8px;
    height: 312px;
    overflow: scroll;

    table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;

        tr td:first-child {
            text-align: right;
            padding-right: 24px;
        }

        tr td:last-child {
            text-align: left;
            padding-left: 24px;
        }

        tr:first-child td:first-child {
            border-top-right-radius: 6px;
        }

        tr:first-child td:last-child {
            border-top-left-radius: 6px;
        }

        tr:last-child td:first-child {
            border-bottom-right-radius: 6px;
        }

        tr:last-child td:lastu-child {
            border-bottom-left-radius: 6px;
        }

        .tr-heade {
            height: 52px;
            background: #0A293D;
            font-weight: bold;
            font-size: 14px;
            color: #FEFEFE;
                position: sticky;
                top: 0;

            .subTitle {
                font-weight: normal;
                font-size: 12px;
                color: #BDBDBD;
            }
        }

        .tr-body {
            height: 56px;
            font-weight: normal;
            font-size: 14px;
            color: #FCFCFC;
            cursor: pointer;

            .crypto-img {
                width: 32px;
                height: 32px;
                vertical-align: inherit;
                margin-left: 14px;
            }
        }

        .tr-body:hover {
            background-color: rgba(253, 215, 91, 0.3) !important;
        }
    }
}

.table-container::-webkit-scrollbar {
    display: none;
}

.table-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media only screen and (max-width:600px) {

    .sell-td,
    .buy-td,
    .crypto-img {
        display: none;
    }
}
</style>
