<template>
<div class="home-help">
    <Baner />

    <div class="home middle-home">

        <Carosel />
        <CurrencyTable />
        <WhyBeebitex />
    </div>

    <div class="home">
        <AccessBeebitex />
    </div>
    <Education />
</div>
</template>

<script>
import CurrencyTable from './CurrencyTable.vue'
import WhyBeebitex from './WhyBeebitex'
import AccessBeebitex from './AccessBeebitex.vue'
import Carosel from './Carosel.vue'
import Baner from './Baner.vue'
import Education from './Education.vue'
export default {
    name: 'HomePage',
    components: {
        CurrencyTable,
        WhyBeebitex,
        AccessBeebitex,
        Carosel,
        Baner,
        Education
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.home-help {
    width: 100%;
}

.middle-home {
    margin-top: 50px;
}
</style>
