<template>
<transition>
    <div class="modal-backdrop" @click.self="$emit('closeModaleEmit')">
        <div class="modal">
            <img @click="closeModale" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt="">
            <div class="modal-container">
                <span class="modal-title">
                    واریز تومانی
                </span>
                <span class="describe">
                    جهت افزایش اعتبار کیف پول تومان خود با استفاده از کارت‌های بانکی عضو شبکه شتاب و از طریق درگاه پرداخت،حتما باید از کارت‌های بانکی به نام خودتان که در پروفایلتان تایید شده است استفاده نمایید.
                </span>
            </div>
            <form class="modal-body">
                <label for="">
                    <input type="text" name="" id="" placeholder="مبلغ ورودی" @input="amount = $toLocal(amount)" v-model="amount">
                </label>
                <label for="">
                    <div>
                        <span class="add-bank" @click="$router.push('account')">
                            افزودن کارت بانکی
                        </span>
                    </div>
                    <select class="col-12" v-model="walletAddress" id="walletaddress">
                        <option value="" disabled selected>انتخاب شماره کارت</option>
                        <option v-for="(Bank,index) in Banks" :key="index" :value="Bank.cardNumber">
                            {{Bank.cardNumber}}
                        </option>
                    </select>
                </label>
                <button class="confirm" :disabled="!amount || !walletAddress" hidden type="submit">
                    انتقال به درگاه پرداخت
                </button>
            </form>

            <button :disabled="!amount || !walletAddress || ($S2N(amount) < 1000)" type="button" class="confirm" @click="deposit" aria-label="Close modal">
                انتقال به در گاه پرداخت
            </button>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'DepositToman',
    components: {},
    props: {
        relatedCoin: {
            default: ''
        },
    },
    data() {
        return {
            amount: '',
            Banks: [],
            walletAddress: '',
            walletInfo: {
                amount: 0,
                // cardNumber: this.Bank.cardNumber,
                paymentGateway: 'ZARRINPAL'
            }
        }
    },
    methods: {
        closeModale() {
            this.$emit('closeModaleEmit')
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/verified-bank-accounts')
            this.Banks = res.content
        },
        async deposit() {
            this.state.loading = true
            let walletInfo = {
                amount: this.$S2N(this.amount),
                paymentGateway: 'ZARRINPAL',
                cardNumber: this.walletAddress,
                callBackURL: this.$callBackURL
            }

            const res = await this.$axios.post('/wallets/deposit-toman', walletInfo)

            if (res.message === "Deposit Request Created Successfully") {
                this.$emit('close');
                window.open(res.baseDTO.redirectSite, "_self")
            }
        },
    },
    mounted() {
        this.getData()
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    min-height: 377px;
    background: var(--background-child);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.modal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.close-icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
}

.modal-title {
    font-weight: bold;
    font-size: 16px;
    color: #F2F2F2;
    text-align: center;
    width: 100%;
}

.describe {
    font-weight: normal;
    font-size: 14px;
    color: var(--font-weake);
}

.confirm {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 40px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;

    .add-bank {
        cursor: pointer;
    }
}

label {
    img {
        position: absolute;
        left: 10px;
        top: 40px;
        cursor: pointer;
    }

    .banks-container {
        position: absolute;
        width: 100%;
        height: 70px;
        background: #001E3D;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid rgba(199, 199, 199, 0.22);

        .option-bank {
            width: 100%;
            background: rgba(105, 105, 105, 0.38);
            height: 36px;
        }
    }

    div {
        font-weight: normal;
        font-size: 12px;
        color: #E0E0E0;
        text-align: left;
        margin-bottom: 5px;

        span {
            border-bottom: 1px solid #E0E0E0;
        }
    }
}
</style>
