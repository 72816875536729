<template>
<transition>
    <div class="modal-backdrop">
        <div class="modal">
            <img @click="closeModale" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt="">
            <div class="modal-container">
                <span class="modal-title">
                    <span>
                        حذف درخواست
                    </span>
                </span>
                <div class="amounts">
                    ایا از لغو درخواست خود مطمئن هستید؟
                </div>
            </div>
            <button class="add-btn" @click="remove">
                ثبت
            </button>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'DeleteModal',
    components: {

    },

    data() {
        return {
            open: true,

        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        remove() {
            this.$emit('remove');
            this.$emit('close');
        }
    },
    created: function () {
        window.addEventListener("click", (event) => {
            if (!event.target.closest('.modal , .delete-with')) {
                this.$emit('close')
            } else {
                return true
            }

        })
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    min-height: 200px;
    background: var(--background-child);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
}

.close-icon {
    position: absolute;
    cursor: pointer;
}

.modal-title {
    font-weight: bold;
    font-size: 16px;
    color: #F2F2F2;
    text-align: center;
    width: 100%;
}

.describe {
    font-weight: normal;
    font-size: 14px;
    color: var(--font-weake);
}

.warning {
    color: #E43700;
    font-weight: normal;
    font-size: 12px;
}

.qr-img {
    width: 114px;
    height: 114px;
    margin: 0 auto;
}

.modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

button {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 40px;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.amounts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.7;
    color: #F2F2F2;
    font-weight: 300;
    font-size: 12px;
    flex-direction: column;
}

.price {
    font-weight: bold;
    font-size: 12px;
}

.crypto-amount,
.price-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.add-btn {
    background: linear-gradient(270deg, rgba(255, 47, 1, 0.97) 0%, rgba(255, 61, 0, 0.979333) 32.6%, rgba(255, 92, 0, 0.984313) 64.37%, #FF3D00 100%);
    border-radius: 6px;
}
</style>
