<template>
    <div class="setting">
        <div class="setting-operation">
            <TwoFA/>
            <ChangePass/>
        </div>
        <div class="history-container">
            <LoginHistory/>
        </div>
    </div>
</template>

<script>
import TwoFA from './TwoFA.vue'
import ChangePass from './ChangePass.vue'
import LoginHistory from './LoginHistory.vue'
export default {
  name: 'Setting',
  components :{
    TwoFA,
    ChangePass,
    LoginHistory
  },

  data() {
      return { 
        
      }
  },
  methods : {
      
  }
  }
</script>

<style lang="scss" scoped>
    .setting {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        margin-top: 5px;
    }
    .setting-operation {
        min-height: 271px;
        display: flex;
        column-gap: 16px;
        flex-wrap: wrap;
        row-gap: 16px;
    }
    .history-container {
        background: #001E3D;
        border-radius: 8px;
        min-height: 447px;
        box-sizing: border-box;
        padding: 16px 32px;
    }
</style>
