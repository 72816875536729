<template>
<div class="bank">
    <DeleteBankModal v-if="opendelet" @close="opendelet=false" @remove="deleteBank" />
    <span class="bank-title">
        اطلاعات بانکی
    </span>
    <div class="inputs-container">
        <span class="inputs-title">
            حساب جدید
        </span>
        <div class="inputs">
            <label>
                <input class="col-12" @input="bankAccount.shebaCode = bankAccount.shebaCode.replace(/[^\d.]|\.\./ig,'');error='';" type="text" maxlength="24" v-model="bankAccount.shebaCode" placeholder="شماره شبا">

                <p class="error" v-if="error.includes(bankAccount.shebaCode) && bankAccount.shebaCode !== ''">شبا
                    تکراریست</p>
            </label>
            <label>
                <input @input="dash2" type="text" v-model="bankAccount.cardNumber" maxlength="19" placeholder="شماره کارت">
                <p class="error" v-if="error.includes(bankAccount.cardNumber) && bankAccount.cardNumber !== ''">
                    شماره کارت تکراریست</p>
            </label>
            <label>
                <input class="col-12" type="text" v-model="bankAccount.bankName" readonly placeholder="نام بانک">

            </label>
            <span class="add-delete" @click.prevent="addaccount">
                افزودن حساب جدید
            </span>
        </div>

    </div>
    <div class="inputs-container" :key="change">
        <span class="inputs-title">
            حساب های قبلی
        </span>
        <div class="inputs" v-for="(bank , index) in data.bankAccounts" :key="index">
            <label :class="bank.class">
                <input type="text" v-model="bank.shebaCode" disabled>
            </label>
            <label :class="bank.class">
                <input type="text" v-model="bank.cardNumber" disabled>
            </label>
            <label :class="bank.class">
                <input type="text" v-model="bank.bankName" disabled>
            </label>
            <span class="add-delete" @click.prevent="removeaccount(index)" v-if="data.bankAccounts.length >= 2">
                حذف
            </span>
        </div>
    </div>
</div>
</template>

<script>
const banks = {
    '603799': 'بانک ملی ایران',
    '589210': 'بانک سپه',
    '627648': 'بانک توسعه صادرات',
    '207177': 'بانک توسعه صادرات',
    '627353': 'بانک تجارت',
    '585983': 'بانک تجارت',
    '627961': 'بانک صنعت و معدن',
    '603770': 'بانک کشاورزی',
    '639217': 'بانک کشاورزی',
    '628023': 'بانک مسکن',
    '627760': 'پست بانک ایران',
    '502908': 'بانک توسعه تعاون',
    '627412': 'بانک اقتصاد نوین',
    '622106': 'بانک پارسیان',
    '639194': 'بانک پارسیان',
    '627884': 'بانک پارسیان',
    '502229': 'بانک پاسارگاد',
    '639347': 'بانک پاسارگاد',
    '639599': 'بانک قوامین',
    '504706': 'بانک شهر',
    '502806': 'بانک شهر',
    '603769': 'بانک صادرات',
    '610433': 'بانک ملت',
    '991975': 'بانک ملت',
    '589463': 'بانک رفاه',
    '502938': 'بانک دی',
    '639607': 'بانک سرمایه',
    '627381': 'بانک انصار',
    '505785': 'بانک ایران زمین',
    '636214': 'بانک آینده',
    '636949': 'بانک حکمت ایرانیان',
    '621986': 'بانک سامان',
    '639346': 'بانک سینا',
    '606373': 'بانک قرض الحسنه مهر',
    '627488': 'بانک کارآفرین',
    '502910': 'بانک کارآفرین',
    '505416': 'بانک گردشگری',
    '639370': 'بانک مهر اقتصاد',
    '628157': 'موسسه اعتباری توسعه',
    '505801': 'موسسه اعتباری کوثر',
}
import DeleteBankModal from './DeleteBankModal.vue'
export default {
    name: 'BankInformation',
    components: {
        DeleteBankModal
    },
    props: ['data', 'error', 'change'],
    data() {
        return {
            bankAccount: {
                shebaCode: '',
                cardNumber: '',
                bankName: ''
            },
            opendelet: false,
            bankIndex: ""
        }
    },
    methods: {
        addaccount() {
            if (this.bankAccount.shebaCode && this.bankAccount.cardNumber) {
                this.data.bankAccounts.push(this.bankAccount)
                this.$emit('addBank', this.bankAccount)
                this.bankAccount = {
                    shebaCode: '',
                    cardNumber: '',
                    bankName: ''
                }
            }

        },
        removeaccount(e) {
            this.opendelet = true
            this.bankIndex = e
        },
        deleteBank() {
            this.data.bankAccounts.splice(this.bankIndex, 1)
        },
        dash(e) {
            this.data.bankAccounts[e].bankName = banks[this.data.bankAccounts[e].cardNumber ? this.data.bankAccounts[e].cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
            // this.data.bankAccounts[e].cardNumber = this.$separator(this.data.bankAccounts[e].cardNumber)
            this.error = ''
        },

        dash2() {
            this.bankAccount.bankName = banks[this.bankAccount.cardNumber ? this.bankAccount.cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
            // this.bankAccount.cardNumber = this.$separator(this.bankAccount.cardNumber)
            this.error = ''
        }
    },
    mounted() {
        // eslint-disable-next-line no-unused-vars
        for (let [index, item] of this.data.bankAccounts.entries()) {
            this.dash(index)
        }
    }

}
</script>

<style lang="scss" scoped>
.bank {
    background: #001E3D;
    border-radius: 8px;
    flex-grow: 1;
    min-height: 300px;
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.bank-title {
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
}

.inputs-title {
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
}

.add-delete {
    font-weight: normal;
    font-size: 12px;
    text-decoration: underline;
    color: rgba(255, 185, 4, 1);
    cursor: pointer;
}

.inputs-container {
    display: flex;
    margin: 16px 0;
    flex-direction: column;
    row-gap: 8px;
}

.card,
.shaba {
    flex-grow: 2;
    max-width: 362px;
}

.bankName {
    flex-grow: 1;
    max-width: 169px;
}

.inputs {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    column-gap: 24px;
    align-items: center;
    row-gap: 32px;
}
</style>
