<template>
<div class="statistics">
    <div>
        <span class="statistics-title">
            تعداد دوستان من
        </span>
        <span class="friends number-font">
             {{$toLocal(data.countInvitees) || 0}} نفر
        </span>
    </div>
    <div>
        <span class="statistics-title">
            مجموع سود من
        </span>
        <span class="income number-font">
             {{$toLocal(data.profit) || 0}} تومان
        </span>
    </div>
    <button class="statistics-btn" @click.prevent="$router.push('invite-friend')">
        دعوت از دوستان
    </button>
</div>
</template>

<script>
export default {
    name: 'Statistics',
    components: {

    },
    data() {
        return {
            data: {
                countInvitees: 0,
                profit: '',
                referralCode: '',
            }
        }
    },
    methods: {
        async Copy(e, t) {
            await navigator.clipboard.writeText(e)
            alert(t + ' کپی شد')
        }
    },
    async mounted() {
        const res = await this.$axios.get('/users/invites')
        this.data = res.baseDTO
    }
}
</script>

<style lang="scss" scoped>
.statistics {
    max-width: 344px;
    min-height: 360px;
    background: #001E3D;
    border-radius: 8px;
    min-width: 300px;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
    }

    .statistics-title {
        font-weight: bold;
        font-size: 20px;
        color: #E0E0E0;
    }

    .friends {
        font-weight: bold;
        font-size: 24px;
        color: #FCFCFC;
    }

    .income {
        font-weight: normal;
        font-size: 18px;
        color: #F2F2F2;
    }

    .statistics-btn {
        background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
        border-radius: 6px;
        font-weight: bold;
        font-size: 14px;
        color: #FCFCFC;
        height: 40px;
        width: 100%;
    }
}
</style>
