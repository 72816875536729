<template>
<!-- <Sidebar/> -->
<div class="navbar" :class="{'nav-homepage': $route.path=='/'}">
    <!-- <Sidebar/> -->
    <div class="nav-container">

        <!-- <button @click="showSidebar"></button> -->
        <div class="logo-in-profile" v-if="$route.path.includes('/profile')" @click="showSide">
            <!-- <img class="main-logo" src="@/assets/Home/logo1.png" alt="">  -->
            <img class="profileHam" src="@/assets/Profile/profileHam.svg" alt="">
        </div>
        <transition name="fade">
            <Sidebar class="side-navbar" @ExitModal="openModal" v-if="sideNav" />
        </transition>
        <ExitModal v-if="exit" @closeModal="closeModal" />
        <div class="items-container" v-if="!$route.path.includes('/profile')">
            <a v-for="(items , index) in items" :key="index" :class="items.class" :href="items.link" @click.prevent="$router.push(items.link)">
                <span :class="items.class">
                    {{items.title}}
                    <inline-svg v-if="items.title==='راهنما'" :src="require('../../assets/Home/Vector.svg')" />
                </span>
                <img :class=items.imgClass :src=items.img alt="">
            </a>
            <div v-if="guidesItem" class="guides-item">
                <a v-for="(guides , index) in guides" :key="index" :href="guides.link" @click.prevent="$router.push(guides.link)">
                    {{guides.title}}
                </a>
            </div>
        </div>
        <div class="buttons-container" v-if="!$route.path.includes('/profile') && !state.token">
            <a v-for="(buttons , index) in buttons" :key="index" :href=buttons.link @click.prevent="$router.push(buttons.link)">
                <button :class=buttons.class>
                    {{buttons.title}}
                </button>
            </a>
        </div>
        <div v-if="!$route.path.includes('/profile') && state.token" @click="$router.push('/profile/account')" class="direct_to_profile">
            ورود به حساب کاربری
        </div>
        <div class="icons-container" v-if="$route.path.includes('/profile')">
            <img alt="" src='@/assets/Profile/toLight.svg' v-if="$route.name=='Trade' && state.theme=='dark'" @click="state.theme='light'">
            <img alt="" src='@/assets/Profile/toDark.svg' v-if="$route.name=='Trade' && state.theme=='light'" @click="state.theme='dark'">
            <img src="@/assets/Profile/noti.svg" alt="" class="notification" v-if="$route.name!=='Trade'">
            <a :href=icon.link @click.prevent="$router.push({name:icon.link})" v-for="(icon , index) in icon" :key="index" :class=icon.class>
                <!-- <img alt="" :src=icon.img> -->
                 <inline-svg class="profile-icons" :src=icon.img />
            </a>
            <div class="notif-box">
                <span class="box-title">
                    اعلانات
                </span>
                <div class="notif-list-container" :key="unreadmessages.length">
                    <!-- notifications -->
                    <perfect-scrollbar style="direction:ltr;padding-right:10px"  @ps-y-reach-end="unreadmessages.length < unreadTotalElements ? load('unread') : ''">
                        <div v-if="unreadmessages==''" :style="{'color' : 'red'}">
                            پیام جدیدی وجود ندارد
                        </div>
                        <template v-else>
                            <div v-for="(notif , index) in unreadmessages" :key="index">
                                <div class="text-container">
                                    <div class="reade-dote"></div>
                                    <span class="notif-text">
                                        {{notif.title}}
                                    </span>
                                </div>
                                <span class="date">
                                    {{$G2J(notif.createdAtDateTime)}}
                                </span>
                            </div>
                        </template>
                    </perfect-scrollbar>
                    <a href='notification' @click.prevent="$router.push({name:'Notification'})">
                        <span class="notif-link">مشاهده همه</span>
                    </a>
                </div>
            </div>

        </div>
        <CornerNavIcon class="icons-btn" v-if="$route.path.includes('/profile')" />
    </div>
    <div id="menu-bar" v-if="!$route.path.includes('/profile')">
        <div id="menu" @click="onClickMenu">
            <div id="bar1" class="bar"></div>
            <div id="bar2" class="bar"></div>
            <div id="bar3" class="bar"></div>
        </div>
        <ul class="nav" id="nav">
            <li v-for="(mobileItem , index) in mobileItem" :key="index">
                <a :href=mobileItem.link @click.prevent="$router.push(mobileItem.link)">{{mobileItem.title}}</a>
            </li>
        </ul>
    </div>
    <div class="menu-bg" id="menu-bg" v-if="!$route.path.includes('/profile')"></div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import Sidebar from '../Profile/Sidebar.vue';
import CornerNavIcon from './CornerNavIcon.vue'
import ExitModal from '../Profile/ExitModal.vue'
export default {
    name: 'Navbar',
    components: {
        InlineSvg,
        Sidebar,
        CornerNavIcon,
        ExitModal
    },
    data() {
        return {
            guidesItem: false,
            exit: null,
            test: false,
            sideNav: false,
            unreadTotalElements:0,
            items: [
                // {
                //     link: "/",
                //     class: "logo",
                //     img: require('../../assets/Home/vistaLogo.svg'),
                //     imgClass: "main-logo"
                // },
                {
                    title: "بازار ها",
                    link: "/markets",
                    class: "items-list"
                },
                {
                    title: "صرافی",
                    link: "/profile/exchange",
                    class: "items-list"
                },
                {
                    title: "معامله سریع",
                    link: "/profile/otc",
                    class: "items-list"
                },
                {
                    title: "راهنما",
                    link: "",
                    class: "guide",
                }
            ],
            buttons: [{
                    title: "ورود",
                    link: "/login",
                    class: "login-btn"
                },
                {
                    title: "ثبت نام",
                    link: "/signup",
                    class: "sub-btn"
                }
            ],
            guides: [{
                    title: "راهنمای استفاده",
                    link: "/guide"
                },
                {
                    title: "سوالات متداول",
                    link: "/FAQ"
                },
                {
                    title: "قوانین و مقررات",
                    link: "/policy"
                },
                {
                    title: "کارمزدها و سطوح کاربری",
                    link: "/fees"
                },
                {
                    title: "بلاگ تیرکس",
                    link: "/blog/"
                },
                {
                    title: "درباره ما",
                    link: "/about-us"
                }
            ],
            mobileItem: [{
                    title: "بازار ها",
                    link: "/markets",
                    class: "items-list"
                },
                {
                    title: "صرافی",
                    link: "/profile/exchange",
                    class: "items-list"
                },
                {
                    title: "معامله سریع",
                    link: "/profile/otc",
                    class: "items-list"
                },
                {
                    title: "راهنمای استفاده",
                    link: "/guide"
                },
                {
                    title: "سوالات متداول",
                    link: "/FAQ"
                },
                {
                    title: "قوانین و مقررات",
                    link: "/policy"
                },
                {
                    title: "کارمزدها و سطوح کاربری",
                    link: "/fees"
                },
                {
                    title: "بلاگ تیرکس",
                    link: "/blog/"
                },
                {
                    title: "درباره ما",
                    link: "/about-us"
                }
            ],
            icon: [
                //  {
                //    img:require('../../assets/Profile/noti.svg'),
                //    class:"notification"
                //  },
                {
                    img: require('../../assets/Profile/support.svg'),
                    link: "Support"
                },
                {
                    img: require('../../assets/Profile/mobile.svg')
                },
                {
                    img: require('../../assets/Profile/line.svg')
                },
                {
                    img: require('../../assets/Profile/dashboard.svg'),
                    link: 'Dashboard'
                }
            ],
            notif: [{
                    title: "احراز هویت شما تایید شد",
                    date: "11/02/00 - 14:12 "
                },
                {
                    title: "احراز هویت شما تایید شد",
                    date: "11/02/00 - 14:12 "
                },
                {
                    title: "احراز هویت شما تایید شد",
                    date: "11/02/00 - 14:12 "
                },
                {
                    title: "احراز هویت شما تایید شد",
                    date: "11/02/00 - 14:12 "
                }
            ],
            unreadmessages: [],
            page: 1,
            perPage: 6,
        }
    },
    methods: {
        onClickMenu() {
            document.getElementById("menu").classList.toggle("change");
            document.getElementById("nav").classList.toggle("change");

            document.getElementById("menu-bg").classList.toggle("change-bg");
        },
        // showSide() {
        //   this.test=true
        // },
        showSide() {
            this.sideNav = true
        },
        closeModal(value) {
            this.exit = value
        },
        openModal(value) {
            this.exit = value
        },
        async getUnreadMessages(page = this.page, perPage = this.perPage) {
            if(this.$route.path.includes('profile')) {
                 this.state.loading = true
            const res = await this.$axios.get('/users/notifications', {
                params: {
                    read: false,
                    page: page,
                    size: perPage,
                }
            })
            if (res.content) {
                this.unreadmessages.push(...res.content)
                this.unreadTotalElements = res.totalElements
            }
            else true
            }
           
        },
    },
    mounted() {
        this.getUnreadMessages()
    },
    created: function () {
        window.addEventListener("click", (event) => {
            if (event.target.closest('.guide')) {
                this.guidesItem = !this.guidesItem
            } else if (!event.target.closest('.logo-in-profile , .side-navbar')) {
                this.sideNav = false
            } else {
                this.guidesItem = false
            }
        })
    },
}
</script>

<style lang="scss" scoped>
.navbar {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #FEFEFE;
    background: rgba(7, 29, 54, 1);
    z-index: 10;

    // max-width: 1440px;
    .nav-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // max-width: 1410px;
        height: 48px;
        margin: 0 15px;
        position: relative;

        .items-container {
            display: flex;
            column-gap: 70px;
            position: relative;

            .guide,
            .items-list {
                display: flex;
                column-gap: 12px;
                align-items: center;
                cursor: pointer;
                color: #fff;
            }

            .logo {
                font-size: 24px;
                cursor: pointer;
                display: flex;
            }
        }

    }
}

.login-btn,
.sub-btn {
    width: 88px;
    height: 32px;
    border: none;
    border-radius: 6px;
    color: #FEFEFE;
    font-weight: bold;
    font-size: 14px;
}

.guide:hover,
.items-list:hover {
    color: #FFC04F;
}

.sub-btn {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
}

.login-btn {
    background: transparent;
}

.guide {
    position: relative;
}

.guides-item {
    width: 162px;
    height: 256px;
    background: #03111F;
    border-radius: 4px;
    position: absolute;
    top: 36px;
    left: -50px;
    box-sizing: border-box;
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: normal;
    font-size: 14px;
    z-index: 200;

    a {
        cursor: pointer;
    }
}

.icons-container {
    margin-right: auto;
    display: flex;
    width: 130px;
    direction: ltr;
    column-gap: 31px;
    position: relative;

    img {
        cursor: pointer;
    }

    a {
        display: flex;
        align-items: center;
    }
}

.notif-box {
    position: absolute;
    left: -10px;
    top: 20px;
    width: 162px;
    max-height: 283px;
    background: #03111F;
    border-radius: 4px;
    padding: 8px;
    direction: rtl;
    display: none;
    z-index: 5;

    .box-title {
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
    }

    .notif-list-container {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }

    .reade-dote {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    }

    .notif-text {
        font-weight: bold;
        font-size: 12px;
        color: #FFFFFF;
    }

    .date {
        font-weight: normal;
        font-size: 10px;
        color: #FFFFFF;
        margin-right: 20px;
        
    }

    .text-container {
        display: flex;
        align-items: center;
        direction: rtl;
    column-gap: 10px;
    }

    .notif-link {
        color: rgba(255, 185, 4, 1);
        text-decoration: underline;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        margin-right: 30px;
    }
}

.notification:hover~.notif-box {
    display: block;
}

.notif-box:hover {
    display: block;
}

.logo-in-profile {
    font-weight: normal;
    font-size: 24px;
    display: flex;
    column-gap: 9px;
}

#menu {
    width: 35px;
    height: 30px;
    margin: 3px 0 2px 20px;
    cursor: pointer;
}

.bar {
    height: 4px;
    // width: 100%;
    background-color: #c67415;
    ;
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
}

#bar1 {
    transform: translateY(-4px);
    width: 80%;
}

#bar2 {
    width: 100%;
}

#bar3 {
    transform: translateY(4px);
    width: 80%;
}

.nav li a {
    color: black;
    text-decoration: none;
}

.nav li a:hover {
    font-weight: bold;
}

.nav li {
    list-style: none;
    padding: 16px 0;
}

.nav {
    padding: 0;
    margin: 0 20px;
    transition: 0.3s ease;
    display: none;
}

.menu-bg,
#menu-bar {
    top: 13px;
    right: 15px;
    position: absolute;
}

.menu-bg {
    z-index: 3;
    width: 0;
    height: 0;
    margin: 30px 0 20px 20px;
    background: radial-gradient(circle, #ffeab2, #F48400);
    border-radius: 50%;
    transition: 0.5s ease;
}

#menu-bar {
    z-index: 4;
}

.change-bg {
    width: 550px;
    height: 911px;
    transform: translate(60%, -30%);
}

.change .bar {
    background-color: white;
}

.change #bar1 {
    transform: translateY(4px) rotateZ(-45deg);
}

.change #bar3 {
    transform: translateY(-6px) rotate(45deg);
}

.change #bar2 {
    opacity: 0;
}

.change {
    display: block;
}

.side-navbar {
    position: fixed;
    z-index: 90;
    // display: none;
    right: 5px;
    top: 49px;
    box-shadow: 0px 5px 20px 5px rgb(128, 128, 128);
    height: 100vh;
}

// .logo-in-profile:hover + .side-navbar {
// display: inline-block;
// }
// .side-navbar:hover {
//   display: inline-block;
// }
.icons-btn {
    display: none;
    width: 142px;
}

.main-logo {
    width: 45px;
    height: 45px;
}

.profileHam {
    cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
    transition: height 1s ease-in;
    background: rgba(0, 30, 61, 0.7);
    z-index: 10;
}

.fade-enter,
.fade-leave-to {
    height: 0;
}

// .nav-homepage {
//   background: rgba(7, 29, 54, 0.64) !important;
// }
.direct_to_profile {
    border: 1px solid rgba(255, 185, 4, 1);
    color: rgba(255, 185, 4, 1);
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 6px;
    cursor: pointer;
}

@media only screen and (min-width:800px) {

    #menu-bar,
    #menu-bg {
        display: none;
    }
}

@media only screen and (max-width:800px) {

    .items-list,
    .guide,
    .icons-container {
        display: none !important;
    }

    #menu-bar,
    #menu-bg,
    .icons-btn {
        display: block;
    }
}
.profile-icons {
    fill: white;
}
</style>
