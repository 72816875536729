<template>
<div class="form">
    <form class="Auth" @submit.prevent="submit">
        <p v-if="pathMatch === 'email'">کد ۶ رقمی که به ایمیل
            {{$cookies.get('username')}} ارسال شده است را وارد نمایید.</p>
        <p v-else-if="pathMatch === 'sms'"> کد ۶ رقمی که به شماره
            {{state.userInfo.mobileNumber}} ارسال شده است را وارد
            نمایید.</p>
        <p v-else-if="pathMatch === 'google-auth'">کد ۶ رقمی سرویس
            Google Authenticator را وارد نمایید.</p>
        <CodeInput :loading="false"  @change="onChange" :fields="num" ref="code-input"/>
        <div class="footer" v-if="pathMatch !== 'google-auth'">
            <!-- <a v-if="countDown===0" @click.prevent="send" style="cursor: pointer" class="countdown">ارسال مجدد</a>
            <a v-else style="opacity:0.5;cursor:default" class="countdown">ارسال مجدد</a>&nbsp; -->
            <a style="text-decoration: none" class="countdown">{{countDown}} ثانیه </a>
        </div>
        <label for="">
            <input :type="type" placeholder="رمز عبور" v-model="otp.password" :errortext="passError">
            <inline-svg class="pass-img" :class="{'show-pass-img' : type=='text'}" :src="require('../../assets/Home/showPass.svg')" alt="" @click="showPass"></inline-svg>
            <span class="line" :class="{'line-hide' :  type=='text'}"></span>
            <p class="error-text">{{passError}}</p>
        </label>
        <!-- <ali-input @update:value="passError = ''" :value.sync="otp.password" type="password" :name="'password'"
                       :errortext="passError"
                       :faname="'رمز عبور'"/> -->
         <button v-if="countDown===0" @click.prevent="send" type="submit" class="login-btn" >
            ارسال مجدد
        </button>
        <button v-else type="submit" class="login-btn" :disabled="disable">
            ذخیره
        </button>
       
        <!-- <button class="normal-btn" @click="submit"
                    :disabled="disable">ذخیره
            </button> -->
    </form>
</div>
</template>

<script>
// import AliInput from "@/components/Tools/AliInput";
import CodeInput from "vue-verification-code-input";
import InlineSvg from 'vue-inline-svg';
import {
    // setPlaceholder,
    reset
} from "@/library/reuseableFunction";

export default {
    name: "ResetPass",
    components: {
        CodeInput,
        InlineSvg
    },
    props: ['pathMatch'],
    data() {
        return {
            type: 'password',
            passError: '',
            countDown: 120,
            num: 6,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username') || '',
                password: ''
            }
        }
    },
    computed: {
        disable() {
            return !this.otp.password || this.passError !== '' || this.otp.code.length !== 6 || (this.pathMatch !== 'google-auth' && this.countDown === 0) || this.state.loading
        }
    },
    methods: {
        showPass() {
            if (this.type === "password") {
                this.type = 'text'
            } else {
                this.type = 'password'
            }
        },
        checkPass() {
            if (!this.otp.password) {
                this.passError = 'رمز اجباری';
            } else {
                if (this.otp.password.length < 8) {
                    this.passError = 'حداقل 8 کاراکتر';
                } else if (!this.validPass(this.otp.password)) {
                    this.passError = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                } else {
                    this.passError = ''
                }
            }
            if (!this.passError.length) {
                return true;
            }
        },
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        onChange(e) {
            this.otp.code = e
        },
        validPass(password) {
            let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
            return re.test(password);
        },
        async submit() {
            if (this.checkPass(this.otp.password) && !this.disabled) {
                this.state.loading = true
                let [res, ] = await this.$http.post('/users/reset-password', this.otp)
                this.reset()
                if (res) {
                    this.$error('رمز عبور با موفقیت تغییر یافت.', '', 'success')
                    await this.$router.push('/login')
                }
            }
        },
        async send() {
            this.state.loading = true
            this.reset()
            await this.$axios.post('/users/forget-password?email=' + this.otp.email)
            this.$error('کد جدید ارسال شد.', '', 'success')
            this.$cookies.set('username', this.state.userInfo.email)

            if (this.countDown === 0) {
                if (this.state.userInfo.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR')
                    this.countDown = 120
                this.countDownTimer()
            }
        },
        reset,
        // setPlaceholder,
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        // this.setPlaceholder()
        if (!this.$cookies.isKey('username')) {
            this.$router.push('/login')
        }
        if (this.pathMatch !== 'google-auth') {
            this.countDownTimer()
        }
    }
}
</script>

<style scoped>
.login-btn {
    border: none;
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 44px;
    font-size: 14px;
    color: #FCFCFC;
    width: 100%;
}
 /* .pass-img {
      cursor: pointer;
      position: absolute;
      left: 12px;
      top: 12px;
  } */
   label {
      width: 100%;
      position: relative;
  }

  input {
      background: rgba(105, 105, 105, 0.38);
      border: 1px solid rgba(199, 199, 199, 0.22);
      box-sizing: border-box;
      border-radius: 4px;
      height: 44px;
      width: 100%;
      color: #FEFEFE;
      box-sizing: border-box;
      padding: 0 10px;
  }

  input::placeholder {
      color: #FEFEFE;
  }
  .title {

  color: #FEFEFE;
  font-size: 24px;
  margin: 15px 0;
  text-align: center;

  }
   .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      row-gap: 124px;
  }
  .error-text {
      color: red;
      font-size: 12px;
  }
  .footer {
      color: white;
  }
  form {
      display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
</style>
