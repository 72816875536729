<template>
<div class="fees">
    <p class="fee-title">
        کارمزد ها و سطوح کاربری
    </p>
    <div class="describe-container">
        <p class="describe" v-for="(describe , index) in describe" :key="index">
            {{describe.title}}
        </p>
    </div>
    <p class="describe">
        با توجه به حجم معاملات سه ماه اخیر کاربر، کارمزد معاملات طبق جدول زیر به صورت پلکانی محاسبه می گردد:
    </p>
    <div class="table-container">
        <table class="levels">
            <tr class="tr-heade">
                <td v-for="(heade , index) in heade" :key="index">
                    {{heade.title}}
                </td>
            </tr>

            <tr class="tr-body" v-for="(level,index) in $accountLevel.filter(e => e.userAccountLevel !== 'SUPERVISOR')" :key="index">
                <td>
                    <img class="img-levels" :src='levels[level.userAccountLevel].img' alt="">
                    <!-- <img v-if="index === 0" class="img-levels" src='../../assets/Home/bee1.png' alt=""> -->
                    <span>{{levels[level.userAccountLevel].name}}</span>
                    <!-- <span v-if="index === 0">{{body.title}}</span> -->
                </td>
                <td class="number-font" v-if="index === 0">
                    کمتر از {{tradeAmount($accountLevel[index].tradeAmount)}} میلیون
                </td>
                <td class="number-font" v-else-if="index === ($accountLevel.length-2)">بیشتر از
                    {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                    میلیون
                </td>
                <td v-else>
                    از
                    {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                    تا
                    {{tradeAmount($accountLevel[index].tradeAmount)}}
                    میلیون
                </td>
                <td class="number-font">
                    % {{level.wagePercent}}
                </td>
                <td class="number-font">
                    % {{level.wagePercentTether}}
                </td>
            </tr>
        </table>
    </div>
    <p class="title">
        کارمزد برداشت ارزها
    </p>
    <p class="describe">
        کارمزد برداشت دارایی‌های دیجیتال به نوع ارز بستگی دارد. این هزینه در واقع به دلیل کارمزدهای تراکنش شبکه کسر می‌شود و تیرکس در آن نقشی ندارد. در حال حاضر این هزینه‌ها به شرح زیر است:
    </p>
    <div class="table-container">
        <table class="fee-table">
            <tr class="tr-heade">
                <td v-for="(feeheade , index) in feeheade" :key="index">
                    {{feeheade.title}}
                </td>
            </tr>
            <tr class="tr-body" v-for="(coin , index) in coins" :key="index">
                <td>
                    {{$coins[coin.coin].persianName}}
                </td>
                <td  class="number-font ltr">
                    <span class="number-font"> {{coin.tokenTypeDetails[0].minWithdraw}}  </span>&nbsp;
                    <span> {{$coins[coin.coin].code}}</span>

                </td>
                <td v-if="coin.coin=='TETHER'">
                      <span>{{coin.tokenTypeDetails[0].withdrawalFee}}&nbsp; ({{coin.tokenTypeDetails[0].tokenType}}) </span>/
                      <span>{{coin.tokenTypeDetails[1].withdrawalFee}}&nbsp; ({{coin.tokenTypeDetails[1].tokenType}})</span>
                </td>
                <td  v-else  class="number-font ltr">
                    {{coin.tokenTypeDetails[0].withdrawalFee}} 
                </td>
            </tr>
        </table>
    </div>
    <p class="title">
        کارمزد برداشت ریالی
    </p>
    <p class="describe">
        برداشت‌های ریالی برای تمامی حساب‌ها، در قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روزهای غیر تعطیل می‌تواند حداکثر تا ۲۴ ساعت به طول انجامد.
    </p>
</div>
</template>

<script>
export default {
    name: 'Fees',
    components: {

    },
    data() {
        return {
            describe: [{
                    title: "کارمزد معاملات تیرکس به صورت درصد از مبلغ کل معامله است و محاسبه آن بر اساس ملاحظات زیر صورت می گیرد:"
                },
                {
                    title: "کارمزد خریدار ارزدیجیتال به صورت ارزدیجیتال کسر و کارمزد فروشنده ارزدیجیتال از مبلغ دریافتی به تومان کسر می شود. "
                },
                {
                    title: "کارمزد از هر دو سمت معامله کسر می شود."
                },
                {
                    title: "در هنگام ثبت معاملات ، کارمزد به شما نمایش داده می شود."
                },
                {
                    title: "لازم به ذکر است کارمزد معاملات جفت ارز )به طور مثال بیت کوین - تتر( به صورت ثابت ۰.۲% در نظر گرفته می شود."
                }
            ],
            heade: [{
                    title: "سطح کاربری"
                },
                {
                    title: "میزان معامله در ۳ ماه اخیر"
                },
                {
                    title: "کارمزد تومانی"
                },
                {
                    title: " کارمزد تتری"
                }
            ],
            body: [{
                    img: require('../../assets/Home/bee1.png'),
                    title: "زنبور عسل",
                    amount: "کمتر از ۲۰ میلیون تومان ",
                    fee: 0.4,
                    class: "row1"
                },
                {
                    img: require('../../assets/Home/bee2.png'),
                    title: "زنبور عسل",
                    amount: "کمتر از ۲۰ میلیون تومان ",
                    fee: 0.4,
                    class: "row2"
                },
                {
                    img: require('../../assets/Home/bee3.png'),
                    title: "زنبور عسل",
                    amount: "کمتر از ۲۰ میلیون تومان ",
                    fee: 0.4,
                    class: "row3"
                },
                {
                    img: require('../../assets/Home/bee4.png'),
                    title: "زنبور عسل",
                    amount: "کمتر از ۲۰ میلیون تومان ",
                    fee: 0.4,
                    class: "row4"
                }
            ],
            feeheade: [{
                    title: "نوع ارز"
                },
                {
                    title: "حداقل میزان قابل برداشت"
                },
                {
                    title: " کارمزد "
                },

            ],
            levels: {
                BRONZE: {
                    name: 'زنبور عسل',
                    img: require('../../assets/Home/bee1.png')
                },
                SILVER: {
                    name: 'زنبور نگهبان',
                    img: require('../../assets/Home/bee2.png')
                },
                GOLD: {
                    name: 'زنبور کاوشگر',
                    img: require('../../assets/Home/bee3.png')
                },
                PLATINUM: {
                    name: 'زنبور ملکه',
                    img: require('../../assets/Home/bee4.png')
                }
            },
            coins: []
        }
    },
    computed: {
        tradeAmount() {
            return e => e ? e.toString().slice(0, -6) : 0
        }
    },
    methods: {
        async getData() {
            const res = await this.$axios.get('/coins/details?p2pSupported=true')
            this.coins = res.content
        }
    },
    mounted() {
        this.$getFees()
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.fees {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 10px;
}

.fee-title {
    font-weight: bold;
    font-size: 24px;
    color: #F2F2F2;
}

.describe {
    color: #F2F2F2;
    font-size: 18px;
}

.table-container {
    margin: 30px 0 30px 0;
    background: #4F4F4F;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 2px;

}

.describe-container {
    margin-bottom: 48px;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    table-layout: fixed;

    tr td:first-of-type {
        text-align: right;
        padding-right: 17px;
    }

    tr td:last-of-type {
        text-align: left;
        padding-left: 17px;
    }

    .tr-body:nth-child(1n+0) {
        background: var(--row-1);
    }

    .tr-body:nth-child(2n+0) {
        background: var(--row-2);
    }

    .tr-body:nth-child(3n+0) {
        background: var(--row-3);
    }
}

table.levels {

    tr {
        height: 64px;
    }

    tr td:first-of-type {
        text-align: right;
        padding-right: 17px;
        // display: flex;
        height: 64px;
        align-items: center;
        column-gap: 30px;

        img,
        span {
            vertical-align: middle;
        }

        img {
            margin-left: 30px;
        }
    }

    .tr-heade {
        background-color: #0A293D;
        color: #E0E0E0;

        td:first-of-type {
            padding-right: 90px;
            border-top-right-radius: 6px;
        }

        td:last-of-type {
            border-top-left-radius: 6px;
        }
    }

    .tr-body {
        font-weight: bold;
        font-size: 16px;
        color: #F2F2F2;
    }
}

.fee-table {
    tr {
        height: 40px;
    }

    .tr-heade {
        font-size: 14px;
        color: #999999;
        background-color: #072534;

        td:first-of-type {
            border-top-right-radius: 6px;
        }

        td:last-of-type {
            border-top-left-radius: 6px;
        }
    }

    .tr-body {
        font-size: 14px;
        color: #F2F2F2;

        tr:last-of-type td:first-of-child {
            border-bottom-right-radius: 6px;
        }
    }
}

table tr:last-child td:last-child {
    border-bottom-left-radius: 6px;
}

table tr:last-child td:first-child {
    border-bottom-right-radius: 6px;
}

@media only screen and (max-width:800px) {
    .img-levels {
        display: none;
    }

    .tr-heade {
        td:first-of-type {
            padding-right: 0 !important;
        }
    }
}
</style>
