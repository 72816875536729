<template>
    <div class="markets">
      <p class="title">
        بازار ها
      </p>
        <CurrencyTable/>
    </div>
</template>

<script>
import CurrencyTable from '../HomePage/CurrencyTable.vue'
export default {
  name: 'Markets',
  components :{
    CurrencyTable
  },
  data() {
      return {
         
      }
  },
  methods : {
      
  }
  }
</script>

<style lang="scss" scoped>
    .markets {
      box-sizing: border-box;
      padding: 0 10px;
    }
    .title {
      margin: 36px 0;
    }
</style>
