<template>
<div class="deal">
    <div class="deal-container">
        <!-- <perfect-scrollbar> -->
        <span class="deal-title">
            آخرین معاملات بازار
        </span>
        <div class="table-container">
            <table>
                <tr class="tr-head">
                    <td v-for="(head , index) in head" :key="index">
                        {{head.title}}
                    </td>
                </tr>
                <tr v-if="!tableContents.length">
                        <td colspan="4">
                            <p style="width:100%;text-align:center;color:red">در حال حاضر اطلاعاتی وجود ندارد</p>
                        </td>
                    </tr>
                <tr v-else class="tr-body" v-for="(tableContent , index) in tableContents" :key="index">
                    <td :class="{'sell' : tableContent.orderType.includes('BUY') , 'buy' : tableContent.orderType.includes('SELL')}" class="number-font">
                        {{$toLocal(tableContent.unitPrice)}} 
                    </td>
                    <td class="number-font">
                        {{$toLocal(tableContent.amount)}}
                    </td>
                    <td class="number-font">
                        {{$G2J(tableContent.createdAtDateTime,'time')}}
                    </td>
                </tr>
            </table>
        </div>
        <!-- </perfect-scrollbar> -->
    </div>
</div>
</template>

<script>
export default {
    name: 'TheLastDeal',
    components: {

    },
    data() {
        return {
            head: [{
                    title: "قیمت"
                },
                {
                    title: "مقدار"
                },
                {
                    title: "زمان"
                }
            ],
            tableContents: [
                /*{
                    amount: 0,
                    calculatedPriceInTomanMarket: 0,
                    createdAtDateTime: '',
                    destinationCoin: "BINANCE_COIN",
                    executedPercent: 0,
                    id: 0,
                    marketType: "BINANCE_COIN_TETHER",
                    orderType: '',
                    receivedAmount: 0,
                    sourceCoin: "BINANCE_COIN",
                    unitPrice: 0,
                    wagePercent: 0,
                    wholePrice: 0
                }*/
            ],
            loading: false,
            alive: true
        }
    },
    methods: {
        async getLastTrades() {
            const res = await this.$axios.get('/trades/last-trades?type=P2P', {
                params: {
                    marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                    size: 20
                }
            })
            this.tableContents = res.content
            this.loading = false
        },
        async loop() {
            // this.state.loading = true
            this.loading = true

            while (this.alive) {
                await this.getLastTrades()
                this.loading = false
                await this.$sleep(5000)
            }
        }
    },
    mounted() {
        this.alive = true
        if (this.state.loop) {
            this.loop()
        } else {
            this.getLastTrades()
        }
    },
    beforeDestroy() {
        this.alive = false
    }
}
</script>

<style lang="scss" scoped>
.deal {
    // max-width: 234px;
    // flex-grow: 1;
    // width: 260px;
    // max-height: 311px;
    background: var(--background-child);
    border: 2px solid var(--border-color);
    border-radius: 8px;
        height: 530px;
        max-width: 270px;
    flex-grow: 1;
}

.deal-container {
    display: flex;
    flex-direction: column;
    padding: 12px 8px;
    align-items: center;
    row-gap: 12px;
}

.deal-title {
    font-weight: bold;
    font-size: 14px;
    color: var(--font-strong);
}

.table-container {
    width: 100%;
    overflow: scroll;
    height: 470px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.table-container::-webkit-scrollbar {
    display: none;
}

table {
    text-align: center;
    width: 100%;

    tr td:first-child {
        text-align: right;
    }

    tr td:last-child {
        text-align: left;
    }
}

.tr-head {
    font-weight: normal;
    font-size: 12px;
    color: var(--font-strong);
    height: 27px;
    background: var(--background-child);
    position: sticky;
    top: 0;
}

.tr-body {
    font-weight: normal;
    font-size: 12px;
    color: var(--font-strong);
}

.sell {
    color: #E43700;
}

// .buy {
//   color: #B9FFA0;
// }
@media only screen and (max-width: 600px) {
   .deal  {
        width: 100%;
    }
}
</style>
