<template>
<div class="table-container">
    <table class="multi_color">
        <tr class="tr-heade">
            <td v-for="(heade , index) in heade" :key="index" :class=heade.class>
                {{heade.title}}
            </td>
        </tr>
        <tr class="tr-body" v-for="(tableContent , index) in tableContents" :key="index">
            <td class="number-font date">
                {{$G2J(tableContent.createdAtDateTime) }}
            </td>
            <td :class="{'sell' : tableContent.orderType==('EXCHANGE_SELL' || 'EXCHANGE_SELL'), 'buy' : tableContent.orderType==('EXCHANGE_BUY' || 'EXCHANGE_BUY') }">
                <span v-if="tableContent.orderType==('EXCHANGE_SELL' || 'EXCHANGE_SELL')">
                    فروش
                </span>
                <span v-if="tableContent.orderType==('EXCHANGE_BUY' || 'EXCHANGE_BUY')">
                    خرید
                </span>
            </td>
            <td class="amount-td number-font">
                {{$toLocal(tableContent.amount)}}
            </td>
            <td class="price-td number-font">
                {{$toLocal(tableContent.unitPrice,precision)}}
            </td>
            <td class="total-td number-font amount">
                {{$toLocal(tableContent.overallValue,precision)}}
            </td>
            <!-- <td class="number-font done">
                {{tableContent.executedPercent.toFixed(1)}} %
            </td> -->
            <td>
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import {
    precision,
} from "@/library/reuseableFunction";
export default {
    name: 'LastOrders',
    data() {
        return {
            heade: [{
                    title: "زمان",
                    class: "date"
                },
                {
                    title: "نوع سفارش"
                },
                {
                    title: "مقدار"
                },
                {
                    title: "قیمت"
                },
                {
                    title: "مجموع",
                    class: "total"
                },
                // {
                //     title: "کارمزد",
                //     class: "fee"
                // },
            ],
            tableContents: []
        }
    },
    computed: {
        precision,

    },
    methods: {
        async getActiveOrders() {
            const res = await this.$axios.get('/orders?orderType=EXCHANGE_BUY,EXCHANGE_SELL', {
                params: {
                    //   orderStatus: 'IS_OPEN',
                }
            })
            this.tableContents = res.content;
            this.$emit('update:activeLength', this.tableContents.length)
        },
    },
    mounted() {
        this.getActiveOrders()
    }
}
</script>

<style lang="scss" scoped>
table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 2px solid #4F4F4F;

    .tr-heade {
        height: 43px;
        background: #001E3D;
        color: #FEFEFE;
        font-weight: normal;
        font-size: 14px;
    }

    .tr-body {
        height: 36px;
        color: #FEFEFE;
        font-weight: normal;
        font-size: 12px;

        .sell {
            color: #E43700;
        }

        .buy {
            color: #B9FFA0;
        }
    }
}

.table-container {
    //   padding: 0 2px 2px 2px;
    //  background: #4F4F4F;
}

@media only screen and (max-width:700px) {

    .date,
    .total,
    .fee,
    .done,
    .total-td,
    .fee-td {
        display: none;
    }
}
</style>
