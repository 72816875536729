<template>
<transition>
    <div class="modal-backdrop" @click.self="$emit('close')">
        <div class="modal">
            <img @click="$emit('close')" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt="">
            <img src='../../../assets/Profile/successModal.svg' />
            <div class="modal-title">
                <span class="sell" v-if="data.orderType=='EXCHANGE_SELL'">فروش</span>
                <span class="buy" v-if="data.orderType=='EXCHANGE_BUY'">خرید</span>
                شما با موفقیت انجام شد
            </div>
            <div class="modal-body">
                <div>
                    <span class="item">
                        مقدار رمزارز
                    </span>
                    <span>
                        <span class="unit">{{$coins[data.destinationCoin].code}}</span>&nbsp;
                        <span class="number-font">{{$toLocal(data.amount)}}</span>

                    </span>
                </div>
                <div>
                    <span class="item">
                        قیمت واحد
                    </span>
                    <span>
                        <span class="number-font">{{$toLocal(data.unitPrice,$decimal[toman ? 'TOMAN' : 'TETHER'])}} </span>&nbsp;
                        <span class="unit">{{$coins[toman ? 'TOMAN' : 'TETHER'].persianName}}</span>
                    </span>
                </div>
                <div>
                    <span v-if="data.orderType=='EXCHANGE_SELL'" class="wallet-item">
                        مبلغ دریافت شما
                    </span>
                    <span v-if="data.orderType=='EXCHANGE_BUY'" class="wallet-item">
                        مبلغ پرداختی شما
                    </span>
                    <span>
                        <span :class="{'sell' : data.orderType=='EXCHANGE_SELL' , 'buy' : data.orderType=='EXCHANGE_BUY'}" class="number-font">{{data.totalPrice}}</span> &nbsp;
                        <span class="unit">{{$coins[toman ? 'TOMAN' : 'TETHER'].persianName}}</span>
                    </span>
                </div>
            </div>
            <div class="wallet-container">
                <div class="squer"></div>
                <div class="wallet-statistics">
                    <div class="wallet-title">
                        موجودی جدید کیف پول
                    </div>
                    <div class="number-font" v-if="buy">
                        {{$toLocal(wallet.credit,$decimal[data.destinationCoin])}}&nbsp;
                        <span class="unit">
                            {{$coins[data.destinationCoin].persianName}}
                        </span>
                    </div>
                    <div class="number-font" v-if="!buy">
                        {{$toLocal(wallet.credit,$decimal[toman ? 'TOMAN' : 'TETHER'])}}&nbsp;
                        <span class="unit">
                            {{$coins[toman ? 'TOMAN' : 'TETHER'].persianName}}
                        </span>
                    </div>
                </div>
            </div>
            <button class="confirm" @click="$emit('close')">
                بستن
            </button>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'SuccessExchange',
    components: {},
    props: ['data', 'beebitexWallet', 'toman' , 'buy'],
    data() {
        return {
            wallet: {
                credit: 0
            }
        }
    },
    methods: {
        async getWallets() {
            this.state.loading = true
            if(this.buy) {
                 const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + this.data.destinationCoin)
            if (res) {
                this.wallet = res[this.data.destinationCoin]
            }
            }
            else {
                 const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE&coins=' + (this.toman ? 'TOMAN' : 'TETHER'))
            if (res) {
                this.wallet = res[this.toman ? 'TOMAN' : 'TETHER']
            }
            }
           
            this.loading = false
        },
    },
    mounted() {
        this.getWallets()
    }
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    max-height: 600px;
    background: var(--background-child);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 30px;
}

.modal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.close-icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
}

.modal-title {
    font-weight: bold;
    font-size: 16px;
    color: #F2F2F2;
    text-align: center;
    width: 100%;
}

.modal-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.describe {
    font-weight: normal;
    font-size: 14px;
    color: var(--font-weake);
}

.confirm {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 40px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.wallet-container {
    border: 4px solid #BDBDBD;
    border-radius: 8px;
    width: 100%;
    height: 116px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    font-size: 16px;

    .squer {
        border: 5px solid #828282;
        border-radius: 6px 0px 0px 6px;
        width: 72px;
        height: 50px;
    }
}

.sell {
    color: #E43700;
}

.item,
.unit {
    color: #BDBDBD;
}

.wallet-item {
    color: #E0E0E0;
}

.wallet-statistics {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.number-font {
    color: #F2F2F2;
}

.wallet-title {
    font-size: 16px;
    color: #F2F2F2;
}
</style>
