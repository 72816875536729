<template>
<div class="invite">
    <span class="invite-title">
        دعوت از دوستان
    </span>
    <p class="describe">
        شما می‌توانید دوستان خود را با لینک اختصاصی خود به تیرکس دعوت کنید. درحال حاضر براساس این طرح، 30% از کارمزد تمامی معاملات کسانی که با لینک شما ثبت نام کرده‌اند به شما پرداخت خواهد شد. همچنین می‌توانید بخشی از این سود را در هنگام ایجاد لینک به فرد دعوت شده اختصاص دهید.
    </p>
    <div class="code-statistics">
        <div class="code-container">
            <span class="sub-title">
                ساخت کد دعوت
            </span>
            <p class="code-container-text">
                نرخ کارمزد اهدایی به خود و دوستانتان را تنظیم کنید:
            </p>
            <div class="create-container">
                <div class="create-ttile">
                    <span>
                        سهم شما
                    </span>
                    <span>
                        سهم دوستان
                    </span>
                </div>
                <div class="range-box">
                    <div class="range-self" :class="{'max-percent' : rangeSelf==30}"  @click="selfFun">
                        <inline-svg :src="require('../../../assets/Home/Vector.svg')" class="self-change"></inline-svg>
                        <span class="number-font">{{rangeSelf}} %</span>
                    </div>
                    <div class="range-friend" :class="{'max-percent' : rangeFriend==30}" @click="friendFun">
                        <span class="number-font">{{rangeFriend}} %</span>
                        <inline-svg :src="require('../../../assets/Home/Vector.svg')" class="friend-change"></inline-svg>
                    </div>
                </div>
            </div>
            <button class="productLink" @click="makeCode">
                ساخت کد دعوت
            </button>
        </div>
        <div class="statistics-container">
            <span class="sub-title">
                آمار دعوت های من:
            </span>
            <div class="friends-income">
                <div>
                    <span class="statics-sub-title">
                        تعداد دوستان من
                    </span>
                    <span>
                        <span class="number-font statistics-number"> {{$toLocal(statistics.countInvitees) || 0}} </span>&nbsp;
                        <span class="unit">نفر</span>
                    </span>
                </div>
                <div>
                    <span class="statics-sub-title">
                        مجموع سود من
                    </span>
                    <span>
                        <span class="number-font statistics-number">  {{$toLocal(statistics.profit) || 0}}</span>&nbsp;
                        <span class="unit">تومان</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <span class="code-list">
        کد دعوت های من
    </span>
    <div class="table-container">
        <table>
            <tr class="tr-heade">
                <td v-for="(heade , index) in heade" :key="index" :class=heade.class>
                    {{heade.title}}
                </td>
            </tr>
            <tr class="tr-body" v-for="(code , index) in codes" :key="index">
                <td class="number-font">
                    {{code.referralCode}}
                </td>
                <td>
                    https://www.beebitex.com/{{code.referralCode}}
                </td>
                <td class="number-font your-td">
                    {{code.inviterProfitPercent}}
                </td>
                <td class="number-font friend-td">
                    {{code.inviteeProfitPercent}}
                </td>
                <td class="number-font friend-number">
                    {{code.countInvitees}}
                </td>
                <td class="number-font income">
                   {{code.profit}}
                </td>
            </tr>
        </table>
    </div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'InviteFriends',
    components: {
        InlineSvg
    },

    data() {
        return {
            income: 0,
            friend: 0,
            rangeSelf: 30,
            rangeFriend: 0,
            statistics:"",
            data: {
                inviterProfitPercent: 30
            },
            heade: [{
                    title: "کد دعوت",
                    class: "code-td"
                },
                {
                    title: "لینک دعوت",
                    class: "link-td"
                },
                {
                    title: "سهم شما",
                    class: "your-td"
                },
                {
                    title: "سهم دوستان",
                    class: "friend-td"
                },
                {
                    title: "تعداد دوستان",
                    class: "friend-number"
                },
                {
                    title: "سود شما",
                    class: "income"
                }
            ],
            codes:[],
        }
    },
    methods: {
        selfFun() {
            if (this.rangeSelf < 30 && this.rangeFriend >= 5) {
                this.rangeSelf = this.rangeSelf + 5
                this.data.inviterProfitPercent = this.rangeSelf 
                this.rangeFriend = this.rangeFriend - 5
            }
        },
        friendFun() {
            if (this.rangeFriend < 30 && this.rangeSelf >= 5) {
               this.rangeSelf = this.rangeSelf - 5
                this.data.inviterProfitPercent = this.rangeSelf 
                this.rangeFriend = this.rangeFriend + 5
            }
        },
        async makeCode() {
            this.state.loading = true
            const [res, ] = await this.$http.post(`/users/make-referral-code`, this.data)
            if (res) {
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
            }
            this.getData()
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/referral-codes')
            this.codes = res.content
        },
    },
    async mounted() {
        this.getData()
        const res = await this.$axios.get('/users/invites')
            this.statistics = res.baseDTO
    }
}
</script>

<style lang="scss" scoped>
.invite {
    background: #0A293D;
    border-radius: 8px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    padding: 24px;
}

.table-container {
    background: rgba(10, 41, 61, 0.5);
    border: 2px solid #4F4F4F;
    border-radius: 8px;
    height: 225px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 16px 0;
}

.productLink {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 32px;
    max-width: 176px;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.table-container::-webkit-scrollbar {
    display: none;
}

table {
    width: 100%;
    text-align: center;

    .tr-heade {
        font-weight: normal;
        font-size: 12px;
        color: #E0E0E0;
        height: 32px;
        table-layout: fixed;
    }

    .tr-body {
        font-weight: normal;
        font-size: 16px;
        color: #FEFEFE;
        height: 32px;
    }
}

.code-list {
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}

.unit {
    font-weight: normal;
    font-size: 16px;
    color: #BDBDBD;
}

.code-statistics {
    display: flex;
    flex-wrap: wrap;
    margin: 35px 0;
    row-gap: 30px;
}

.statistics-container,
.code-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 230px;
    justify-content: space-between;
}

.friends-income {
    display: flex;
    flex-grow: 1;
    margin-top: 70px;

    div {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        flex-grow: 1;
    }
}

.invite-title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
}

.describe {
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.sub-title {
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}

.statics-sub-title {
    font-weight: bold;
    font-size: 16px;
    color: #E0E0E0;
}

.statistics-number {
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
}

.create-container {
    width: 176px;
    display: flex;
    flex-direction: column;
    row-gap: 7px;

    .create-ttile {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-weight: normal;
            font-size: 12px;
            color: #FFFFFF;
        }
    }

    .code-container-text {
        font-weight: normal;
        font-size: 14px;
        color: #FFFFFF;
    }

    .range-box {
        width: 100%;
        height: 44px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        display: flex;

        .range-self {
            background: #031B27;
            border-radius: 7px;
            width: 50%;
            align-items: center;
            justify-content: space-around;
            display: flex;
            cursor: pointer;

            span {
                font-weight: bold;
                font-size: 18px;
                color: #FFFFFF;
            }
        }

        .range-friend {
            border-radius: 7px;
            width: 50%;
            align-items: center;
            justify-content: space-around;
            display: flex;
            cursor: pointer;
            span {
                font-weight: bold;
                font-size: 18px;
                color: #FFFFFF;
            }
        }

    }
}
.max-percent {
    opacity: 0.5;
}
.self-change {
    transform: rotate(265deg);
}

.friend-change {
    transform: rotate(90deg);
}

.code-container-text {
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

@media only screen and (max-width:600px) {

    .your-td,
    .friend-td,
    .friend-number,
    .income {
        display: none;
    }
}
</style>
