<template>
<div>
    <div class="input-container">
        <span class="describe" v-if="state.towStepAuthenticationType=='EMAIL'">
            کد 6 رقمی ارسال شده به آدرس ایمیل
            {{otp.email}} را وارد نمایید
        </span>
        <span class="describe" v-if="state.towStepAuthenticationType=='SMS'">
            کد شش رقمی ارسال شده به <span dir="ltr">{{otp.mobile}}</span>
        </span>
        <span class="describe" v-if="state.towStepAuthenticationType=='GOOGLE_AUTHENTICATOR'">
            Google Authenticator
        </span>
        <CodeInput :loading="false" @change="onChange" @complete="submit" :fields="num" />

    </div>
    <span v-if="state.towStepAuthenticationType!=='GOOGLE_AUTHENTICATOR'" class="count-p">
        {{countDown}}&nbsp; ثانیه 
    </span>
    <button v-if="countDown===0" @click.prevent="send" class="active-btn">
        ارسال مجدد 
    </button>
    <button v-else :disabled=disablebtn @click.prevent="submit" :class="{'deActive-btn' :this.disablebtn , 'active-btn' : !this.disablebtn}">
        ثبت 
    </button>
    
</div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
    name: 'TwoFAEmail',
    components: {
        CodeInput
    },
    data() {
        return {
            countDown: 120,
            disablebtn: true,
            error: '',
            code: '',
            num: 6,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username'),
                mobile: this.state.userInfo.mobile
            }
        }
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async send() {
            this.state.loading = true
            this.reset()
            await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
            // const text = {
            //     email: 'ایمیل',
            //     sms: 'شماره'
            // }
            this.$error('کد جدید  ' +'  ارسال شد.', '', 'success')
            this.$cookies.set('username', this.state.userInfo.email)
            if (this.pathMatch !== 'google-auth') {
                if (this.countDown === 0) {
                    this.countDown = 120
                    this.countDownTimer()
                }
            }

        },
        // setPlaceholder() {
        //     for (let i = 0; i < this.num; i++) {
        //         this.$refs['code-input'].$refs['input_' + i][0].placeholder = '-------'
        //     }
        // },
        onChange(e) {
            this.otp.code = e
        },
        async submit() {
            this.disablebtn = false
            if (this.countDown !== 0) {
                this.state.loading = true
                const res = await this.$axios.post('/users/check-otp-code', this.otp)
                if (res.token) {
                    await this.$setToken(res.token)
                    await this.$router.push('/profile/account')
                }
            }
        },
        reset() {
            this.otp.code = ''
            // for (let i = 0; i < this.num; i++) {
            //     this.$refs['code-input'].values[i] = ''
            //     this.$refs['code-input'].$refs['input_' + i][0].value = ''
            // }
            // this.$refs['code-input'].$refs['input_0'][0].focus()
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        // this.setPlaceholder()
        if (!this.otp.email) {
            this.$router.push('/login')
        }
        if (this.state.towStepAuthenticationType!=='GOOGLE_AUTHENTICATOR') {
            this.countDownTimer()
        }
    }
}
</script>

<style lang="scss" scoped>
.input-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 40px;
}

.describe {
    font-size: 12px;
    color: #FEFEFE;
}

.deActive-btn,
.active-btn {
    border-radius: 6px;
    border: none;
    color: #FCFCFC;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    height: 44px;
}

.active-btn {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
}

.deActive-btn {
    background: linear-gradient(94.21deg, rgba(255, 245, 4, 0.5) 0%, rgba(255, 199, 0, 0.5) 47.18%, rgba(244, 132, 0, 0.5) 99.06%);
}
.count-p {
    color: white;
}
</style>
