<template>
<form @input="calculate" class="form">
    <SellModal v-if="show" @close="show = false" :unitPrice="unitPrice" :totalPrice="totalPrice" :stopPrice="stopPrice" :amount="amount" type="SELL" :date="eDate" :limit="limit" />
    <SuccessSell v-if="SuccessSell" @closeSuccess="SuccessSell=false" />

    <div>
        <div class="describe-input" >
            <span>
                بالاترین پیشنهاد خرید
            </span>
            <span class="number-font">
                {{$toLocal(state.shopInfo.maxBuy,tradeTo === 'TOMAN' ? 0 : $prcesion[tradeFrom])}} {{$coins[tradeTo].persianName}}
            </span>
        </div>
        <label for="" v-if="limit !== 'stopMarket'">
            <input class="number-font" type="text" :placeholder="'قیمت'+'('+$coinLabel[tradeTo]+')'" @input="unitPrice = $toLocal(unitPrice,$decimal[tradeTo])" :disabled="limit =='market'" v-model="unitPrice">
        </label>
        
    </div>
    <label for="" v-if="limit == 'stopLimit' || limit == 'OCO' || limit == 'stopMarket'">
            <input type="text" class="sellPrice" :placeholder="'حد توقف'+'('+$coins[tradeTo].persianName+')'" @input="stopPrice = $toLocal(stopPrice,tradeTo=='TOMAN' ? 0 : 6);calculate()" v-model="stopPrice" :id="'stopprice'+id" >
        </label>
    <div>
        <div class="describe-input">
            <span>
                <img src="@/assets/Profile/walletForm.svg" alt="">&nbsp;
                <span>موجودی</span>
            </span>
            <span class="number-font">
                {{$toLocal(cash,$decimal[tradeFrom])}}&nbsp;
                <span class="unit">{{$coins[tradeFrom].persianName}}</span>
            </span>

        </div>
        <label for="">
            <input class="number-font" type="text" :placeholder="'مقدار'+'('+$coins[tradeFrom].persianName+')'" @input="amount = $toLocal(amount,$decimal[tradeFrom]);calculate()" v-model="amount">
            <p v-if="readonly" class="error-p">
                {{readonly}}
            </p>
        </label>
        <div class="range-container">
            <InputRange @change="setAmount" />
        </div>
    </div>
    <div>
        <div class="ex-date">
            <input type="checkbox" @change="date_disable = !date_disable">
            <span>تاریخ انقضا</span>
        </div>
        <label for="" v-if="limit=='limit'">
            <date-picker id="exp" type="datetime" v-model="eDate" color="#001E3D" :format="$dateFormat['en']" :display-format="$dateFormat['faDateTime']" :min="now" clearable class="col-7 col-sm-12 col-md-7 m-0" placeholder="تاریخ انقضا (اختیاری)" :disabled="date_disable" />
            <!-- <img class="calender" src="@/assets/Profile/calender.svg" alt=""> -->
        </label>
    </div>
    <div>
        <div class="describe-input">
            <span>
                کارمزد
            </span>
            <span class="number-font">
                ٪ {{wage}}
            </span>
        </div>
        <label for="">
            <input class="number-font" type="text" placeholder="مجموع" v-model="totalPrice" @input="totalPrice = $toLocal(totalPrice,$decimal[tradeFrom])" disabled>
        
        </label>
    </div>
    <button class="sell" v-if="state.userInfo.authorized || state.userInfo.supervisor" :disabled="!!readonly" @click.prevent="showmodal">
        فروش {{$coinLabel[tradeFrom]}}
    </button>
</form>
</template>

<script>
import InputRange from '../../Public/InputRange.vue'
import SellModal from './SellModal.vue'
import SuccessSell from './SuccessSell.vue'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import {
    tradeTo,
    tradeFrom,
    precision,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'SellLimit',
    components: {
        SellModal,
        InputRange,
        SuccessSell,
        datePicker: VuePersianDatetimePicker,
    },
    props: ['cash', 'wage', 'decimal', 'limit', 'id', 'role', 'SellModal'],
    data() {
        return {
            SuccessSell: false,
            thisModal: true,
            //  min:54654654654,
            //  fee:0.4,
            date_disable: true,
            eDate: '',
            percent: 0,
            unitPrice: '',
            stopPrice:'',
            amount: '',
            totalPrice: '',
            danger: false,
            danger1: false,
            danger2: false,
            show: false,
            value: '',
            checked: '',
            calculating: false,
            getAmount: false,
            amountTemp: '',
            count: 0,
            alive: true,
            loop: undefined,
            coins: '',
            priceError: "",
            amountError: "",
            minAmountOrder: null,
            maxAmountOrder: null,
            disableBuyBtn: true
        }
    },
    watch: {
        'state.sellAmount'() {
            this.amount = this.$toLocal(this.state.sellAmount, this.decimal)
            // this.unitPrice = this.$toLocal(this.state.sellPrice, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
            this.calculate()
        },
        // 'state.sellPrice'() {
        //     this.amount = this.$toLocal(this.state.sellAmount, this.decimal)
        //     this.unitPrice = this.$toLocal(this.state.sellPrice, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
        //     this.calculate()
        // }
    },
    computed: {
        now() {
            return this.$jmoment().add(5, 'minute').format(this.$dateFormat['en'])
        },
        readonly() {
            let priceError = ""
            if (this.limit.includes('limit') && !this.$S2N(this.unitPrice)) {
                priceError = 'قیمت واحد اجباریست'
            } else if(this.limit.includes('stop') && !this.stopPrice) {
                priceError = 'حد توقف اجباریست'
            }
            else if (!this.$S2N(this.amount)) {
                priceError = 'مقدار ارز اجباریست'
            } else if (this.$S2N(this.amount) < this.min) {
                priceError = 'مقدار ارز کمتر از حد مجاز است'
            } else if (this.$S2N(this.amount) > this.max) {
                priceError = 'مقدار ارز بیشتر از حد مجاز است'
            } else if (this.$S2N(this.amount) > this.$S2N(this.cash)) {
                priceError = 'موجودی کافی نیست'
            } 
            else if ((this.percent > 1 || this.percent < 0) && !this.state.userInfo.supervisor) {
                priceError = 'موجودی کافی نیست'
            }
            //  else if (this.load) {
            //     priceError = 'لطفا صبر کنید...'
            // } 
            else if (!this.limit && this.state.userInfo.supervisor) {
                priceError = 'بازارگردان قادر به انجام سفارش بازار نیست'
            }
            return priceError
        },
        load() {
            return this.calculating || this.getAmount
        },
        min() {
            return this.role.minAmount
        },
        max() {
            return this.role.maxAmount
        },
        range() {
            return 'حداقل ' + this.$toLocal(this.min) + ' ' + this.$coinLabel[this.tradeFrom] +
                '\n' + 'حداکثر ' + this.$toLocal(this.max) + ' ' + this.$coinLabel[this.tradeFrom]
        },
        tradeTo,
        tradeFrom,
        precision
    },
    methods: {
        disableBtn() {
            if (this.$S2N(this.amount) < this.minAmountOrder) {
                this.amountError = 'حداقل میزان معامله رعایت نشده'
                this.disableBuyBtn = true
            } else if (this.$S2N(this.amount) > this.maxAmountOrder) {
                this.amountError = 'حداکثر میزان معامله رعایت نشده'
                this.disableBuyBtn = true
            } else if (((this.percent > 1 || this.percent <= 0) && !this.state.userInfo.supervisor) ||
                this.load ||
                (!this.limit && this.state.userInfo.supervisor)) {
                this.disableBuyBtn = true
            } else if ((this.$S2N(this.totalPrice) > this.$S2N(this.cash)) && !this.state.userInfo.supervisor) {
                this.priceError = 'مقدار بیش از موجودی شماست'
                this.disableBuyBtn = true
            } else if (!this.amount || !this.unitPrice) {
                this.disableBuyBtn = true
            } else {
                this.disableBuyBtn = false
                this.amountError = ''
                this.priceError = ''
            }
        },
        setAmount(value) {
            if (this.cash >= 0) {
                this.amount = (this.$S2N(this.cash)) * value
            } else this.amount = 0
            this.calculate()
            // this.disableBtn()
        },
        putcash(p) {
            this.percent = p
            let e = this.percent * this.cash
            this.amount = this.$toLocal(e, this.decimal)
            this.calculate()

        },
        setMin() {
            if (this.limit ) {
                this.unitPrice = this.$toLocal(this.state.shopInfo.maxBuy, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
                this.danger = false
                this.calculate()
            }
        },
        localCalculate() {
            const up = this.$S2N(this.unitPrice)
            const a = this.$S2N(this.amount)
            this.totalPrice = this.$toLocal((up * a), this.$decimal[this.tradeTo])
            this.percent = this.$S2N(this.amount) / this.cash
        },
        async serverCalculate() {
            this.loop ? this.loop.stop() : ''
            this.calculating = true
            const a = this.amount
            await this.$sleep(500)
            if (a === this.amount) {
                this.loop = new Loop(this.getPriceFromServer, this.state.time, 'getPriceSell')
                this.loop.start()
            }
        },
        async getPriceFromServer() {
            let amount = this.$S2N(this.amount);
            const [res, ] = await this.$http.get('/orders/market-buy-sell-whole', {
                params: {
                    amount: amount,
                    marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                    orderType: 'MARKET_SELL'
                }
            })
            if (res) {
                this.totalPrice = this.$toLocal(res.baseDTO.wholePrice, this.$decimal[tradeTo]);
                this.unitPrice = this.$toLocal((this.$S2N(this.totalPrice) / amount), this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom]);
                this.percent = this.$S2N(this.amount) / this.cash
            }
            this.calculating = false
        },
        calculate() {
            if (this.limit !== 'market' && this.unitPrice && this.amount) {
                this.localCalculate()
            } else if (this.limit == 'market' && this.$S2N(this.amount)) {
                this.serverCalculate()
            }
        },
        showmodal() {
            if (this.limit !== 'stopMarket' && !this.$S2N(this.unitPrice)) {
                this.danger = true
                this.danger1 = false
                this.danger2 = false
            } else if ( this.limit !== 'stopMarket' && !this.$S2N(this.amount)) {
                this.danger1 = true
                this.danger2 = false
                this.danger = false
            } else if ((this.$S2N(this.totalprice) > this.$S2N(this.cash) || this.$S2N(this.totalprice)) && !this.state.userInfo.supervisor) {
                this.danger2 = true
                this.danger1 = false
                this.danger = false
            } else {
                this.show = true
                this.danger = false
                this.danger1 = false
                this.danger2 = false
            }
        },
        async getCoins() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
            this.minAmountOrder = this.coins.find(a => a.coin == this.tradeFrom).minAmount
            this.maxAmountOrder = this.coins.find(a => a.coin == this.tradeFrom).maxAmount
        },
    },
    mounted() {
        this.getCoins()
    },
    beforeDestroy() {
        (this.loop ? this.loop.stop() : '')
    }

}
</script>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    flex-grow: 1;
    justify-content: space-between;
}

.describe-input {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    color: #E2E2E2;

    span {
        display: flex;
        align-items: center;

        .unit {
            color: #BDBDBD;
        }
    }
}

input[type="text"] {
    height: 35px !important;
}

.ex-date {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: normal;
    font-size: 12px;
    color: var(--font-strong);
}

input[type="checkbox"] {
    background: #2E414E;
    border: 1px solid rgba(199, 199, 199, 0.22);
    border-radius: 2px;
    width: 16px;
    height: 16px;
}

.calender {
    position: absolute;
    top: 5px;
    left: 10px;
}

.sell,
.sell-btn {
    background: linear-gradient(270deg, rgba(255, 47, 1, 0.97) 0%, rgba(255, 61, 0, 0.979333) 32.6%, rgba(255, 92, 0, 0.984313) 64.37%, #FF3D00 100%);
    font-weight: bold;
    color: #FCFCFC;
    cursor: pointer;
    width: 100%;
    height: 40px;
    border-radius: 6px;
}

.range-container {
    position: relative;
}
</style>
<style lang="scss">
.vpd-input-group label {
    position: absolute;
    left: 0;
    height: 100%;
    background: transparent !important;
}

.vpd-input-group input:not(.vpd-is-editable) {
    cursor: pointer;
    height: 35px;
}

.vpd-input-group input {
    border: none !important;
    border-radius: 6px !important;
}

.vpd-input-group input:disabled {
    opacity: 0.4;
}
</style>
