<template>
<div class="login-history">
    <TheTableTitle :items="items" />
    <div class="table-container">
        <perfect-scrollbar @ps-y-reach-end="check" ref="scroll">
            <table class="multi_color">
                <tr class="tr-head">
                    <td v-for="(head , index) in head" :key="index" :class="head.class">
                        {{head.title}}
                    </td>
                </tr>

                <tbody>
                    <tr class="tr-body " v-for="(tableContent , index) in tableContents" :key="index">
                        <td class="date-td number-font">
                            {{$G2J(tableContent.loginRequestedDateTime,'faDateTime')}}
                        </td>
                        <td class="ip-td number-font">
                            {{tableContent.ipAddress}}
                        </td>
                        <td class="device-td number-font">
                            {{tableContent.deviceType}}
                        </td>
                        <td class="platform-td number-font">
                            {{tableContent.osType}}
                        </td>
                        <td class="browser number-font">
                            {{tableContent.browser}}
                        </td>
                        <td :class="{'success' : tableContent.isSuccessful===true, 'fail' : tableContent.isSuccessful===false}">
                            <span v-if="tableContent.isSuccessful">
                                موفق
                            </span>
                            <span v-else>
                                نا موفق
                            </span>
                        </td>
                    </tr>
                </tbody>

            </table>
        </perfect-scrollbar>
    </div>
</div>
</template>

<script>
import TheTableTitle from '../../Public/TheTableTitle.vue'
export default {
    name: 'LoginHistory',
    components: {
        TheTableTitle
    },
    data() {
        return {
            items: [{
                title: "تاریخچه لاگین",
                class: "active"
            }],
            head: [{
                    title: "زمان",
                    class: "date-td"
                },
                {
                    title: "آی پی",
                    class: "ip-td"
                },
                {
                    title: "نوع دستگاه",
                    class: "device-td"
                },
                {
                    title: "پلتفرم",
                    class: "platform-td"
                },
                {
                    title: "نوع مرورگر",
                    class: "browser"
                },
                {
                    title: "وضعیت",
                    class: "statuse-td"
                }
            ],
            lastPage: 1,
            tableContents: [],
            page: 0,
            loading: false

        }
    },
    methods: {
        async check() {
            if (this.page <= this.lastPage && !this.loading) { //for scroll loading
                this.loading = true
                this.page++ //for scroll loading
                const [res, ] = await this.$http.get('/users/login-history', {
                    params: {
                        size: 10,
                        page: this.page
                    }
                })
                if (res) {
                    this.lastPage = res.totalPages
                    this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
                    // this.tableContents = res.content //for pagination
                }
                this.loading = false
            }
        },
    },
    mounted() {
        this.check()
    }
}
</script>

<style lang="scss" scoped>
.login-history {
    flex-grow: 1;
    background: #001E3D;
    border-radius: 8px;
    height: 447px;
    max-width: 1500px;
}

.table-container {
    // box-sizing: border-box;
    // padding: 0 2px 2px 2px;
    // background: #4F4F4F;
    // border-radius: 0 0 6px 6px;
    height: 90%;
    overflow: scroll;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border: 2px solid #4F4F4F;

    tr {
        width: 100%;
        // display: table;
        // table-layout: fixed;
    }

    tr td:first-child {
        text-align: right;
        padding-right: 16px;
    }

    tr td:last-child {
        text-align: left;
        padding-left: 16px;
    }

    .tr-head {
        height: 58px;
        background: #001E3D;
        font-weight: normal;
        font-size: 14px;
        color: #FEFEFE;
        position: sticky;
        top: 0;

        td:last-child {
            border-top-left-radius: 6px;
        }
    }

    .tr-body {
        height: 40px;
        font-weight: normal;
        font-size: 14px;
        color: #E0E0E0;

        .success {
            color: #B9FFA0;
        }

        .fail {
            color: #E43700;
        }
    }

    .tr-body:last-child td:first-child {
        border-bottom-right-radius: 6px;
    }

    .tr-body:last-child td:last-child {
        border-bottom-left-radius: 6px;
    }

    // tbody {
    //     height: 280px;
    //     overflow: auto;
    //     display: block;
    //     -ms-overflow-style: none;
    //     scrollbar-width: none;
    // }

    // tbody::-webkit-scrollbar {
    //     display: none;
    // }
}

@media only screen and (max-width:700px) {

    .device-td,
    .platform-td,
    .browser {
        display: none;
    }
}
</style>
