<template>
<div class="table-container">
    <table class="multi_color">
        <tr class="tr-heade">
            <td v-for="(heade , index) in heade2" :key="index" :class=heade.class>
                {{heade.title}} <span class="title2">{{heade.title2}}</span>
            </td>
        </tr>
        <tr class="tr-body" v-for="(withdra , index) in withdraList" :key="index">
            <td class="number-font date-td">
                {{$G2J(withdra.createdAtDateTime)}}
            </td>
            <!-- <td >

                    </td> -->
            <td class="crypto-td">
                {{$coinLabel[withdra.relatedCoin]}}
            </td>
            <td class="number-font">
                {{$toLocal(withdra.amount)}}
            </td>
            <td class="number-font net-td">
                <span class="number-font" v-if="withdra.bankAccount">{{withdra.bankAccount}}</span>
                <span class="number-font" v-if="withdra.tokenType">{{withdra.tokenType}}</span>
            </td>
            <td class="number-font adress-td">
                {{withdra.destinationWalletAddress}}
            </td>
            <td>
                <!-- {{statusLabel(tableContent.orderStatus)}} -->
                {{statusLabel(withdra.withdrawalRequestStatus)}}
            </td>
            <td>
                <img v-if="withdra.withdrawalRequestStatus=='RAISED_BY_USER'" class="delete-with" @click.prevent="withdra.withdrawalRequestStatus === 'RAISED_BY_USER' ? showModal(withdra) : ''" :class="{'disable' : withdra.withdrawalRequestStatus !== 'RAISED_BY_USER'}" src="@/assets/Profile/delete.svg" alt="">
            </td>
            <td>
                <a :href="withdra.link" target="_blank">
                    {{withdra.hashCode}}
                </a>
            </td>
        </tr>
    </table>
    <transition name="modal-fade">
        <DeleteModal v-if="modalshow" @close="closemodal" @remove="removeorder(removeid)" />
        <!-- <CompletedModal  @close="completedModal=false"
                             :text="'سفارش شما با موفقیت لغو شد.'"/> -->
    </transition>
</div>
</template>

<script>
import DeleteModal from './DeleteModal.vue'
// import CompletedModal from './CompletedModal.vue'
import {
    statusLabel,

} from "@/library/reuseableFunction";
export default {
    name: 'WithdrawHistory',
    components: {
        // CompletedModal
        DeleteModal
    },
    data() {
        return {
            withdraList: [],
            depositList: [],
            loading: false,
            setting: {
                suppressScrollX: false,
                swipeEasing: false,
                wheelPropagation: false,
            },
            label: {
                with: 'برداشت',
                dep: 'واریز'
            },
            lastPage: 1,
            page: 0,
            page2: 0,
            lastPage2: 1,
            modalshow: false,
            completedModal: false,
            removeid: '',

            items: [{
                    title: "واریز ",
                    class: "active"
                },
                {
                    title: "برداشت",
                    class: "deActive"
                }
            ],
            heade1: [{
                    title: "زمان",
                    class: "date-td"
                },
                {
                    title: "واریز"
                },
                {
                    title: "مقدار",
                    title2: "تومان"
                },
                {
                    title: "شماره شبا/کارت",
                    class: "shaba-td"
                },
                {
                    title: "وضعیت"
                },
                {
                    title: "شماره پیگیری",
                    class: "id-td"
                }
            ],
            heade2: [{
                    title: "زمان",
                    class: "date-td"
                },
                //  {
                //      title:"واریز"
                //  },
                {
                    title: "رمز ارز",
                    class: "crypto-td"
                },
                {
                    title: "مقدار"
                },
                {
                    title: "شماره کارت / شبکه",
                    class: "net-td"

                },
                {
                    title: "آدرس مقصد",
                    class: "adress-td"
                },
                {
                    title: "وضعیت",
                    class: "status-td"
                },
                {
                    title: "",
                },
                {
                    title: "شناسه تراکنش"
                }
            ],
        }
    },
    computed: {

        statusLabel
    },
    methods: {
        async getWithdrawHistory() {
            const address = 'wallets/withdrawal-requests'
            if (this.page <= this.lastPage) { //for scroll loading
                this.page++ //for scroll loading
                this.loading = true
                const [res, ] = await this.$http.get(address, {
                    params: {
                        size: 10,
                        page: this.page
                    }
                })
                if (res) {
                    this.withdraList = [...this.withdraList, ...res.content]
                    this.lastPage = res.totalPages
                }
            }

        },
        async getDepositHistory() {

            const address = 'wallets/deposit'
            if (this.page2 <= this.lastPage2) { //for scroll loading
                this.page2++ //for scroll loading
                this.loading = true
                const [res, ] = await this.$http.get(address, {
                    params: {
                        size: 10,
                        page2: this.page2
                    }
                })
                if (res) {
                    this.depositList = [...this.depositList, ...res.content]
                    this.lastPage = res.totalPages
                }

            }
        },
        closemodal() {
            this.modalshow = false
        },
        showModal(e) {
            this.modalshow = true
            this.removeid = e.id
        },
        async removeorder(e) {
            this.state.loading = true
            await this.$axios.post(`/wallets/withdrawal-request/cancel/${e}`)
            this.modalshow = false
            // this.completedModal = true
            this.$error('حذف با موفقیت انجام شد', '', 'success')
            this.tableContents = []
            this.page = 0
            this.withdraList = []
            this.getWithdrawHistory()
        },

    },
    mounted() {
        this.getWithdrawHistory()
        this.getDepositHistory()
    }
}
</script>

<style lang="scss" scoped>
.transaction {
    flex-grow: 1;
    background: #001E3D;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.transaction-title {
    font-weight: 500;
    font-size: 18px;
    color: #FEFEFE;
}

.table-container {
    display: flex;
    flex-direction: column;
    // padding: 0 2px 2px 2px;
    // background: #4F4F4F;
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 2px solid #4F4F4F;

    td:first-child {
        text-align: right;
        padding-right: 14px;
        // border-top-right-radius: 8px;
    }

    td:last-child {
        text-align: left;
        padding-left: 14px;
        // border-top-left-radius: 8px;
    }
}

.tr-heade {
    height: 56px;
    font-weight: normal;
    font-size: 14px;
    color: #FEFEFE;
    background: #001E3D;
}

.tr-body {
    height: 40px;
    font-weight: normal;
    font-size: 14px;
    color: #E0E0E0;
}

.success,
.deposit {
    color: #B9FFA0;
}

.fail,
.withdrawal {
    color: #E43700;
}

.title2 {
    font-weight: normal;
    font-size: 14px;
    color: #BDBDBD;
}

.detail {
    font-weight: normal;
    font-size: 14px;
    color: #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
}

@media only screen and (max-width:700px) {

    .date-td,
    .net-td,
    .shaba-td,
    .id-td,
    .adress-td,
    .crypto-td,
    .status-td {
        display: none;
    }
}

.trash.disable {
    opacity: 0.5;
    cursor: not-allowed;
}

.delete-with {
    cursor: pointer;
}
</style>
