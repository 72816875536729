<template>
    <div class="login-signup">
        <div class="form-container">
            <div class="title-container">
                <p class="title" v-for="(titles , index) in titles" :key="index">
                    <span v-if="$route.name===titles.link">{{titles.title}}</span> 
                </p>
            </div>
            <LoginForm v-if="$route.name==='Login'"/>
            <SignUpForm v-if="$route.name==='SignUp'"/>
            <TwoFAEmail v-if="$route.name==='TwoFAEmail'"/>
            <ForgetPasswordEmail v-if="$route.name==='ForgetPassword'"/>
            <EmailConfirm v-if="$route.name==='EmailConfirm'"/>
            <ResetPass v-if="$route.name==='ResetPass'"/>
        </div>
    </div>
</template>

<script>
import ForgetPasswordEmail from './ForgetPasswordEmail.vue'
import LoginForm from './LoginForm.vue'
import SignUpForm from './SignUpForm.vue'
import TwoFAEmail from './TwoFAEmail.vue'
import EmailConfirm from './EmailConfirm.vue'
import ResetPass from './ResetPass.vue'
export default {
  name: 'LoginSignup',
  components :{
    LoginForm,
    TwoFAEmail,
    ForgetPasswordEmail,
    SignUpForm,
    EmailConfirm,
    ResetPass
  },
  data() {
      return {
         titles : [
             {
                 title:"ورود",
                 link:"Login"
             },
             {
                 title:"ثبت نام",
                 link:"SignUp"
             },
             {
                 title:"شناسایی دو عاملی",
                 link:"TwoFAEmail"
             },
             {
                 title:"فراموشی رمز عبور",
                 link:"ForgetPassword"
             },
             {
                 title:"تایید آدرس ایمیل",
                 link:"EmailConfirm"
             },
             {
                 title:"تعیین رمز عبور",
                 link:"ResetPass"
             }
         ]
      }
  },
  methods : {
      
  }
  }
</script>
<style lang="scss" scoped>
    .login-signup {
        background-image: url('../../assets/Home/login.png');
        width: 100%;
        height: calc(100vh - 48px);
        display: flex;
        box-sizing: border-box;
        padding: 56px 10px 10px 10px;
        justify-content: center;
        background-color: #031B27;
        background-repeat: no-repeat;
        background-size: cover;
            .form-container {
                max-width: 384px;
                min-width: 300px;
                width: 100%;
                max-height: 571px;
                background: #001E3D;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                box-sizing: border-box;
                padding: 0 16px 16px 24px;
                height: max-content;
                    .title-container {
                        height: 107px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
            }
    }
</style>
