<template>
<div class="ticket">
    <span class="ticket-title">
        آخرین تیکت ها
    </span>
    <div class="tickets-list">
        <div v-if="messages.length==0" class="ticket-item no-content">
            پیامی وجود ندارد
        </div>
        <div v-else class="ticket-item" v-for="(tickets , index) in messages" :key="index">
            <div class="ticket-name">
                <span class="dote number-font"></span>
                <span class="name">
                    {{tickets.subject}}
                </span>
            </div>
            <!-- <span class="date number-font">
                {{tickets.date}}
            </span> -->
        </div>
    </div>
    <button class="ticket-btn" @click="$router.push('support')">
        مشاهده تیکت ها
    </button>
</div>
</template>

<script>
export default {
    name: 'Tickets',
    components: {

    },
    data() {
        return {
            tickets: [],
            messages:[]
        }
    },
    methods: {
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/tickets')
            // this.header = res.ticketDTO
            this.messages = res.content
        },
        async getUnreadMessages(page = this.page, perPage = this.perPage) {
            const res = await this.$axios.get('/users/notifications', {
                params: {
                    read: false,
                    page: page,
                    size: perPage,
                }
            })
            if (res.message !== 'No Notifications') {
                this.tickets.push(...res.content)
                // this.unreadTotalElements = res.totalElements
            } else {
                this.unreadmessages = []
                this.unreadTotalElements = [1]
            }
        },
        async getReadMessages(page = this.page, perPage = this.perPage) {
            const res = await this.$axios.get('/users/notifications', {
                params: {
                    read: true,
                    page: page,
                    size: perPage,
                }
            })
            if (res.message !== 'No Notifications') {
                if (res.totalElements !== this.readmessages.length) {
                    this.tickets.push(...res.content)
                    // this.readTotalElements = res.totalElements
                }
            }
        },
        async readMessage(e, index) {
            if (!this.unreadmessages[index].read) {
                await this.$axios.put(`/users/notifications/read/${e}`)
                this.unreadmessages[index].read = true
            }
            this.$forceUpdate()
        },
        async load() {
            if (!this.loading) {
                this.loading = true;
                document.body.style.cursor = 'wait';
                this.page++
                await this.getMessages()
                document.body.style.cursor = 'default';
                this.loading = false;
            }
        }
    },
    mounted() {
        this.getData()
        // this.getReadMessages()
        // this.getUnreadMessages()

    }
}
</script>

<style lang="scss" scoped>
.ticket {
    background: #001E3D;
    border-radius: 8px;
    flex-grow: 1;
    max-width: 344px;
    height: 360px;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: space-between;

    .ticket-title {
        font-weight: bold;
        font-size: 20px;
        color: #E0E0E0;
    }

    .ticket-btn {
        background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
        border-radius: 6px;
        font-weight: bold;
        font-size: 14px;
        color: #FCFCFC;
        width: 100%;
        height: 40px;
    }

    .tickets-list {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
            height: 55%;
    overflow: scroll;

        .no-content {
            color: rgba(228, 55, 0, 1) !important;
        }

        .ticket-item {
            height: 24px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #F2F2F2;

            .ticket-name {
                display: flex;
                align-items: center;
                column-gap: 16px;

                .name {
                    font-weight: bold;
                    font-size: 14px;
                }

                .dote {
                    width: 12px;
                    height: 12px;
                    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
                    border-radius: 50%;
                }
            }

            .date {
                font-weight: normal;
                font-size: 12px;
            }
        }
    }
}
</style>
