import Vue from 'vue'
import App from './App.vue'
import router from './library/router'
import './library/store'
import VueCookies from 'vue-cookies';
import VueApexCharts from 'vue-apexcharts'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import PerfectScrollbar from 'vue2-perfect-scrollbar'
// import consolere from 'console-remote-client'  
// consolere.connect({
//   server: 'https://console.re', // optional, default: https://console.re
//   channel: 'beebitex', // required
//   redirectDefaultConsoleToRemote: true, // optional, default: false
//   disableDefaultConsoleOutput: false, // optional, default: false
// });
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueSweetalert2 from 'vue-sweetalert2'
// import InlineSvg from 'vue-inline-svg';
import 'sweetalert2/dist/sweetalert2.min.css';
import './style/SweetAlertStyle.scss'
import TrendChart from "vue-trend-chart";
import VueFroala from 'vue-froala-wysiwyg'
import 'froala-editor/css/froala_editor.pkgd.min.css'
Vue.use(PerfectScrollbar,{
  options:{
      // swipeEasing: false,
      wheelPropagation: false,
  }
})
Vue.component('date-picker', VuePersianDatetimePicker);
// import $ from 'jquery'

import './style/dateTimePicker.scss'
import "./style/Font/Font-Yekan.css"
import "./style/index.scss"
import './library/axios'
import './library/reuseableFunction'
import './library/globalFunctions'
import './library/globalConstants'

Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
Vue.use(VueApexCharts)
Vue.use(VueCookies);
Vue.use(VueFroala)
Vue.use(VueSweetalert2);
Vue.use(TrendChart);
// Vue.use('inline-svg',InlineSvg);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
