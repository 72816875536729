<template>
    <div class="help">
        <p class="help-title">
          راهنمای استفاده
        </p>
        <div class="question"  v-for="(message,index) in messages"
                 :key="index">
            <div class="question-box" @click.prevent="toggle(index)">
                <p>
                    {{message.title}}
                </p>
                <img  src="@/assets/Home/Vector.svg"  :style="{transform: [showmessage[index] ?  'rotate(180deg)':'' ]}" alt="">
            </div>
            <div class="answer-box"  :style="{maxHeight: [showmessage[index]?  messageheight[index] + 'px' : '']}">
                <froalaView v-model="message.html"/>
            </div>
        </div>
        <p class="link-text" @click="$router.push('/FAQ')">
          اگر سوالی دارید می توانید به صفحه <span class="link">سوالات متداول</span> بروید
        </p>
    </div>
</template>

<script>

export default {
  name: 'Help',
  components :{
    
  },
  data() {
      return {
         read: false,
                showmessage: [],
                messages: [],
                loading: true,
                messageheight: [],
      }
  },
 methods: {
            toggle(e) {
                this.messageheight[e] = document.getElementsByClassName('answer-box')[e].firstChild.clientHeight + 20
                this.showmessage[e] = !this.showmessage[e];
                this.$forceUpdate()
            },
            async getData() {
                this.loading = true
                this.messages = await this.$axios.get('/user-manual').catch(() => {
                })
                this.loading = false
                if (this.messages)
                    for (let i = 0; i < this.messages.length; i++) {
                        this.showmessage[i] = false
                    }
            },
        },
        mounted() {
            this.getData()
        }
  }
</script>

<style lang="scss" scoped>
    .help {
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 0 10px;
    }
    .help-title {
      font-weight: bold;
      font-size: 24px;
      color: #FFFFFF;
    }
    .describe {
      // font-size: 18px;
      font-size:clamp(14px , 1vw , 18px);
      color: #FFFFFF;
    }
    .link-text {
      text-align: center;
      // font-size: 18px;
      font-size:clamp(14px , 1vw , 18px);
      color: #FFFFFF;
      cursor: pointer;
    }
    .link {
      color: #FFC04F;
      cursor: pointer;
      border-bottom: 1px solid #FFC04F;;
    }
    .question {
    background: #001E3D;
    border-radius: 8px;

}

.question:hover {
    background: #1c3f61;
}

.question-box {
    height: 64px;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 32px;
}

.answer-box {
    max-height: 0;
    color: #FFFFFF;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 32px;
}

div.deActive {
    display: none;
}

div.active {
    display: block;
}

img {
    width: 17px;
    height: 10px;
}

.active-svg {
    transform: rotate(-180deg);
    transition: ease-in 0.1s;
}
</style>
