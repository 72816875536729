import Vue from 'vue'
import VueRouter from 'vue-router'

const that = Vue.prototype

import HomePage from '../../components/HomePage/HomePage.vue'
import LoginSignup from '../../components/Login&SignUp/LoginSignup.vue'
import Markets from '../../components/Information/Markets.vue'
import Fees from '../../components/Information/Fees.vue'
import Help from '../../components/Information/Help.vue'
import Terms from '../../components/Information/Terms'
import FAQ from '../../components/Information/FAQ'
import AboutUs from '../../components/Information/AboutUs.vue'
import Profile from '../../components/Profile/Profile.vue'
import Dashboard from '../../components/Profile/Dashboard/Dashboard.vue'
import History from '../../components/Profile/History/History.vue'
import Setting from '../../components/Profile/Setting/Setting.vue'
import Account from '../../components/Profile/Account/Account.vue'
import Wallet from '../../components/Profile/Wallets/Wallet.vue'
import RedirectFromBank from '../../components/Profile/Wallets/RedirectFromBank.vue'
import OtcWallets from '../../components/Profile/Wallets/OtcWallets.vue'
import InviteFriends from '../../components/Profile/InviteFriend/InviteFriends.vue'
import Notification from '../../components/Notification/Notification.vue'
import Exchange from '../../components/Profile/Exchange/Exchange.vue'
import OTCExchange from '../../components/Profile/OTC/OTCExchange.vue'
import Transaction from '../../components/Profile/Transaction/Transaction.vue'
import Trade from '../../components/Trade/Trade.vue'
import Support from '../../components/Support/Support.vue'
import Finnotech from '../../components/Profile/Account/Finnotech.vue'
import FromGoogle from '../../components/Login&SignUp/FromGoogle.vue'
Vue.use(VueRouter)

const routes = [
  
    {
          path: '/',
          component: HomePage,
          name:'HomePage',
    },
    {
          path: '/login',
          component: LoginSignup,
          name:'Login',
    },
    {
      path : '/redirect-from-banks',
      redirect  :'/profile/redirect-from-banks'
    },
    {
      path: '/signup',
      component: LoginSignup,
      name:'SignUp',
    },
    {
      path: '/email-confirm',
      component: LoginSignup,
      name:'EmailConfirm',
    },
    {
      path: '/twoFA-email',
      component: LoginSignup,
      name:'TwoFAEmail',
    },
    {
      path: '/two-step-google-auth',
      component: LoginSignup,
      name:'TwoFAEmail',
    },
    {
      path: '/two-step-sms',
      component: LoginSignup,
      name:'TwoFAEmail',
    },
    {
      path: '/forget-password',
      component: LoginSignup,
      name:'ForgetPassword',
    },
    {
      path: '/reset-pass-*',
      name: 'ResetPass',
      component: LoginSignup,
      props:true
  },
    {
      path: '/fromgoogle',
      name: 'FromGoogle',
      component: FromGoogle
  },
    {
      path: '/markets',
      component: Markets,
      name:'Markets',
    },
    {
      path: '/fees',
      component: Fees,
      name:'Fees',
    },
    {
      path: '/guide',
      component: Help,
      name:'Help',
    },
    {
      path: '/policy',
      component: Terms,
      name:'Terms',
    },
    {
      path: '/FAQ',
      component: FAQ,
      name:'FAQ',
    },
    {
      path: '/about-us',
      component: AboutUs,
      name:'AboutUs',
    },
    {
      path: '/profile',
      component: Profile,
      name:'Profile',
        children : [
          {
            path:'dashboard',
            component:Dashboard,
            name:'Dashboard'
          },
          {
            path:'history',
            component:History,
            name:'History'
          },
          {
            path:'setting',
            component:Setting,
            name:'Setting'
          },
          {
            path:'account',
            component:Account,
            name:'Account'
          },
          {
            path:'fino-verifying',
            component:Finnotech,
            name:'FinoVerifying'
          },
          {
            path:'wallet',
            component:Wallet,
            name:'Wallet'
          },
          {
            path:'OTC-wallets',
            component:OtcWallets,
            name:'OtcWallets'
          },
          {
            path:'transaction',
            component:Transaction,
            name:'Transaction'
          },
          {
            path:'invite-friend',
            component:InviteFriends,
            name:'InviteFriend'
          },
          {
            path:'notification',
            component:Notification,
            name:'Notification'
          },
          {
            path:'exchange',
            component:Exchange,
            name:'Exchange'
          },
          {
            path:'otc',
            component:OTCExchange,
            name:'OTC'
          },
          {
            path: 'trade',
            redirect: 'trade/TOMAN/BITCOIN',
            name:'Trade',
          },
          {
            path:'trade/TETHER/TETHER',
            redirect :'trade/TOMAN/TETHER'
          },
          {
            path:'trade/:tradeTo/:tradeFrom',
            component:Trade,
            name:'Trade',
            props: true,
          },
          {
            path:'support',
            component:Support,
            name:'Support'
          },
          {
            path:'redirect-from-banks',
            name: 'RedirectFromBank',
                    component: RedirectFromBank,
          },
        ],
       
    },
  //   {
  //     path: '*',
  //     redirect: '/'
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }, 500)
    })
}
})

const disabledRoutes = [
  'OTC',
  'Exchange',
]

const forLogin = [
  'ExchangeHistory',
  'Dashboard',
  'Profile',
  'Wallet',
  'OtcWallets',
  'Setting',
  'Support',
  'Notification',
  'Orders',
  'OTC',
  'Trade',
  'Exchange',
  'Transaction',
]
const unAuthorUser = [
  'ExchangeHistory',
  'Dashboard',
  'Wallet',
  'OtcWallets',
  'Orders',
  'Trade',
  'Exchange',
  'OTC'
  
]
const otp = [
  'TwoStep',
]
const auth = [
  'Login',
  'SignUp',
  'ForgetPassword',
]
router.beforeEach(async (to, from, next) => {

  that.state.loading = true

  if(!that.state.coins){
    await that.$getCoins()
  }

  if (that.state.token) {

      if (!that.state.gotten) {
        that.state.loading = true
          await that.$getuserInfo()
          that.state.loading = true
          await that.$getFees()
      }

      that.state.loading = true
      if(that.state.userInfo.supervisor && disabledRoutes.includes(to.name)){
        that.$error('','با عرض پوزش قابلیت مورد نظر برای بازار گردان غیر فعال است')
          next(from)
      }
       if (from.name === 'Blog') {
          await window.open(to.fullPath, '_self')
      } else if (otp.includes(to.name) || auth.includes(to.name)) {
          next({name: 'HomePage'})
      } else if (to.name === 'Trade') {
        if(!to.params.tradeFrom || !to.params.tradeTo){
          next('/profile/trade/TOMAN/BITCOIN')
        }else{
          try {
            that.state.loading = true
            await that.$axios(`/markets/${to.params.tradeFrom}_${to.params.tradeTo}`)
            next()
        } catch (e) {
            if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                if (otp.includes(from.name)) {
                    next({name: 'HomePage'})
                } else {
                    await that.$error('بازار غیرفعال است')
                    next(from)
                }
            }
        }
        }
      } else {
          if (that.state.userInfo.authorized) {
              next()
          } else {
              if (otp.includes(from.name) && to.name !== 'Account') {
                  next({name: 'Account'})
              } else if (unAuthorUser.includes(to.name)) {
                  that.state.loading = false
                  const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
                  if (res.isConfirmed) {
                      next('/profile/account')
                  } else {
                      next(from)
                  }
              } else next()
          }
      }
  } else {
      if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
          next({name: 'Login'})
      else {
          next()
      }
  }
  that.state.loading = false
})
export default router
