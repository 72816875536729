<template>
    <div class="profile">
      <ExitModal v-if="exit" @closeModal="closeModal"/>
      <Sidebar class="profile-sidebar" @ExitModal="openModal" v-if="$route.name != 'Notification' && $route.name != 'Trade' && $route.name != 'Support'"/>
      <router-view />
      <!-- :key="$route.name" -->
    </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import ExitModal from './ExitModal.vue'
export default {
  name: 'Profile',
  components :{
    Sidebar,
    ExitModal
  },
  data() {
      return {
         exit:null
      }
  },
  methods : {
      closeModal(value) {
        this.exit=value
      },
      openModal(value) {
        this.exit=value
      }
  }
  }
</script>

<style lang="scss" scoped>
    .profile {
      width: 100%;
      // max-width: 1440px;
      display: flex;
      column-gap: 10px;
      justify-content: space-between;
      min-height: calc(100vh - 60px);
    } 
    .a {
      width: 100%;
    } 
    @media only screen and (max-width:990px) {
      .profile-sidebar {
        display: none;
      }
    }
</style>
