<template>
<transition>
    <div class="modal-backdrop" @click.self="$emit('closeModaleEmit')">
        <div class="modal">
            <img @click="$emit('closeModaleEmit')" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt="">
            <div class="modal-container">
                <span class="modal-title">
                    واریز {{$coinLabel[relatedCoin[0]]}}
                </span>
                <span class="describe">
                    آدرس کیف پول شما در زیر قابل مشاهده است. برای واریز بیت‌کوین به این کیف پول،می‌توانید از این آدرس استفاده کنید.
                </span>
            </div>
            <div class="modal-body">
                <template v-if="selected_net && enable">
                    <img class="qr-img" :src="'data:image/png;base64,'+qrCode" alt="">
                    <label for="">
                        <input type="text" placeholder="آدرس کیف پول" :value="address" disabled>
                        <div class="copy-container">
                            <img class="copy-image" src="@/assets/Home/copy.svg" alt="" @click="copyLink">
                            <div class="copy-alert" v-if="alertLink">کپی شد</div>
                        </div>
                    </label>
                    <p class="warning">
                        دقت داشته باشید واریز هر نوع ارز دیگر به این آدرس به جز {{$coinLabel[relatedCoin]}} موجب از دست رفتن دارایی شما می شود .
                    </p>
                </template>
            </div>
            <template>
                <label for="" class="selectd-net-lable">
                    <div class="selectd-net" @click="drop = !drop">
                        <span v-if="selected_net">{{selected_net}}</span>
                        <span v-else>انتخاب شبکه</span>
                    </div>
                    <div class="dropdown" v-if="drop">
                        <div v-for="(net , index) in nets" :key="index" @click="selectFun(index)">
                            {{net.tokenType}}
                        </div>
                    </div>
                </label>
            </template>
            <p class="error-p" v-if="!enable && !!selected_net">
                 در حال حاضر واریز به شبکه مورد نظر مقدور نیست
            </p>
            <input type="text" v-if="txIdIsNeeded" v-model="txid" placeholder="شناسه تراکنش">
            <button class="confirm" @click.prevent="checkWallet" :disabled="(txIdIsNeeded && !txid && !enable) || selected_net==''">
                <!-- :disabled="(txIdIsNeeded && !txid && !enable) || selected_net==''"" -->
                بررسی واریز
            </button>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'DepositBit',
    components: {},
    props: {
        relatedCoin: {
            default: ''
        },
        exchange: {
            default: false
        },
        nets: {
            default: {}
        }
    },
    data() {
        return {
            amount: '',
            address: '',
            qrCode: '',
            tag: '',
            txid: '',
            token: false,
            alertLink: false,
            drop: false,
            selected_net: "",
            txIdIsNeeded: false,
            enable : false
        }
    },
    methods: {
        copyLink() {
            navigator.clipboard.writeText(this.address);
            this.alertLink = true
            setTimeout(() => {
                this.alertLink = false;
            }, 1500);
        },
        closeModale() {
            this.$emit('closeModaleEmit', this.close)
        },
        changeNetwork(e) {
            this.token = e
            this.getQR()
        },
        Copy(e) {
            navigator.clipboard.writeText(e)
            alert('آدرس کپی شد')
        },
        async checkWallet() {
            this.state.loading = true
            // const token = this.token ? 'ERC20' : 'TRC20'
            let query = {
                relatedCoin: this.relatedCoin[0],
                tokenType : this.selected_net,
                walletType : (this.exchange ? 'EXCHANGE' : 'P2P')
            }
            if (this.txIdIsNeeded) {
                query.txid = this.txid
            }
            const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
                params: query
            })
            let text
            if (res.baseDTO.amount) {
                text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[this.relatedCoin[0]]} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
            } else {
                text = 'مقدار واریزی شناسایی نشد'
            }
            this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
        },
        selectFun(index) {
            this.selected_net = this.nets[index].tokenType
            this.txIdIsNeeded = this.nets[index].txIdIsNeeded
            this.enable = this.nets[index].isDepositEnable || true
            this.drop = false
            if(this.enable) {
                this.getQR()
            }
            

        },
        async getQR() {
            this.state.loading = true
            const token = this.selected_net
            let params = {
                relatedCoin: this.relatedCoin[0]
            }
            if (this.relatedCoin[0] === 'TETHER') {
                params.tokenType = token
            }
            const res = await this.$axios('/wallets/customer/wallet-address?walletType=' + (this.exchange ? 'EXCHANGE' : 'P2P') + '&tokenType=' + token, {
                params
            }).catch(() => {
                this.closeModale()
            })
            if (res) {
                this.address = res.baseDTO.address
                this.qrCode = res.baseDTO.qrCode
                this.tag = res.baseDTO.tag
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    max-height: 600px;
    background: var(--background-child);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
}

.modal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.close-icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
}

.modal-title {
    font-weight: bold;
    font-size: 16px;
    color: #F2F2F2;
    text-align: center;
    width: 100%;
}

.describe {
    font-weight: normal;
    font-size: 14px;
    color: var(--font-weake);
}

.confirm {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 40px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    align-items: center;
}

.warning {
    color: #E43700;
    font-weight: normal;
    font-size: 12px;
}

.qr-img {
    width: 114px;
    height: 114px;
}

label {
    width: 100%;
}

.copy-container {
    width: 36px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(159, 159, 159, 0.35);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nets {
    color: white;
}

.copy-image {
    cursor: pointer;
}

.copy-alert {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100px;
    color: white;
    background: green;
    position: absolute;
    left: 39px;
    top: 2px
}

.selectd-net-lable {
    position: relative;
    z-index: 2;

    .selectd-net {
        cursor: pointer;
        background: var(--background-input);
        border: 1px solid rgba(199, 199, 199, 0.22);
        box-sizing: border-box;
        border-radius: 4px;
        height: 44px;
        width: 100%;
        color: var(--font-strong);
        box-sizing: border-box;
        padding: 0 10px;
        display: flex;
        align-items: center;
    }

    .dropdown {
        position: absolute;
        width: 100%;
        background: gray;
        padding: 10px;

        div {
            cursor: pointer;
        }
    }
}
</style>
