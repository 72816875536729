<template>
<div class="notifications">
    <span class="notif-title">
        اعلانات
    </span>
    <div class="notif-container">
        <!-- <perfect-scrollbar style="direction:ltr;padding-right:10px" :options="setting" @ps-y-reach-end="unreadmessages.length < unreadTotalElements ? load('unread') : ''"> -->
        <div class="notification" v-for="(unreadmessage,index) in unreadmessages" :key="index">
            <div class="box-title" @click.prevent="toggle0(unreadmessage.id,index)">
                <div class="box-title-first-part">
                    <div class="dote" ></div>
                    <span class="">
                        {{unreadmessage.title}}
                    </span>
                </div>
                <inline-svg :src="require('../../assets/Home/Vector.svg')" :style="{transform: [showmessage0[index] ?  'rotate(180deg)':'' ]}"></inline-svg>
            </div>
            <div class="box-text" :style="{maxHeight: [showmessage0[index]?  unreadmessageheight[index] + 'px' : '']}">
                {{unreadmessage.content}}
            </div>
        </div>
        <div class="notification" v-for="(unreadmessage,index) in readmessages" :key="index">
            <div class="box-title" @click.prevent="toggle0(unreadmessage.id,index)">
                <div class="box-title-first-part">
                    <div class="reade-dote"></div>
                    <span class="">
                        {{unreadmessage.title}}
                    </span>
                </div>
                <inline-svg :src="require('../../assets/Home/Vector.svg')" :style="{transform: [showmessage0[index] ?  'rotate(180deg)':'' ]}"></inline-svg>
            </div>
            <div class="box-text" :style="{maxHeight: [showmessage0[index]?  unreadmessageheight[index] + 'px' : '']}">
                <p>{{unreadmessage.content}}
                </p>
            </div>
        </div>
        <!-- </perfect-scrollbar> -->
    </div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'Notification',
    components: {
        InlineSvg
    },
    data() {
        return {
            read: false,
            showmessage: [],
            showmessage0: [],
            unreadmessageheight: [],
            readmessageheight: [],
            messages: [],
            readmessages: [],
            unreadmessages: [],
            readTotalElements: 0,
            unreadTotalElements: 0,
            page: 1,
            perPage: 6,
            setting: {
                suppressScrollX: true
            }
        }
    },
    methods: {
        tabChange(item) {
            this.readmessages = []
            this.unreadmessages = []
            this.page = 1
            if (item === 'read') {
                this.read = true
                this.getReadMessages()
            } else {
                this.read = false
                this.getUnreadMessages()
            }
        },
        toggle(e) {
            this.readmessageheight[e] = document.getElementsByClassName('box-text')[e].firstChild.clientHeight + 20
            this.showmessage[e] = !this.showmessage[e];
            this.$forceUpdate()
        },
        toggle0(e, index) {
            this.unreadmessageheight[index] = document.getElementsByClassName('box-text')[index].firstChild.clientHeight + 20
            this.showmessage0[index] = !this.showmessage0[index];
            this.$forceUpdate()
            this.readMessage(e, index)
        },
        async getUnreadMessages(page = this.page, perPage = this.perPage) {
            this.state.loading = true
            const res = await this.$axios.get('/users/notifications', {
                params: {
                    read: false,
                    page: page,
                    size: perPage,
                }
            })
            if (res) {
                this.unreadmessages.push(...res.content)
                this.unreadTotalElements = res.totalElements
                this.state.messages = this.unreadTotalElements
            }
        },
        async getReadMessages(page = this.page, perPage = this.perPage) {
            this.state.loading = true
            const res = await this.$axios.get('/users/notifications', {
                params: {
                    read: true,
                    page: page,
                    size: perPage,
                }
            })
            if (res) {
                this.readmessages.push(...res.content)
                this.readTotalElements = res.totalElements
            }
        },
        async readMessage(e, index) {
            if (!this.unreadmessages[index].read) {
                await this.$axios.put(`/users/notifications/read/${e}`)
                this.unreadmessages[index].read = true
                    --this.readTotalElements
                    --this.state.messages
            }
            this.$forceUpdate()
        },
        async load(e) {
            if (!this.loading) {
                this.state.loading = true;
                this.page++
                if (e === 'read') {
                    await this.getReadMessages()
                } else {
                    await this.getUnreadMessages()
                }
            }
        }
    },
    mounted() {
        this.state.loading = true
        this.getUnreadMessages()
        this.getReadMessages()
    }
}
</script>

<style lang="scss" scoped>
.notifications {
    width: 100%;
    max-width: 1179px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin: 0 auto;
}

.notif-title {
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    margin: 24px 0;
}

.notif-container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.notification {
    width: 100%;
    // min-height: 64px;
    background: #001E3D;
    border-radius: 8px;
    padding: 18px 24px;
    transition: transform 0.5s;
    cursor: pointer;
    //  transition: background 0.5s;
}

.notification:hover {
    background: #26496D;
    transform: translateY(10px);
}

.box-title {
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box-text {
    // padding: 15px;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
    max-height: 0;
    overflow: hidden;
    transition: 0.4s ease-out;
    -webkit-transition: 0.4s ease-out;
    -moz-transition: 0.4s ease-out;
}

.box-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rotate {
    transform: rotate(180deg);
}

.dote {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
}
.reade-dote {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #E0E0E0;
}
.box-title-first-part {
    display: flex;
    align-items: center;
    column-gap: 16px;
}
</style>
