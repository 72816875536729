<template>
<transition>
    <div class="modal-backdrop" @click.self="$emit('closeModaleEmit')">
        <div class="modal">
            <img @click="closeModale" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt="">
            <div class="modal-container">
                <span class="modal-title">
                    برداشت تومانی
                </span>
                <span class="describe">
                    لطفا جزییات در خواست خود را بررسی کنید، در صورت اطمینان از صحت درخواست خود،کد تایید دومرحله ای خود را وارد کرده و روی گزینه ارسال کلیک کنید.
                </span>
            </div>
            <div class="modal-body">
                <label for="">
                    <div class="top-input">
                        <inline-svg :src="require('@/assets/Profile/wallet.svg')" fill="white"/>
                        <div>
                            <span class="number-font">
                                {{cash}}
                            </span>
                            <span>
                                {{unit}}
                            </span>
                        </div>
                    </div>
                    <input type="text" name="" id="" placeholder="میزان برداشت" @input="amount = $toLocal(amount)" v-model="amount" :max='cash'>

                    <div class="input-footer">
                        <span>
                            حداقل میزان برداشت
                        </span>
                        <div class="amounts-container">
                            <span class="number-fonts">
                                {{role.tokenTypeDetails[0].minWithdraw}}
                            </span>
                            <span>

                            </span>
                        </div>
                    </div>
                    <div class="input-footer">
                        <span>
                            حداکثر میزان برداشت
                        </span>
                        <div class="amounts-container">
                            <span class="number-fonts">
                                {{$toLocal(role.tokenTypeDetails[0].maxWithdraw)}}
                            </span>
                            <span>

                            </span>
                        </div>
                    </div>
                    <div class="input-footer">
                        <span>
                            کارمزد
                        </span>
                        <div class="amounts-container">
                            <span class="number-fonts">
                                {{wage}}
                            </span>
                            <span>

                            </span>
                        </div>
                    </div>
                </label>
                <label for="">
                    <!-- <input type="text" name="" id="" placeholder="شماره شبا"> -->
                    <select class="col-12" v-model="walletAddress" id="walletaddress">
                        <option value="" disabled selected>انتخاب کارت</option>
                        <option v-for="(Bank,index) in Banks" :key="index" :value="Bank.cardNumber">
                            {{Bank.cardNumber}}
                        </option>
                    </select>
                </label>
                <label for="" v-if="sendBTN">
                    <input type="text" name="" id="" placeholder="کد تایید دو عاملی" v-model="TwoStep_code">
                </label>
            </div>
            <button class="confirm" v-if="sendBTN" @click="submitWithdrawRequest" :disabled="disabled">
                برداشت
            </button>
            <button v-else class="confirm" @click="withdraw" :disabled="disabled">
                ارسال کد تایید
            </button>
        </div>
    </div>
</transition>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
export default {
    name: 'WithdrawalToman',
    components: {
        InlineSvg
    },
    props: {
        relatedCoin: {
            default: ''
        },
        cash: {
            default: 0
        },
        wage: {
            default: 0
        },
        role: {
            default: {}
        }
    },

    data() {
        return {
            banksOptions: false,
            unit: "تومان",
            amount: '',
            Banks: [],
            walletAddress: '',
            submitBTN: false,
            sendBTN: false,
            TwoStep_code: '',
            transaction: {
                code: this.TwoStep_code,

            }
        }
    },
    computed: {
        disabled() {
            return !this.$S2N(this.amount) ||
                !this.walletAddress ||
                (this.$S2N(this.amount) > this.$S2N(this.cash)) ||
                (this.$S2N(this.amount) > this.$S2N(this.max)) ||
                (this.$S2N(this.amount) < this.$S2N(this.min))
        },
        min() {
            return this.role.tokenTypeDetails[0].minWithdrawAmount
        },
        max() {
            return this.role.tokenTypeDetails[0].maxWithdrawAmount
        },
        danger() {
            return 'حداقل ' + this.$toLocal(this.min) + ' تومان' +
                '\n' + 'حداکثر ' + this.$toLocal(this.max) + ' تومان'
        }
    },
    methods: {
        closeModale() {
            this.$emit('closeModaleEmit', this.close)
        },
        // setAmount() {
        //     if (this.amount > this.cash) {
        //         this.$error(this.$errorHandeling['AMOUNT_IS_HIGH'])
        //         this.amount = this.cash
        //     } else this.amount = this.$toLocal(this.amount)
        // },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/verified-bank-accounts')
            this.Banks = res.content
        },
        async withdraw() {
            this.state.loading = true

            const res = await this.$axios.post("/users/send-code")
            if (res.message === "code sent successfully.") {
                this.sendBTN = true
                // this.$emit(
                //     "two-fa",
                //     [transaction, res.baseDTO.towStepAuthenticationType]
                // );

            }
        },
        async submitWithdrawRequest() {
            this.state.loading = true
            this.transaction.amount = this.$S2N(this.amount)
            this.transaction.destinationWalletAddress = this.walletAddress
            this.transaction.relatedCoin = 'TOMAN'
            this.transaction.code = this.TwoStep_code
            const [res, ] = await this.$http.post(`/wallets/withdrawal-request`, this.transaction)
            if (res) {
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged
                this.closemodal()
            }
        },

    },
    mounted() {
        this.getData()
    },
    // created: function () {
    //     window.addEventListener("click", (event) => {
    //         if (!event.target.closest('.modal , .depositeWith-btn')) {
    //             this.$emit('closeModaleEmit', this.close)
    //         } else {
    //             return true
    //         }

    //     })
    // },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    min-height: 531px;
    background: var(--background-child);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.modal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.close-icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
}

.modal-title {
    font-weight: bold;
    font-size: 16px;
    color: #F2F2F2;
    text-align: center;
    width: 100%;
}

.describe {
    font-weight: normal;
    font-size: 14px;
    color: var(--font-weake);
}

.confirm {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 40px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
}

.top-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px;

    div {
        display: flex;
        align-items: center;
        column-gap: 4px;
        color: #FCFCFC;
        font-weight: normal;
        font-size: 12px;
    }
}

select {
    width: 100%;
    height: 44px;
    background: var(--background-input);
    border: 1px solid rgba(199, 199, 199, 0.22);
    color: #FCFCFC;
}

.input-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    font-size: 12px;
    color: #E0E0E0;
    margin: 4px 0;
}

.amounts-container {
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: #BDBDBD;
}
</style>
