<template>
    <div class="dashboard">
        <DashboardTitle/>
        <Wealth/>
        <div class="statistics-crypto">
          <Statistics/>
          <Allcryptoes/>
        </div>
        <div class="history-tickets">
          <OpenOrders/>
          <Tickets/>
        </div>
    </div>
</template>

<script>
import DashboardTitle from './DashboardTitle.vue'
import Wealth from './Wealth/Wealth.vue'
import Statistics from './Statistics.vue'
import Allcryptoes from './Allcryptoes.vue'
import OpenOrders from './OpenOrders.vue'
import Tickets from './Tickets.vue'
export default {
  name: 'Dashboard',
  components :{
    DashboardTitle,
    Wealth,
    Statistics,
    Allcryptoes,
    OpenOrders,
    Tickets
  },
  data() {
      return {
         
      }
  },
  methods : {
      
  }
  }
</script>

<style lang="scss" scoped>
    .dashboard {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      flex-grow: 1;
      margin-top: 8px;
    }
    .statistics-crypto {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 10px;
    }
    .history-tickets {
      flex-grow: 1;
    display: flex;
    column-gap: 16px;
    row-gap: 8px;
    flex-wrap: wrap;
    }
</style>
