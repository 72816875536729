<template>
<div class="app" :class="{ 'light': (state.theme==='light' && $route.name==='Trade')}">
    <LoadingPage v-if="state.loading" />
    <Navbar :key="$route.name" />
    <router-view />
    <Footer v-if="this.$route.name == ('HomePage' || 'Markets' || 'Fees' || 'Help' || 'Terms' || 'FAQ' || 'AboutUs')" />
</div>
</template>

<script>
import Navbar from '@/components/Public/Navbar.vue'
import Footer from '@/components/Public/Footer.vue'
import LoadingPage from '@/components/Public/LoadingPage.vue'
export default {
    name: 'App',
    components: {
        Navbar,
        Footer,
        LoadingPage
    },
    data() {
        return {
            // light:true
        }
    },
    // computed : {
    //     setClass() {
    //         if(this.store.theme=='light' && this.$route.name=='trade') {
    //             this.light=true
    //         }
    //         else this.light=false
    //     }
    // }
}
</script>

<style>
.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    max-width: 1800px;
    margin: 0 auto;
}
</style>
