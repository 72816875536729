<template>
<div class="form-container-signup">
    <form @submit.prevent="send">
        <div class="inputs-container">
            <label for="" v-for="(inputs, index) in inputs" :key="index">
                <input :type="inputs.type" :placeholder=inputs.title v-model="data[inputs.data]">
                <!-- <img class="pass-img" v-if="inputs.title==='رمز عبور'" @click="showPass(index)" src="@/assets/Home/showPass.png" alt=""> -->
                <inline-svg v-if="inputs.title==='رمز عبور'" class="pass-img" :class="{'show-pass-img' : inputs.type=='text'}" :src="require('../../assets/Home/showPass.svg')" alt=""  @click="showPass(index)"></inline-svg>
                <span v-if="inputs.title==='رمز عبور'" class="line" :class="{'line-hide' :  inputs.type=='text'}"></span>
            </label>
        </div>

        <!-- <a href="/email-confirm" @click.prevent="$router.push('/email-confirm')"> -->
        <button type="submit" class="login-btn" :disabled="!data.email || !data.password">
            ثبت نام
        </button>
        <!-- </a> -->
    </form>
    <div class="buttons-container">
        <span class="or">
            یا
        </span>
        <button class="google-btn" @click="googleSso">
            <img src="@/assets/Home/google.png" alt="">
            ورود با حساب گوگل
        </button>
        <span class="to-log">
            قبلا ثبت نام کرده اید؟
            <a href="/login" @click.prevent="$router.push('/login')">
                <span class="log-link">
                    وارد شوید
                </span>
            </a>

        </span>
    </div>
</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
    name: 'SignUpForm',
    components: {
        InlineSvg
    },
    data() {
        return {
            inputs: [{
                    title: "آدرس ایمیل",
                    type: 'text',
                    data: "email"
                },
                {
                    title: "رمز عبور",
                    type: 'password',
                    data: "password"
                },
                {
                    title: "کد دعوت(اختیاری)",
                    type: 'text',
                    data: "referralCode"
                }
            ],
            data: {
                email: "",
                password: "",
                osType: '',
                deviceType: '',
                browser: '',
                referralCode: ""
            }
        }
    },
    methods: {
        googleSso() {
            window.location.href = this.$axios.defaults.baseURL + '/users/google-sso'
        },
        showPass(index) {
            if (this.inputs[index].type === "password") {
                this.inputs[index].type = 'text'
            } else {
                this.inputs[index].type = 'password'
            }
        },
        async send() {
            await this.checkEmail()
            await this.check2Pass()
            this.state.loading = true
            const res = await this.$axios.post('/users/register', this.data)
            if (res.message === 'user created successfully , verification email sent') {
                if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                    this.$cookies.set('username', this.data.email)
                    this.state.userInfo.email = this.data.email
                    await this.$router.push('/email-confirm')
                }
            } else if (res.token) {
                this.$setToken(res.token, this.state.userInfo.remember)
                await this.$router.push('/profile')
            }
        },
        async check2Pass() {
            if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                this.passError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                return Promise.reject('wrong pass : ' + this.data.password)
            } else {
                this.passError = ''
            }
        },
        async checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.emailError = 'ایمیل صحیح وارد نمایید'
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed : ' + this.data.email)
            } else {
                this.emailError = ''
            }
        },
        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },
    },
    mounted() {
        this.getData()
        if (this.$route.query.ref || localStorage.referralCode) {
            localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
            this.data.referralCode = this.$route.query.ref || localStorage.referralCode
        }
    }
}
</script>

<style lang="scss" scoped>
.form-container-signup {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    // row-gap: 72px;
    form {
        width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 70px;
    }
}

.inputs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;
    width: 100%;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    a {
        width: 100%;
    }

    .or {
        margin: 12px;
        color: #F2F2F2;
    }
}

.to-log {
    margin-top: 20px;
    font-size: 12px;
    color: #FEFEFE;

    .log-link {
        color: #FFC04F;
        border-bottom: 1px solid #FFC04F;
        cursor: pointer;
    }
}

.login-btn {
    border: none;
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 44px;
    font-size: 14px;
    color: #FCFCFC;
    width: 100%;
}

.google-btn {
    width: 100%;
    position: relative;
    height: 44px;
    background: rgba(105, 105, 105, 0.38);
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 4px;
    color: #FEFEFE;

    img {
        position: absolute;
        right: 10px;
        top: 6px;
    }
}

label {
    width: 100%;
}
</style>
