<template>
    <transition >
        <div class="modal-backdrop" @click.self="$emit('closeSuccess')">
            <div class="modal">
                <img @click="closeModale" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt="">
                <img class="success" src="@/assets/Profile/success.svg" alt="">
                <span class="modal-title">
                    <span :class="{'sell' : type=='فروش'}">
                        {{type}} &nbsp;
                    </span>
                     شما با موفقیت انجام شد
                </span>
                <div class="amounts-container">
                    <div class="amount" v-for="(amount , index) in amounts" :key="index">
                        <span :class=amount.class>
                            {{amount.title}}
                        </span>
                        <span class="number-font">
                            {{amount.number.toLocaleString()}}
                            <span class="unit">
                                {{amount.unit}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="wallet">
                    <img src="@/assets/Profile/squer.svg" alt="">
                    <div class="balance-container">
                        <span class="balance-title">
                            موجودی کیف پول شما
                        </span>
                        <div>
                            <span class="number-font">
                                {{$toLocal(balance)}}
                            </span>
                            <span class="unit">
                                {{unit}}
                            </span>
                        </div>
                    </div>
                </div>
                <button class="close-btn"  @click="closeModale">
                    بستن
                </button>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
  name: 'SuccessSell',
  components :{
    
  },
  data() {
      return {
        type:'فروش',
        balance:321321231,
        unit:"تومان",
        amounts : [
            {
                title:"مقدار رمزارز",
                number:0,
                unit:"BTC",
                class:"crypto"
            },
            {
                title:"قیمت واحد",
                number:0,
                unit:"تومان",
                class:"price"
            },
            {
                title:"مبلغ دریافت شما",
                number:0,
                unit:"تومان",
                class:"total"
            }
        ]
      }
  },
  methods : {
      closeModale() {
        this.$emit('closeSuccess')
      }
  },
//   created: function () {
//     window.addEventListener("click",(event)=>{
//             if(!event.target.closest('.modal , .add-btn , .sell-btn')) {
//               this.$emit('closeSuccess' , this.close)
//             }
//             // else if(event.target.closest('.modal , .add-btn')) {
//             // }
            
//           } )
//   },
  }
</script>

<style lang="scss" scoped>
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    min-height: 531px;
    background: var(--background-child);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .close-icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 16px;
  }
    .success {
        width: 98px;
        height: 86px;
    }
    .sell {
        color: #E43700;
    }
    .modal-title {
        font-weight: bold;
        font-size: 16px;
        color: #F2F2F2;
    }
    .amounts-container {
        margin: 38px 0;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        width: 100%;
    }
    .amount {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: normal;
        font-size: 14px;
    }
    .close-btn {
        background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
        border-radius: 6px;
        font-weight: bold;
        font-size: 14px;
        color: #FCFCFC;
        width: 100%;
        height: 40px;
    }
    .wallet {
        width: 100%;
        height: 116px;
        border: 4px solid var(--border-color);
        border-radius: 8px;
        display: flex;
        align-items: center;
        column-gap: 20px;
        padding-left: 28px;
        // justify-content: space-between;
    }
    .crypto , .price ,.unit {
        color: #BDBDBD;
    }
    .total {
        font-weight: bold;
        color: var(--font-weake);            
    }
    .number-font {
        font-weight: bold;
        color: #F2F2F2;
    }
    .balance-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        row-gap: 12px;
            div {
                display: flex;
                justify-content: space-between;
            }
    }
    .balance-title {
        font-weight: normal;
        font-size: 16px;
        color: #F2F2F2;
    }
</style>
