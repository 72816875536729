<template>
<div>
    <form class="account" v-if="profile" @submit.prevent="submit">
        <PersonalInformation :data.sync="userInfo" />
        <BankInformation :key="change" :data.sync="userInfo" :error="duplicateBank" />
        <!-- @addBank='setBanks' -->
        <DocumentsUpload :data.sync="userInfo" />
        <div class="btn-container">
            <p class="error-p" v-if="disabled">
                {{disabled}}
            </p>
            <button class="account-btn" type="submit" :title="disabled" :disabled="disabled">
                 <!-- @click="submit" @touchend="submit" -->
                <!-- :disabled="disabled" -->
                ثبت و ارسال اطلاعات
            </button>
        </div>
    </form>

</div>
</template>

<script>
import PersonalInformation from './PersonalInformation.vue'
import BankInformation from './BankInformation.vue'
import DocumentsUpload from './DocumentsUpload.vue'

const statusLabel = {
    CONFIRMED_IDENTITY: 'اطلاعات هویتی تایید شده',
    AUTHORIZED: 'احراز هویت شده',
    UNAUTHORIZED: 'اطلاعات تکمیل نشده',
    PENDING: 'در انتظار تایید',
    NOT_COMPLETE: 'احراز هویت تکمیل نشده',
    UNIDENTIFIED: 'اطلاعات هویتی تایید شده',
    INFORMATION_CHANGED_PENDING: 'در انتظار تایید',
    INFORMATION_CHANGED: 'احراز هویت تکمیل نشده'
}
 const inputLabel = {
        'firstName': 'نام',
        'lastName': 'نام خانوادگی',
        'email': 'ایمیل',
        'birthDay': 'تاریخ تولد',
        'mobileNumber': 'شماره موبایل',
        'telephone': 'شماره ثابت',
        'address': 'آدرس',
        'image': 'عکس',
    }

const statusVariant = {
    CONFIRMED_IDENTITY: 'royalblue',
    AUTHORIZED: 'green',
    UNAUTHORIZED: 'red',
    PENDING: 'gold',
    NOT_COMPLETE: 'orange',
    UNIDENTIFIED: 'red',
    INFORMATION_CHANGED_PENDING: 'gold',
    INFORMATION_CHANGED: 'orange'
}
export default {
    name: 'Account',
    components: {
        PersonalInformation,
        BankInformation,
        DocumentsUpload
    },
    data() {
        return {
            bankChange: 'noop',
            profile: true,
            userInfo: {
                authenticationType: '',
                firstName: '',
                lastName: '',
                email: '',
                nationalCode: '',
                birthDay: '',
                mobileNumber: '',
                telephone: '',
                address: '',
                bankAccounts: [{
                    shebaCode: "",
                    cardNumber: "",
                    bankName: ""
                }],
                image: '',
            },
            duplicateBank: '',
            change: 0,
        }
    },
    computed: {
        disabled() {
            for (let key in this.userInfo) {
                if (['firstName', 'lastName', 'email', 'nationalCode', 'birthDay', 'mobileNumber', 'telephone', 'bankAccounts', 'image'].includes(key)) {
                    if (key === 'bankAccounts') {
                        if(this.userInfo.bankAccounts.length){
                        for (let data of this.userInfo.bankAccounts) {
                            if (!data.shebaCode || !data.cardNumber || data.cardNumber.replace(/-/g, '').length < 16) {
                                return 'اطلاعات بانکی اشتباه است';
                            }
                            
                        }}else{
                            return 'حداقل یک حساب بانکی اضافه نمایید';
                        }
                    } else if (key === 'nationalCode') {
                        if (this.userInfo[key] < 10) {
                            return 'کدملی اشتباه است';
                        }
                    } else if (!this.userInfo[key]) {
                        return inputLabel[key] + ' اجباریست'
                    }
                }
            }
            return false
        },

        label() {
            return statusLabel[this.state.userInfo.authenticationType]
        },
        variant() {
            return statusVariant[this.state.userInfo.authenticationType]
        }
    },
    methods: {
        async check() {
            const res = await this.$axios("/users/account")
            this.userInfo = res.baseDTO
            this.change++
        },
        bankCheck(items) {
            for (let item of items) {
                if (!item.cardNumber || !item.shebaCode) {
                    this.$error('اطلاعات ناقص', "شماره شبا یا کارت بانکی را وارد نکرده اید");
                    return false;
                } else {
                    return true;
                }
            }
        },
        dataURLtoFile(dataurl, filename) {

            let arr = dataurl.split(",");
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {
                type: mime
            });
        },
        async submit() {
            if(this.state.loading) return
            this.state.loading = true
            let form_data = new FormData();

            for (let key in this.userInfo) {
                if (key === 'image') {
                    if (typeof this.userInfo.image !== 'string') {
                        form_data.append("file", this.userInfo.image);
                    }
                }

                form_data.append(key, this.userInfo[key]);
            }
            form_data.append("bankAccountsString", JSON.stringify(this.userInfo.bankAccounts));

            if (this.bankCheck(this.userInfo.bankAccounts)) {
                
                try {
                    let vm = this
                    const config = {
                        onUploadProgress: function (progressEvent) {
                            vm.state.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        }
                    }
                    const res = await this.$axios.post("/users/account", form_data, config)

                    this.state.userInfo.authenticationType = res.baseDTO.authenticationType
                    this.state.userInfo.email = res.baseDTO.email
                    this.state.userInfo.authorized = res.baseDTO.authenticationType === "AUTHORIZED"
                    this.state.userInfo.pending = res.baseDTO.authenticationType === "PENDING"
                    await this.$error('', 'اطلاعات با موفقیت ذخیره شد', 'success')

                    if (res.message.includes("need to be verified")) {
                        this.state.loading = true
                        await this.$axios(`/finotech/send-sms-to-customer?email=${this.userInfo.email}`)
                        this.$router.push('/profile/fino-verifying')
                    } else {
                        this.$getuserInfo()
                    }
                } catch (e) {
                    if (e.response && e.response.data.message.includes("ShebaCode or CardNumber Is For Another User")) {
                        this.$error('', ' شماره شبا یا کارت قبلا وارد شده است.')
                        this.duplicateBank = e.response.data.message
                    }
                }
            }
            this.state.loading = false
        },
    },
    created() {
        this.check()
    }
}
</script>

<style lang="scss" scoped>
.account {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 5px;
}

.account-btn {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
    max-width: 344px;
    height: 40px;
    width: 100%;
}

.btn-container {
    text-align: center;
    margin: 32px 0;
}
</style>
