<template>
<div class="why">
    <p class="why-title">
        چرا به تیرکس اعتماد کنیم؟
    </p>
   <div class="cell-containers">
        <!-- <div class="feature-beetex"> -->
        <!-- <div class="for-text"> -->
        <!-- <div class='feature-cell-title'>
                    <div class='feature-cell-text'></div>
                </div> -->
        <span class="feature-span first-span">
            <img src="@/assets/Home/Vector (6).svg" alt="">
            <span>مزایای تیرکس:</span>
        </span>
        <!-- </div> -->
        <!-- </div> -->
        <div class="descrbe-cell-contsiner odd">
            <span class="feature-span">
                <img src="@/assets/Home/Vector (6).svg" alt="">
                <span>معامله سریع

                </span>
            </span>
            <span class="title-content">
                <img src="@/assets/Home/Vector (15).svg" alt="">
                <span>
                     پشتیبانی آنلاین
                    <!-- <img src="@/assets/Home/support.png" alt=""> -->
                </span>
            </span>
        </div>
        <div class="descrbe-cell-contsiner even">
            <span class="feature-span">
                <img src="@/assets/Home/Vector (6).svg" alt="">
                <span>کسب درآمد از دعوت از دوستان</span>
            </span>
            <span class="title-content">
                <img src="@/assets/Home/Vector (15).svg" alt="">
                <span>کارمزد پایین</span>
            </span>
        </div>
        <div class="descrbe-cell-contsiner odd">
            <span class="feature-span">
                <img src="@/assets/Home/Vector (6).svg" alt="">
                <span>تنوع بالا در معاملات</span>
            </span>
            <span class="title-content">
                <img src="@/assets/Home/Vector (15).svg" alt="">
                <span>استفاده آسان
                </span>
            </span>
        </div>
        <div class="descrbe-cell-contsiner even">
            <span class="feature-span">
                <img src="@/assets/Home/Vector (6).svg" alt="">
                <span>
                    استفاده ار آخرین فناوری ها
                    <!-- <img src="@/assets/Home/technology.png" alt=""> -->
                </span>
            </span>
            <span class="title-content">
                <img src="@/assets/Home/Vector (15).svg" alt="">
                <span>امنیت بالا</span>
            </span>
        </div>
    </div>
</div>
</template>

<script>
// import InlineSvg from 'vue-inline-svg';
export default {
    name: 'WhyBeebitex',
    components: {
        // InlineSvg
    },
    data() {
        return {
            describe: [{
                    img: require('../../assets/Home/support.png'),
                    title: "پشتیبانی آنی",
                    text: "پشتیبانی حرفه‌ای و ۲۴ ساعته‌ی آنلاین و تلفنی والکس، خیال کاربران را برای پاسخ به هر سوالی آسوده می‌کند.",
                    class: "odd"
                },
                {
                    img: require('../../assets/Home/variety.png'),
                    title: "تنوع کوین و سبد معاملات",
                    text: "معامله‌ی بهترین کوین‌های بازار در سه پایه بازار متفاوت و متنوع‌تر شدن سبد معاملات، یک ویژگی مهم برای کاربران حرفه‌ای است.",
                    class: "even"
                },
                {

                    img: require('../../assets/Home/support.png'),
                    title: "کیف‌پول اختصاصی",
                    text: "دارایی‌های شما در کیف‌پول اختصاصی به صورت سرد نگه‌داری می‌شود و دربرابر حمله‌های مختلف امنیت دارد.",
                    class: "odd"
                },
                {
                    img: require('../../assets/Home/invite.png'),
                    title: "پشتیبانی آنی",
                    text: "لورم ایپسوم متن ساختگی با تولید سادگی از صنعت چاپ",
                    class: "even"
                }
            ]
        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.why {
    width: 100%;
    max-width: 1400px;
    margin: 40px 0;
}

.why-title {
    font-weight: bold;
    font-size: 24px;
    color: #F2F2F2;
    margin: 62px 0;
    text-align: right;
}

.cell-containers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // column-gap: 10px;
    row-gap: 5px;
    direction: rtl;
    // column-gap: 27px;
    // flex-direction: row-reverse;

    .feature-beetex {
        margin-top: 67px;

        .feature-span {
            position: absolute;
            color: #001E3D;
            font-weight: bold;
            font-size: 20px;
            /* top: 26px; */
            right: 36px;
            left: 36px;
            text-align: center;
            transform: rotate(-90deg);
        }
    }
}

.feature-span {
    position: relative;
    width: 204px;
    height: 176px;

    span,
    img {
        position: absolute;
    }

    span {
        left: 35px;
        top: 40px;
        width: 140px;
        text-align: center;
        font-size: 24px;
        color: #001E3D;
    }
}

.descrbe-cell-contsiner {
    height: 458px;
    display: flex;
    flex-direction: column;
    width: 183px;
    row-gap: 15px;
    align-items: center;
}

.odd {
    justify-content: flex-start;
}

.even {
    justify-content: center;
}

.for-text {
    position: relative;
    transform: rotate(90deg);

    .title-span {
        position: absolute;
        color: #FFFFFF;
        font-size: 14px;
        top: 32px;
        right: 27px;
        text-align: center;
        transform: rotate(-90deg);
    }

    .content {
        position: absolute;
        top: 11px;
        width: 111px;
        text-align: center;
        right: 18px;
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;
        transform: rotate(-90deg);
        height: 131px;

        span {
            font-weight: bold;
            font-size: 18px;
            color: #FFFFFF;
        }

    }
}

.HomeHx {
    position: absolute;
}

.title-content {
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    // width: 101px;
    position: relative;

    span {
        position: absolute;
        top: 63px;
        width: 109px;
        text-align: center;
        left: 36px;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 5px;
    }
}

.first-span {
    height: 252px;
    display: flex;
    align-items: flex-end;

    span {
        top: 140px !important;
    }
}

.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

.descrbe-cell-title {
    overflow: hidden;
    display: inline-block;
    // margin: 4em 0;
    width: 150px;
    height: 130px;
    transform: rotate(-30deg) skewX(30deg);
    border-radius: 6px;
}

.descrbe-cell-title *,
.descrbe-cell-title *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.descrbe-cell-text {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.descrbe-cell-title:first-child .descrbe-cell-text:before {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: orange;
    content: '';
}

.title-cell-title {
    overflow: hidden;
    display: inline-block;
    // margin: 4em 0;
    width: 150px;
    height: 130px;
    transform: rotate(-30deg) skewX(30deg);
    border-radius: 6px;
}

.title-cell-title *,
.title-cell-title *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.title-cell-text {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.title-cell-title:first-child .title-cell-text:before {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: orange;
    content: '';
}

.feature-cell-title {
    overflow: hidden;
    display: inline-block;
    // margin: 4em 0;
    width: 150px;
    height: 130px;
    transform: rotate(-30deg) skewX(30deg);
    border-radius: 6px;
}

.feature-cell-title *,
.feature-cell-title *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.feature-cell-text {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.feature-cell-title:first-child .feature-cell-text:before {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: #FFDA00;
    content: '';
}

.inner-cell-title {
    overflow: hidden;
    display: inline-block;
    // margin: 4em 0;
    width: 150px;
    height: 130px;
    transform: rotate(-30deg) skewX(30deg);
    border-radius: 6px;
}

.inner-cell-title *,
.inner-cell-title *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.inner-cell-text {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.inner-cell-title:first-child .inner-cell-text:before {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    background: rgb(15, 15, 15);
    content: '';
}

.odd {
    flex-direction: column-reverse;
    justify-content: flex-end;

    // margin-right: -8px;
    .content {
        height: 135px;
        margin-right: -8px;
    }
}

.even {
    justify-content: flex-end;
    margin-top: -25px;

    // margin-right: -8px;
    .content {
        margin-right: -8px;

        .content-container {
            flex-direction: column-reverse;
            margin-top: 37px !important;
        }
    }
}

@media only screen and (max-width:600px) {
    .odd {}

    .even {
        flex-direction: column-reverse;

        .content {
            height: 137px;

            .content-container {
                flex-direction: column-reverse;
                margin-top: 37px !important;
            }
        }
    }

    .cell-containers {
        flex-direction: column !important;
        align-items: center !important;
    }
}
</style>
