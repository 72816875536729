<template>
    <div class="orders">
        <TheTableTitle :items="items"/>
        <OpenOrders v-if="items[0].class=='active'"/>
        <PreviousOrders v-if="items[1].class=='active'"/>
    </div>
</template>

<script>
import TheTableTitle from '../../Public/TheTableTitle.vue'
import OpenOrders from './OpenOrders.vue'
import PreviousOrders from './PreviousOrders.vue'
export default {
  name: 'Orders',
  components :{
    TheTableTitle,
    OpenOrders,
    PreviousOrders
  },
  data() {
      return {
         items : [
           {
             title:"سفارشات باز",
             class:"active"
           },
           {
             title:"سفارشات قبلی",
             class:"deActive"
           },
          //  {
          //    title:"تاریخچه معاملات",
          //    class:"deActive"
          //  }
         ]
      }
  },
  methods : {
      
  }
  }
</script>

<style lang="scss" scoped>
    .orders {
      display: flex;
      flex-direction: column;
      flex-grow: 11;
      min-width: 300px;
      background: var(--background-child);
      max-height: 311px;
    }
</style>
