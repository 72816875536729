<template>
<transition>
    <div class="modal-backdrop" @click.self="$emit('close')">
        <div class="modal">
            <img @click="$emit('close')" class="close-icon" src="@/assets/Profile/closeIcon.svg" alt="">
            <div class="modal-container">
                <span class="modal-title" v-if="data.orderType=='EXCHANGE_BUY' && !beebitexWallet">
                    خرید به کیف پول دیگر
                </span>
                <span class="modal-title" v-if="data.orderType=='EXCHANGE_BUY' && beebitexWallet">
                    خرید به کیف پول تیرکس
                </span>
                <span class="modal-title" v-if="data.orderType=='EXCHANGE_SELL'">
                    فروش
                </span>
                <span class="describe" v-if="data.orderType=='EXCHANGE_BUY' && !beebitexWallet">
                    ارز خریداری شده شما خود را به آدرس کیف پول زیر ارسال می شود و مبلغ آن از موجودی شما کسر خواهد شد در ورود آدرس دقت کنید زیرا ورود آدرس نادرست موجب از دست رفتن سرمایه شما میشود
                </span>
            </div>
            <div class="modal-body">
                <div>
                    <span class="item">
                        مقدار رمزارز
                    </span>
                    <span class="item-amount">
                        {{$toLocal(data.amount)}} &nbsp;
                        <span class="item-unit">{{data.destinationCoin}}</span>
                    </span>
                </div>
                <div>
                    <span class="item">
                        قیمت واحد
                    </span>
                    <span class="item-amount">
                        {{$toLocal(data.unitPrice,$decimal[toman ? 'TOMAN' : 'TETHER'])}}&nbsp;
                        <span v-if="unit" class="item-unit">تومان</span>
                        <span v-else class="item-unit">تتر</span>
                    </span>
                </div>
                <div v-if="data.orderType=='EXCHANGE_BUY'">
                    <span class="item">
                        مبلغ پرداختی شما
                    </span>
                    <span class="item-amount">
                        {{data.totalPrice}} &nbsp;
                        <span v-if="unit" class="item-unit">تومان</span>
                        <span v-else class="item-unit">تتر</span>
                    </span>
                </div>
                <div v-if="data.orderType=='EXCHANGE_SELL'">
                    <span class="item">
                        مبلغ دریافتی شما
                    </span>
                    <span class="item-amount">
                        {{data.totalPrice}} &nbsp;
                        <span v-if="unit" class="item-unit">تومان</span>
                        <span v-else class="item-unit">تتر</span>
                    </span>
                </div>
                <div v-if="!beebitexWallet">
                    <span class="item">
                        شبکه
                    </span>
                    <span class="item-amount">
                        {{data.tokenType}}
                    </span>
                </div>
                <div v-if="!beebitexWallet" class="wallet-container">
                    <span>آدرس کیف پول</span> <span>{{data.destinationWallet}}</span>
                </div>
                <!-- <input v-if="!beebitexWallet" type="text" v-model="data.destinationWallet" disabled> -->
            </div>
            <button class="confirm" @click.prevent="$emit('submit')">
                ثبت
            </button>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    name: 'ExchangeModal',
    components: {},
    props: ['data', 'beebitexWallet', 'unit' , 'buy' , 'toman'],
    data() {
        return {

        }
    },

}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.modal {
    display: flex;
    flex-grow: 1;
    max-width: 350px;
    height: 350px;
    background: var(--background-child);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
}

.modal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.close-icon {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
}

.modal-title {
    font-weight: bold;
    font-size: 16px;
    color: #F2F2F2;
    text-align: center;
    width: 100%;
}

.describe {
    font-weight: normal;
    font-size: 14px;
    color: var(--font-weake);
}

.confirm {
    background: linear-gradient(94.21deg, #FFB904 0%, #E89900 47.18%, #F48400 99.06%);
    border-radius: 6px;
    height: 40px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    color: #FCFCFC;
}

.modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    align-items: center;

    div {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
}

.warning {
    color: #E43700;
    font-weight: normal;
    font-size: 12px;
}

.item {
    color: #BDBDBD;
    font-size: 14px;
}

.item-amount {
    font-size: 14px;
    color: #F2F2F2;
}

.wallet-container {
    background: rgba(105, 105, 105, 0.38);
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 4px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #FEFEFE;
    padding: 0 12px;
}
.item-unit {
    color: #BDBDBD;
    font-size: 14px;
}
</style>
